import React, {useEffect} from 'react';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import {Box,Stack,Container,Flex,SimpleGrid,Link,Image,GridItem ,Accordion, AccordionButton, AccordionItem, AccordionIcon, AccordionPanel } from '@chakra-ui/react';
import Testimonial from '../component/Testimonial/Testimonial';
import PriceBox from '../component/Price Component/PriceBox';
import MarqueLoop from '../component/Marque Loop/MarqueLoop';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';
import VideoBanner from '../component/VideoBanner/VideoBanner';
import * as Style from './Crossfit.style';
import BlogSection from '../component/Blog/BlogSection';
import Button from '../component/Button/CustomeButton';
import LazyLoad from "react-lazy-load";
import LookingInformation from '../component/LookingInformation/LookingInformation';


const whyChoosePostNataData =[
  {
    id: '1',
    title: 'CERTIFIED & EXPERIENCED COACHES',
    description:'Whether you are pregnant or have already given birth, Loz will help you on your journey. She is a CPPC Certified Pre & Post Natal Coach and has an option to suit all pregnancies and abilities.'
  },
  {
    id: '2',
    title: 'COMFORTABLE & SAFE ENVIRONMENT',
    description:'Perpetua has the best facilities available for you to come in and get your session in. You will feel safe with the guidance from our coaches and enjoy the friendly environment.'
  },
  {
    id: '3',
    title: 'VARIETY OF TRAINING OPTIONS',
    description:'We have systems in place to make sure you are receiving the best service available. Each session will delivered online, you can track results, see your progress, communicate with your coach and receive daily accountability.'
  }
]


const WhatYouGetData  = [
  {
    id: '1',
    title: 'EXPERIENCED COACHING',
    description: 'Whether you are pregnant or have already given birth, Loz will help you on your journey. She is a CPPC Certified Pre & Post Natal Coach and has an option to suit all pregnancies and abilities.',
    image:  '/images/weight-icon.svg'  
  },
  {
    id: '2',
    title: 'PERSONAL PROGRAM',
    description: 'You will receive your weekly program via an app so you can understand exactly what you are working towards, log your numbers and stats and see the progress you are making with the team.',
    image: '/images/Specialclasses.svg'
  },
  {
    id: '3',
    title: 'COMFORTABLE & SAFE ENVIRONMENT',
    description: 'Perpetua has the best facilities available for you to come in and get your session in. You will feel safe with the guidance from our coaches and enjoy the friendly environment.',
    image: '/images/justgym-access.svg' 
  },

]


const metaTitle="Pregnancy Exercise Classes Dublin | Perpetua Fitness"
const metaDesc="Pregnancy exercise classes in Dublin with Perpetua Fitness. Pre natal & post natal training. Train safely during and after pregnancy."


function PostNatal(props) {

  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc}/>
     
      <VideoBanner 
        videobanner='../video/Website-banner.mp4'
        subtitle='PREGNANCY EXERCISE CLASSES DUBLIN'
        title='PRE & POST NATAL TRAINING'
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image>Available at our Windmill Lane Facility</Box>}
        isButton={true} 
        issubtextpara={false} 
        buttontitle="Enquire now"
        margin="0px 0 0"
        Link="/contact-us"
        videoModal='https://www.youtube.com/embed/-3cC0zZ6yt0' 
        targetBlank={false}
        modalButtontitle="View The Experience"
        issubtitleRight={true} 
      />
      
      <Flex  backgroundColor='#fff' padding={{sm:"60px 0 30px", md:"70px 0 35px", xl:"80px 0 40px"}}>
        <Container maxW='container.xl'>
          <MarqueLoop textColor='#000' />
        </Container>
      </Flex>
      <Flex  backgroundColor='#fff' padding={{sm:"30px 0 60px", md:"35px 0 70px", xl:"40px 0 80px"}}>
        <Container maxW='container.xl'>
          <Stack direction={{base:'column',md:'row'} } spacing='30px' textAlign={{base:'left', lg:'left'}} alignItems='center'>
              <Box w={{ base:'100%' ,lg:'50%'}} padding={{md:'0px 20px',lg:'0px 0px'}}>
                <PriceBox 
                  color='#000'
                  manheadind='Pre Natal-Group 150€/6 Weeks '
                  time='/6 Weeks '
                  description={<Box>We have a Pre-Natal Group that runs for a 6-week block that involves 1 session per week.</Box>}
                  buttonText='Enquire Now'
                  link='/contact-us'
                  buttonPading='0px'
                  issubHeading={false}
                  width={{base:'100%', sm:'70%'}}
                  isHeading={false}
                  isButton={false}
                />
              </Box>
              <Box w={{ base: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
                <PriceBox 
                  color='#000'
                  currency='€'
                  priceValue='Pre Natal-Group'
                  time='150e/6 Weeks'
                  description={<Box>We have a Post-Natal Group that runs for a 6-week block that includes 2 sessions per week.</Box>}
                  buttonText='Enquire Now'
                  link='/contact-us'
                  buttonPading='0px'
                  issubHeading={false}
                  width={{base:'100%', sm:'70%'}}
                  isHeading={false}
                  manheadind='Post Natal-Group 300€/6 weeks'
                  isButton={false}
                />
              </Box>
          </Stack>
          <Box w='100%' textAlign={{sm:'left',md:'center',lg:'center'}} marginTop={{lg:'50px',md:'50px',sm:'25px'}}>
            <Link href='/contact-us'>
              <Button margin='30px 0 0' className="wow fadeInUp" title='Enquire Now' color='#000' />
            </Link>
          </Box>
          <Box textAlign={{sm:'left',md:'center',lg:'center'}} marginTop='30px'>
            <Text title={<Box>Email Loz on <Link href='mailto:lorraine@perpetua.ie' textDecoration='underline' color='#d12c41'>lorraine@perpetua.ie</Link> find out more information on our available services.</Box>} color='#000'></Text>
          </Box>
        </Container>
      </Flex>

      <Box  padding={{sm:"60px 0", md:"70px 0", xl:"80px 0"}} backgroundColor='#f7f7f7'>
        <Container maxW='container.xl'>
          <Heading
            variant="extralarge"
            title='WHY CHOOSE PERPETUA'
            margin={{ sm: '0px auto 50px', md: '0px auto 86px' }}
            width={{ base: '90%', md: '60%' }}
            textAlign='center'
            lineHeight='1'
            color='black'
          />
          <SimpleGrid
            columns={{ sm: '1', md: '3' }}
            spacing={5}
            textAlign='center'
            justifyContent='center'
          >
            <GridItem>
              <Heading title='01' color='Red' textAlign='center' />
              <Heading
                title='EXPERIENCED COACHES'
                color='black'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title='Whether you are pregnant or have already given birth, Loz will help you on your journey. She is a CPPC Certified Pre & Post Natal Coach and has an option to suit all pregnancies and abilities.
                '
                color='black'
                padding='15px 20px'

              />
            </GridItem>
            <GridItem>
              <Heading title='02' color='Red' textAlign='center' />
              <Heading
                title='COMFORTABLE & SAFE ENVIRONMENT'
                color='black'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title='Perpetua has the best facilities available for you to come in and get your session in. You will feel safe with the guidance from our coaches and enjoy the friendly environment.'
                padding='15px 20px'
                color='black'
              />
            </GridItem>
            <GridItem>
              <Heading title='03' color='Red' textAlign='center' />
              <Heading
                title='Friendly Community'
                color='black'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title='We understand that coming into a gym with your baby can be hard so we make sure all classes are run at our quieter times so you feel comfortable with the surroundings and maybe your little one can have a nap.
                '
                color='black'
                padding='15px 20px'
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Flex flexDirection='column' padding={{sm:'50px 0px', md:'90px 0px' }} position="relative"  >
        <Container maxW='container.xl'>
          <Testimonial headingWidth={{sm:'97%',md:'90%'}} iconUri='/images/comment-quote-outline.svg' isname={true} 
          title='“Having a baby changed everything for me! I’m delighted with the information I received 
          during my pregnancy and Loz helped me so much on this journey.”' name='- PERPETUA CLIENT'/>

        </Container>
      </Flex>

      <Box backgroundColor='#f7f7f7' padding={{ sm: '60px 0px', md: '80px 0px' }}>
        <Container maxW='container.xl'>
          <Style.Wrapper>
            <Style.LeftBox textAlign={{sm:'center', md:'left'}}>
              <Heading 
              variant="medium" 
              title='PRE/POST NATAL' 
              lineHeight='1'
              color='#000'
              />
              <Heading 
              variant="medium" 
              title='TRAINING AT PERPETUA' 
              lineHeight='1'
              color='#000'
              />
            </Style.LeftBox>
            <Style.RightBox>
              <Style.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://youtube.com/embed/-3cC0zZ6yt0?si=Myn4-Rtkn6KUggUX' width={{sm:'100%', md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='FIND OUT MORE' 
                  lineHeight='1'
                  color='#000'
                  marginBottom='5px'
                  />
                  
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://youtube.com/embed/V9AVPmPYwLE?si=AQaDgcJiFYqZntVg' width={{sm:'100%', md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='BREATHING TECHNIQUE' 
                  lineHeight='1'
                  color='#000'
                  marginBottom='5px'
                  />
                  
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://www.youtube.com/embed/ksFkWySYU3o' width={{sm:'100%', md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>

                  <Heading 
                  variant="smallheading" 
                  title='Post Natal Training at Perpetua Fitness' 
                  lineHeight='1'
                  color='#000'
                  marginBottom='5px'
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
            </Style.RightBox>
          </Style.Wrapper>
        </Container>
      </Box>

      <BlogSection catSlug ='prepost-natal' />

      <Flex p={{ sm: '50px 0px', md: '45px 0 90px' }}>
        <Container maxW={{ sm: 'Container.sm', md: 'container.lg', lg: 'container.lg', xl: 'container.xl' }}>
          <Stack direction={{ sm: 'column', md: 'row' }} spacing='24px'>
            <Box width={{ sm: '100%', md: '50%' }}>
              <Box paddingLeft='30px' position={'relative'} _after={{ position: 'absolute', content: '""', width: '3px', height: '100%', top: '0', left: '0', background: 'Red' }}>
                <Heading title='FREQUENTLY ASKED QUESTIONS' lineHeight='1' padding='0px 0px 30px' margin='0px auto' />
                <Text title='Questions about the workout or our gyms? We got you. Check out the FAQ below or contact us.' width='80%' />
              </Box>
            </Box>
            <Box width={{ sm: '100%', md: '50%' }}>
              <Accordion defaultIndex={[0]} allowMultiple className='Accor_item _black_acording  white_acording'>
                <AccordionItem >
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title=' Who Is This Suitable for Postnatal Training? ' variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='1-2-1 Training and Post Natal Group Training is suitable once you have been cleared for exercise (postnatal generally 6 weeks post vaginal birth /12 weeks post caesarean birth).' margin='0px 0px 20px' />
                    <Text title='​​Most feel comfortable from 2-3 months postnatal up until their baby is at pre-crawling stage and is suitable for moms with babies up to 12 months.' margin='0px 0px 20px' />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Have You Any Options For Prenatal? " variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='Yes we offer both 1-2-1 personal training where you work closer with Loz, as well as our Prenatal small group training.' margin='0px 0px 20px' />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title='Can I exercise if I’m pregnant and haven’t exercised before? ' variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title="Yes, absolutely. We will start you slow and build it up." margin='0px 0px 20px' />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="What Should I Bring To My Session?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title={<Box>For you:<br/>
                      Wear light comfortable clothing & a water bottle<br/>
                      For your little one:<br/>
                      Anything you generally need and maybe a little blanket/toy that can keep them occupied for some time.
                      </Box>}></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Where Are You Located?" variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title=' 2 WindMill Lane, Dublin 2' margin='0px 0px 20px' />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="What If I’m Unsure What Suits Me Best?" variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='Have a consultation with Loz, grab a coffee or arrange a call to help figure out which suits you best.' margin='0px 0px 20px' />
                  </AccordionPanel>
                </AccordionItem>
                
              </Accordion>
            </Box>
          </Stack>
        </Container>
      </Flex>

      <LookingInformation pageName="PostNatal" heading='LOOKING FOR MORE INFORMATION'  subHeading='Chat with a member of the team' buttonTitle='BOOK A CALL'/>

    </>
  );
}

export default PostNatal;
