import React from 'react';
import * as Style from './Membership.style';
import { Box, Container, SimpleGrid } from '@chakra-ui/react';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';
import Button from '../Button/CustomeButton';
import PriceCrad from '../Pricing/PricingCard';

function MembershipCard() {
    return (
        <>
            <Container maxW='container.xl' padding={{ sm: '20px 15px 1px', md: '20px 0px', lg: '50px 0px 50px' }}>
                <Style.CrossfitInfo>
                    <Style.CrossfitContent>
                        <Box padding={{ sm: '15px 0px 0px', md: '0px 20px' }}>
                            <Heading title='CrossFit' variant='large' ></Heading>
                            <Heading title='crossfit@perpetua.ie' variant='extrasmall'></Heading>
                            <Text title='Our CrossFit program is designed for universal scalability making it the perfect application for any committed individual regardless of experience.' padding='5px 0px' ></Text>
                            <Text title='CrossFit is not a specialised fitness program but rather it focuses on competency in each of the ten recognised fitness domains; cardiovascular and respiratory endurance, stamina, strength, flexibility, power, speed, coordination, agility, balance and accuracy. Our athletes are trained to successfully perform multiple, diverse and randomised physical challenges.' padding='5px 0px'></Text>
                            <Text title='Interested in joining our CrossFit community? Click below to learn more.' padding='5px 0px'></Text>
                            <Box className='crossfitButton' paddingTop='20px' >
                                <Style.ButtonLinkUrl href='/crossfit' >
                                    <Button title='LEARN MORE'
                                        color='black' />
                                </Style.ButtonLinkUrl>
                            </Box>
                        </Box>
                    </Style.CrossfitContent>
                    <Style.CrossfitImage
                        backgroundImage='url(./images/crossfit-membership.jpg)'
                    ></Style.CrossfitImage>
                </Style.CrossfitInfo>

                <Style.JustGymInfo>
                    <Style.JustGymImage
                        backgroundImage='url(./images/just-gym-membership.jpg)'
                    ></Style.JustGymImage>
                    <Style.JustGymContent>
                        <Box padding={{ sm: '15px 0px 0px', md: '0px 40px' }}>
                            <Heading title='JustGYM' variant='large' ></Heading>
                            <Text title='JustGYM at Perpetua has all you need, from cardio to heavy lifting, and everything in between. Our open-plan training space is big and bold. Find your space and get inspired.' padding='5px 0px' ></Text>
                            <Text title='Train at your own pace with our flexible JustGYM Membership options. All members have unlimited access to the JustGYM area and your membership also includes a complimentary session with our head trainer and nutritionist.' padding='5px 0px'></Text>
                            <Text title='The training area is separated into five distinct zones: cardio conditioning, functional training, free weights, and fixed weights. Each section features the very latest model equipment from Keiser, Concept 2, Skill Mill, TechnoGym, Step and Versaclimber to name a few.' padding='5px 0px'></Text>
                            <Text title='Ready to go? Get in touch to become a member!' padding='5px 0px'></Text>
                            <Box className='crossfitButton' paddingTop='20px' >
                                <Style.ButtonLinkUrl href='justgym' >
                                    <Button title='LEARN MORE'
                                        color='black' />
                                </Style.ButtonLinkUrl>
                            </Box>
                        </Box>
                    </Style.JustGymContent>
                </Style.JustGymInfo>
            </Container>

            {/* price */}
            <Style.PriceInfo >
                <Container maxW='container.xl' >
                    <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10} >
                        <PriceCrad key="1" offer="" cardTitle="" currency="€89" planname="JUST GYM €89/MO" description="-FLEXIBLE ROLLING MONTHLY MEMBERSHIP AFTER 3 MONTHS."
                            link="contact-us" targetBlank={false} />
                        <PriceCrad key="2" offer='' cardTitle="" currency="€199" planname="JUST GYM + 8 STUDIO CREDITS €199/MO" description="-FLEXIBLE ROLLING MONTHLY MEMBERSHIP AFTER 3 MONTHS."
                            link='contact-us' targetBlank={false} />
                    </SimpleGrid>
                </Container>
            </Style.PriceInfo>

            {/* Button */}

            <Style.ButtonContent>
                <SimpleGrid columns={{ sm: '1', md: '2' }} spacing={6} >
                    <Style.ButtonLinkUrl to='/studios'>
                        <Style.ButtonLink>
                            BUY STUDIO CREDITS
                        </Style.ButtonLink>
                    </Style.ButtonLinkUrl>
                    <Style.ButtonLinkUrl to='/membership'>
                        <Style.ButtonLink >
                            MEMBERSHIPS
                        </Style.ButtonLink >
                    </Style.ButtonLinkUrl>
                </SimpleGrid>
            </Style.ButtonContent>
        </>
    )
}
export default MembershipCard;