import { Flex, Container, SimpleGrid, GridItem, Box, Center, VStack, Link, Image } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Heading from '../component/Heading/Heading';
import ClassCard from '../component/Classcard/ClassCard';
import AppDownload from '../component/AppDownload/AppDownload';
import Text from '../component/SubHeading/SubHeading';
import Button from '../component/Button/CustomeButton';
import Testimonial from '../component/Testimonial/Testimonial';
import Accordian from '../component/Accordion/Accordian';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';
import VideoSection from '../component/VideoSection/VideoSection';
import VideoBanner from '../component/VideoBanner/VideoBanner';
import BlogSection from '../component/Blog/BlogSection';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import TabItem from '../component/TeamFilter/TabItem';

const accoData = [
  {
    "id": 1,
    "title": "When should I arrive for class?",
    "description": "We recommend arriving at the gym 15 minutes before class for check-in. This will allow time for our Front Desk staff to get you checked into class and show you around the facility.",
  },
  {
    "id": 2,
    "title": "What do I do when I get there?",
    "description": "Say hello to whoever is at the front desk and mention that it is your first time. They’ll get you taken care of.",
  },
  {
    "id": 3,
    "title": "What if I'm running late?",
    "description": "To ensure that your spot is not released to the waitlist, please arrive at least 5 minutes prior to the start of your class.",
  },
  {
    "id": 3,
    "title": "Good Vibes Zone",
    "description": "We want you to bring your good vibes! We truly believe in making our gyms a space where members can check their stress at the door. In our gyms you’ll find a community built on sweat and support, all are welcome here. All we ask is that you show up, bring a smile, and get in a great workout. It’s that simple.",
  }

]
const accoData2 = [
  {
    "id": 1,
    "title": "What is a RHYTHM RIDE class like?",
    "description": "In a RHYTHM RIDE class, you can expect sprints, hills and jogs like any other spin class but.. with a little flavour on top. In RHYTHM RIDE you will mindfully move connecting to the beat and switching off any distractions. In this class you will move through choreographed sequences to the best tunes and even better motivation. Get ready to leave feeling inspired and excited for more.",
  },
  {
    "id": 2,
    "title": "I've never done this style of training before, can I keep up?",
    "description": "Absolutely! Our workouts are beginner friendly and designed to allow all athletes to workout at their own pace. Our Coaches are trained to modify and make adjustments to the workout on the fly to meet you where you are.",
  },
  {
    "id": 3,
    "title": "What should I wear?",
    "description": "Wear athletic clothes that are comfy for you to sweat and move in.",
  },
  {
    "id": 4,
    "title": "What shoes should I wear?",
    "description": "Most athletes choose to wear normal trainers or you can wear clip on cycling shoes",
  },
  {
    "id": 5,
    "title": "Do you guys provide water?",
    "description": "Each of our gyms are equipped with filtered water stations. Bring a reusable water bottle with you and fill up before class!",
  }

]
const accoData3 = [
  {
    "id": 1,
    "title": "Do you have showers?",
    "description": "Yes! Each of the gyms have showers that you are free to use. Towels and bath & body products are also provided.",
  },
  {
    "id": 2,
    "title": "That was fun! What's next?",
    "description": "Awesome! Schedule your next workout through the Perpetua Fitness app. If you have any questions, please feel free to reach out to our team at any facility or through hello@perpetua.ie",
  }

]

const metaTitle = "Spin Class Dublin | Rhythm Ride | Perpetua Fitness"
const metaDesc = "More than a spin class. An experience. Fully equipped with Keiser M3i bikes and the best lighting and sound system in Ireland. Book a class."


function RidePage(props) {

  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])

  const [items, setItems] = useState([]);
  axios({
    method: 'get',
    url: process.env.REACT_APP_ADMIN_API_BASE_URL + '/coaches/all',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },

  }).then(response => {
    var rawItems = response.data.data.data;
    console.log(rawItems);
    var filteredItems = rawItems.filter(item => item.category_title.includes('RHYTHM RIDE'));
    var limitedItems = filteredItems.slice(0, 4); // Get only the first 4 items
    setItems(limitedItems);
  }).catch(error => {
    console.log(error);
  });

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />

      <VideoBanner
        videobanner='../video/Ride-banner.mp4'
        title="RHYTHM RIDE"
        subtitle="SPIN CLASS DUBLIN"
        isButton={true}
        issubtextpara={false}
        buttontitle="BOOK A CLASS"
        margin="0px 0 0"
        Link="/timetable"
        videoModal='https://www.youtube.com/embed/Q5v9N-zZckU'
        targetBlank={false}
        modalButtontitle="View The Experience"
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image> Available at our Windmill Lane & Lennox Street Facility</Box>}
        issubtitleRight={true}
      />

      <Flex flexDirection='column'padding={{ sm: '0', md: '60px 0', lg: '70px 0 35px', xl: '80px 0 40px' }} background='#fff'>
        <Container maxW='container.xl'>
          <Heading
            as="h3"
            title="MORE THAN A SPIN CLASS. AN EXPERIENCE. Fully equipped with Keiser M3I bikes and the best lighting and sound system in Ireland. Our RHYTHM RIDE classes offer something for everyone."
            variant='medium'
            textAlign="center"
            lineHeight="1"
            width={{ lg: '100%', xl: '100%' }}
            padding={{ sm: '40px 0px', md: '0px 0px 70px' }}
            className='wow animate fadeInUp'
            color='#000'
          >
          </Heading>
        </Container>
        <Container maxW='container-fluid'>
          <SimpleGrid
            columns={{ sm: '1', md: '2', lg: '2', xl: '3', xxl: '4' }}
            columnGap={4}
            rowGap={2}
            width="full"
            padding={{ sm: '0px', md: '0px 30px' }}
          >
            <GridItem className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
              <ClassCard
                title='WARM UP'
                subtitle='Please arrive 15 minutes early and check in with our front desk. Our staff will introduce
                  you to the class coach. They will provide an introduction to how the class works and show you how to use our treadmills. We have 6-10 minutes set aside to start your session off with a dynamic warm-
                  up to prep your body for the activity to come, we will alternate between the treadmills and floor until your heart rate is up and your body is ready to go!'
                bg="url('/images/RhythmRide/WarmUp.webp')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
            <GridItem mt={{ sm: '40px', xl: '80px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                title='BEAT OF THE MUSIC'
                subtitle='TURN OFF THE SCREENS, BRING THE LIGHTS DOWN LOW, AND JUST FEEL THE BEAT OF
                  THE MUSIC. DURING THIS 45 MINUTE FULL BODY EXPERIENCE, YOU WILL HIT CARDIO HIGHS,
                  CHOREOGRAPHY COMBOS AND A DUMBBELL DEDICATED TRACK TO REALLY FOCUS ON YOUR UPPER
                  BODY.'
                bg="url('/images/RhythmRide/Beat.webp')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
            <GridItem mt={{ sm: '0', lg: '0', xl: '160px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                title='PERFECTLY CURATED PLAYLIST'
                subtitle='GET READY TO RIDE TO YOUR INSTRUCTOR’S PERFECTLY CURATED PLAYLIST AND LEAVE THE ROOM
                  LIGHTER AND MORE INSPIRED THAN WHEN YOU WALKED IN.'
                bg="url('/images/RhythmRide/Playlist.webp')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
            <GridItem mt={{ sm: '0', md: '40px', lg: '40px', xl: '0', xxl: '240px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                title='COOL DOWN & HIGH FIVES'
                subtitle='We have 5 minutes set aside to finish class with a cool-down to bring your heart rate back
                  to normal and stretch out your muscles. Afterward, you can shower on-site & grab a shake at our
                  Mojo Health Bar'
                bg="url('/images/RhythmRide/Cooldown.webp')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>

      <Flex flexDirection='column' padding={{ sm: '50px 0 70px', md: '30px 0', lg: '35px 0', xl: "40px 0" }}>
        <Container maxW='container.xl'>
          <SimpleGrid
            columns={{ sm: '1', md: '2' }}
            columnGap={10}
            rowGap={2}
            width="full"
            textAlign={{ sm: "center", md: "left" }}
          >
            <GridItem>
              <Heading
                as="h2"
                title="WHO IS RHYTHM RIDE FOR?"
                variant='extralarge'
                textAlign={{ sm: "center", md: "left" }}
                lineHeight="1"
                padding='0px 0px 20px'
                width={{ sm: "", md: "60%", lg: "70%", xl: "80%" }}
              >
              </Heading>
            </GridItem>
            <GridItem >
              <Text
                as="p"
                fontFamily='poppins'
                title=" Anyone and everyone! Especially if, you are a lover of music and working to the beat in choreographed sequences, if you'd like a workout that involves mind-body movement connection and if you like to leave workouts inspired to do and be better!"
                width='98%'
              ></Text>
            </GridItem>
          </SimpleGrid>

        </Container>
      </Flex>

      <Flex flexDirection='column' padding={{ sm: '0 0 70px', md: '60px 0 100px'}} position="relative">
        <Container maxW='container.xl'>
          <Testimonial headingWidth={{ sm: '97%', md: '90%', lg: '87%', xl: '69%' }} isicon={false} isname={false} title='“THE COACHES ARE BOTH PROFESSIONAL AND WELL-TRAINED. I WOULD ABSOLUTELY RECOMMEND PERPETUA TO ANYONE, FROM A BEGINNER TO A EXPERIENCED GYM USER”' />
        </Container>
      </Flex>

      <Box padding={{sm:"50px 0", md:"70px 0"}} backgroundColor='#f7f7f7'>
        <Container maxW='container.xl'>
          <Heading title='Rhythm Ride Instructors ' variant='large' textAlign='center' />
          <TabItem items={items} />
          <Center>
            <NavLink to='/team'>
              <Button title='see the full team' color='#000' />
            </NavLink>
          </Center>
        </Container>
      </Box>

      <VideoSection bgColor='#fff' textColor='#000' heading='Rhythm Ride' headingSub='at Perpetua' video1='https://youtube.com/embed/Q5v9N-zZckU?si=21Ba9C8bKlzi0zwG' video2='https://youtube.com/embed/1026ZN9O5QE?si=78KiJx16LR1cDuvf' smallHeading='Find out more' smallHeading2='What our clients say' midHeading='' midHeading2='' />

      <Box
        backgroundImage='url(./images/ride.jpg)'
        backgroundColor='black'
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        height={{ sm: '150vh', md: '767px', lg: "850px", xl: "767px" }}
        backgroundSize={"cover"}
        width='100%'
      >
        <Container maxW='container.2xl' p={{ sm: '0', lg: "0 0 0 130px" }} height='100%' width='100%'
          backgroundColor='rgba(0,0,0,0.7)'
        >
          <SimpleGrid
            columns={{ sm: '1', lg: '2' }}
            columnGap={10}
            rowGap={2}
            height='100%'
            width='100%'
          >
            <GridItem
              display='flex'
              alignItems='end'
              paddingBottom="60px"
              className='wow animate fadeInUp'
              paddingLeft={{ sm: "20px", lg: "0" }}
            >
              <Box>
                <Heading title='Benefits of Rhythm Ride Classes' color='#fff' lineHeight="1" padding="0px 0px 5px" variant="extralarge" width={{ lg: "75%" }} />
                <Box>
                  <NavLink to='/timetable'><Button margin='20px 0 0' title="Book a class" /> </NavLink>
                </Box>
              </Box>
            </GridItem>
            <GridItem
              borderLeft={{ lg: '1px solid rgba(255, 255, 255, .4)' }}
              height='100%'
              width='100%'
              borderTop={{ sm: '1px solid rgba(255, 255, 255, .4)', lg: "0" }}
            >
              <SimpleGrid
                columns={{ sm: '2', md: '2' }}
                height='100%'
                width='100%'
              >
                <GridItem
                  padding={{ sm: "20px", lg: "40px" }}
                  borderBottom='1px solid rgba(255, 255, 255, .4)'
                >
                  <Image src='./images/threadAndShread1.png' />
                  <Heading title='IMPROVE PERFORMANCE' variant='extrasmall' color='#fff' padding='10px 0' />
                  <Text title='Experience full-body transformation. Build muscle, increase strength and endurance, and improve movement, mobility and coordination.' color='#fff' fontSize={{ sm: "12px", xl: "14px" }} />
                </GridItem>
                <GridItem
                  padding={{ sm: "20px", lg: "40px" }}
                  borderBottom='1px solid rgba(255, 255, 255, .4)'
                  borderLeft='1px solid rgba(255, 255, 255, .4)'
                >
                  <Image src='./images/threadAndShread4.png' />
                  <Heading title='BOOST CARDIOVASCULAR HEALTH' variant='extrasmall' color='#fff' padding='10px 0' />
                  <Text title='Lower your risk of cardiovascular disease by improving your anaerobic threshold and increasing your peak oxygen consumption (VO2 max).' color='#fff' fontSize={{ sm: "12px", xl: "14px" }} />
                </GridItem>
                <GridItem
                  padding={{ sm: "20px", lg: "40px" }}
                  borderBottom='1px solid rgba(255, 255, 255, .4)'
                >
                  <Image src='./images/threadAndShread2.png' />
                  <Heading title='IMPROVE HEALTH OUTCOMES' variant='extrasmall' color='#fff' padding='10px 0' />
                  <Text title='Improve your metabolic rate, insulin sensitivity, glycemic control and cognitive function, and decrease your risk of metabolic syndrome, osteoarthritis, and type 2 diabetes.' color='#fff' fontSize={{ sm: "12px", xl: "14px" }} />
                </GridItem>
                <GridItem
                  padding={{ sm: "20px", lg: "40px" }}
                  borderBottom='1px solid rgba(255, 255, 255, .4)'
                  borderLeft='1px solid rgba(255, 255, 255, .4)'
                >
                  <Image src='./images/threadAndShread3.png' />
                  <Heading title='RELIEVE STRESS' variant='extrasmall' color='#fff' padding='10px 0' />
                  <Text title='Improve your mental health and help reduce symptoms of stress and anxiety through a well-balanced exercise regimen.' color='#fff' fontSize={{ sm: "12px", xl: "14px" }} />
                </GridItem>
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Box
        padding={{ sm: "50px 0", md: "60px 0", xl:"80px 0" }}
        borderBottom='1px solid #ccc'
      >
        <Container maxW='container.xl'>
          <Heading title='Rhythm Ride Studios'
            variant='medium'
            padding='0 0 30px'
          />
          <SimpleGrid
            columns={{ sm: '1', md: '2' }}
            columnGap={10}
            rowGap={2}
          >
            <GridItem>
              <Image src='./images/ride_img3.webp' alt='Windmill Lane studio' />
              <Heading title='Windmill Lane'
                variant='smallmid'
                padding='20px 0 10px'
              />
              <Text title="Get ready to experience the best group fitness class in Ireland in our state of the art
              Windmill Lane studio. Our studio has Technogym treadmills, Torpedo's, dumbbells, slam
              balls and benches.
              " />
              <Box paddingTop='20px'>
                <NavLink to='/timetable'>
                  <Button title='Book A Class' className="wow fadeInUp" color='black' />
                </NavLink>
              </Box>
            </GridItem>
            <GridItem paddingTop={{ sm: "30px", md: "0" }}>
              <Image src='./images/ride_img2.webp' alt='Lennox Street studio' />
              <Heading title='Lennox Street'
                variant='smallmid'
                padding='20px 0 10px'
              />
              <Text title=" Get ready to experience the best group fitness class in Ireland in our state of the art
                Lennox Street studio. Our studio has Technogym treadmills, Torpedo's, dumbbells, slam
                balls and benches." />
              <Box paddingTop='20px'>
                <NavLink to='/timetable'>
                  <Button title='Book A Class' className="wow fadeInUp" color='black' />
                </NavLink>
              </Box>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Flex
        flexDirection='column'
        padding={{ sm: '50px 0', md: '60px 0', xl:"80px 0" }}
        textAlign='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Container maxW='container.xl'>
          <Box>
            <Heading
              as="h2"
              title="ARE YOU READY?"
              variant='extralarge'
              textAlign="center"
              lineHeight="1"
              padding={{ base: '0px 0px 15px', sm: '0px 0px 30px' }}
            >
            </Heading>
            <Text
              title="Try 3 classes for 33 euros. All fitness levels welcome "
              as='p'
            >
            </Text>

            <Link href='/contact-us' _hover={{ textDecoration: 'none' }}><Button title='Get Started' className="wow fadeInUp" color='black' margin='40px 0px 0px ' /> </Link>
            <Box >
              <Heading
                as="h5"
                title="Download our app"
                variant='extrasmall'
                textAlign="center"
                lineHeight="1"
                padding={{ base: '15px 0px 0px', sm: '30px 0px 0px' }}
                display='inline-block'
              >

              </Heading>

              <Link href="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness" target='_blank' >
                <Heading
                  as="h5"
                  title="Here"
                  variant='extrasmall'
                  textAlign="center"
                  lineHeight="1"
                  padding={{ base: '15px 0px 0px', sm: '30px 0px 0px' }}
                  display='inline-block'
                  margin='0px 0px 0px 5px'
                  color='Red'
                  textDecoration='underline'
                ></Heading>
              </Link>
            </Box>
          </Box>
        </Container>
      </Flex>

      <Box padding={{ sm: '35px 0px 30px', md: '80px 0px' }} background="#f7f7f7">
        <Container maxW='container.xl' >
          <Flex margin="auto" textAlign={'center'} width={{ sm: '100%', md: '50%' }} flexDirection="column" paddingBottom="30px">
            <Heading
              lineHeight={"1"}
              variant="large"
              title="Frequently Asked Questions"
              marginBottom='30px'
            />
            <Box mb="40px">
              <Text
                title="Questions about the classes or next steps? We got you. If you need more information, please feel free to contact us hello@perpetua.ie"
              />
            </Box>
          </Flex>

          <Flex flexDirection={{ sm: "column", md: "row" }}>
            <Box width={{ sm: '100%', md: '33.33%' }} paddingBottom={{ sm: "30px", md: 0 }}>
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="BEFORE CLASS"
                marginBottom='20px'
                padding="0 15px"
              />
              <Accordian data={accoData} />
            </Box>
            <Box width={{ sm: '100%', md: '33.33%' }} paddingBottom={{ sm: "30px", md: 0 }}>
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="DURING CLASS"
                marginBottom='20px'
                padding="0 15px"
              />
              <Accordian data={accoData2} />
            </Box>
            <Box width={{ sm: '100%', md: '33.33%' }} paddingBottom={{ sm: "30px", md: 0 }}>
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="AFTER CLASS"
                marginBottom='20px'
                padding="0 15px"
              />
              <Accordian data={accoData3} />
            </Box>
          </Flex>
        </Container>
      </Box>

      <BlogSection catSlug='ride' />

      <AppDownload
        // bg="url('/images/App-section-bg.webp')"
        heading='Your Daily Dose of Online Workouts Is Live'
        para='The Best Online Training Platform'
        imageUri='/images/iphone.webp'
        appstore='/images/app-store.png'
        googleplay='/images/google-play.png'
        className="_appdownload_section"
        googleplaylink="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
        appstorelink="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131"
        isAppButton={false}
        link="/online-gym-programming"
        alt="iphone - spin class dublin"
        color='#000'
      />
    </>
  );
}

export default RidePage;
