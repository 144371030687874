import * as React from 'react'
import { Button as ButtonChakra} from '@chakra-ui/react'
import { ButtonProps, defaultBottonProps } from './Button.type';
function Button(props) {
  return (
    
      <ButtonChakra
      isLoading={props.btnDisable}
      loadingText={props.title}
      spinnerPlacement="end"
      className={props.className}
      color={props.color} 
      variant='outline' 
      fontSize={{ sm:'xs' , md:'xs', xl:'sm2'}}  
      textTransform='uppercase' 
      borderColor='Red' 
      borderWidth='2px' 
      minW={props?.minW ? props?.minW : {sm:'100px', md:'150px', xl:'208px'}} 
      minH='49px' borderRadius='50px'
      _hover={{bg:'Red'}}
      margin={props.margin}
      width={props.width}
      type={props.submit}
      >
          {props.title}
      </ButtonChakra>
    
  );
}

export default Button;
Button.propTypes = ButtonProps;
Button.defaultProps = defaultBottonProps