import { Flex, Container,SimpleGrid,GridItem,Box,VStack,Link, Stack,Image} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import Heading from '../component/Heading/Heading';
import AppDownload from '../component/AppDownload/AppDownload';
import Text from '../component/SubHeading/SubHeading';
import Testimonial from '../component/Testimonial/Testimonial';
import Accordian from '../component/Accordion/Accordian';
import MarqueLoop from '../component/Marque Loop/MarqueLoop';
import PriceBox from '../component/Price Component/PriceBox';
import WhatsincludedCard from '../component/WhatsIncluded Card/WhatsincludedCard';
import LinkButton from '../component/Button/LinkButton';
import ContactForm from '../component/Form/ContactForm';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';
import VideoBanner from '../component/VideoBanner/VideoBanner';
import BlogSection from '../component/Blog/BlogSection';
import LookingInformation from '../component/LookingInformation/LookingInformation';


const accoData = [
  {
      "id": 1,
      "title": "HOW DO I ARRANGE A CONSULTATION?",
      "description": "Please fill in the form below and a member of the nutrition team will be in contact with you in the next 24 hours.",
  },
  {
      "id": 2,
      "title": "HOW LONG IS A NUTRITION PLAN?",
      "description": "Each plan is developed to run for 12 weeks - However if you need a longer or shorter plan, you can discuss with the coach",
  },
  {
      "id": 3,
      "title": "WHAT IS AN IN-BODY ANALYSIS?",
      "description": "This is a machine that we use in Perpetua to measure your weight, muscle mass, body fat and much more. This allow us to get an accurate reading of your starting point.",
  },
  {
      "id": 3,
      "title": "WHAT IS THE PRICING?",
      "description": "Our 12 week program starts at 300 euros. If you need any additional personal training on top of this, we will arrange a price plan for you. ",
  }

]
const accoData2 = [
  {
      "id": 1,
      "title": "HOW IS THE PROGRAM DELIVERED?",
      "description": "The nutrition program will be delivered through our training app where you can easily follow the plan and log your daily stats",
  },
  {
      "id": 2,
      "title": "HOW OFTEN DO I GIVE FEEDBACK?",
      "description": "We ask that clients log their daily food intake to provide us with an accurate measure of their nutrition.",
  },
  {
      "id": 3,
      "title": "WHEN IS THE WEEKLY CHECK IN?",
      "description": "This will be decided between you and your coach to find a time that suits.",
  },
  {
      "id": 4,
      "title": "HOW MUCH CONTACT DO I HAVE WITH THE COACH?",
      "description": "You can contact the coach through the training app or pop them an email if you have any questions.",
  },


]
const accoData3 = [
  {
      "id": 1,
      "title": "CAN I CONTINUE WITH THE PROGRAM?",
      "description": "Yes of course and we would encourage this. After you complete your initial program, we would sit down with the client and go over the results together. Once this is complete, you can decide on your goal and work with the coach. However, if you are happy with your results and want a break from the program, this is no problem.",
  },


]

const metaTitle="Nutrition Coaching Dublin | Perpetua Fitness"
const metaDesc="Nutrition coaching in Dublin with Perpetua Fitness. Learn how to balance your goals, lifestyle and nutrition for best results and enjoyment."



function Nutrition(props) {

  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc}/>
       
      <VideoBanner 
        videobanner='../video/Nutrition-banner.mp4'
        title="NUTRITION COACHING DUBLIN"
        subtitle={<Box>PERPETUA NUTRITION</Box>}       
        isButton={true} 
        issubtextpara={false} 
        buttontitle="Enquire now"
        margin="0px 0 0"
        Link="/contact-us"
        videoModal='https://www.youtube.com/embed/WeL4yfMoleY' 
        targetBlank={false}
        modalButtontitle="View The Experience"
        subtitleRight={<Box display='inline-block'>The accountability and guidance you need</Box>}
        issubtitleRight={true} 
      />

      {/*  */}
      <Flex backgroundColor='#fff' padding={{sm:"60px 0 30px", md:"70px 0 35px", xl:"80px 0 40px"}}>
        <Container maxW='container.xl'>
          <MarqueLoop textColor='#000' />
        </Container>
      </Flex>
      <Flex backgroundColor='#fff' padding={{sm:"30px 0", md:"35px 0", xl:"40px 0"}}>
        <Container maxW='container.xl'>
          <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='center'>
            <Box w={{ base: '100%', lg: '50%' }} padding={{ md: '0 20px', lg: '0' }}>
              <PriceBox
                color='#000'
                currency='€'
                priceValue='99'
                time='/8 Week Group Challenge'
                description='Learn how to balance your goals, lifestyle and nutrition for best results and enjoyment in our 8 Week Group Nutrition Challenge starting in January!'
                buttonText='Sign up now'
                link='https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107856'
                issubHeading={true}
                width={{ base: '100%', sm: '90%' }}
                border='2px solid'
                borderColor='Red'
                buttonPading='20px 25px'
                targetBlank={true}
                manheadind="Starting January 15th 2024"
                subheading='Next Group Starting Soon'
              />
            </Box>
            <Box w={{ base: '100%', lg: '50%' }} padding={{ md: '0 20px', lg: '0' }}>
              <Heading title='WHATS INCLUDED ' variant='small' color='#000' margin='0 0 30px' padding={{ sm: '0p', md: '0 0 0 31px' }} textAlign={{ sm: 'center', ms: 'left' }} />
              <SimpleGrid columns={{ base: '1', lg: '2' }} spacing='0px' className='_whatsinclude_box_body_blar _whatsinclude_box_body2 _whatsinclude_box_body_white'>
                <Box className='_whatsinclude_box_blar' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    title='In-Body Analysis'
                    headingColor='#000'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    alt="Nutrition Coaching Dublin - arrow icon"
                  />
                </Box>
                <Box className='_whatsinclude_box_blar' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    title='Individualised nutrition plan'
                    headingColor='#000'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    alt="Nutrition Coaching Dublin - arrow icon"
                  />
                </Box>
                <Box className='_whatsinclude_box_blar' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    title='24/7 support via whatsapp group chat'
                    headingColor='#000'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    alt="Nutrition Coaching Dublin - arrow icon"
                  />
                </Box>
                <Box className='_whatsinclude_box_blar' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    title='Nutrition guidebook & shopping list'
                    headingColor='#000'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    alt="Nutrition Coaching Dublin - arrow icon"
                  />
                </Box>
                <Box className='_whatsinclude_box_blar' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    title='Weekly check in & accountability'
                    headingColor='#000'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    alt="Nutrition Coaching Dublin - arrow icon"
                  />
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
        </Container>
      </Flex>
      {/*  */}
      
      <Flex  padding={{sm:"30px 0 60px", md:"35px 0 70px", xl:"40px 0 80px"}}  position="relative">
        <Container maxW='container.xl'>
          <Testimonial headingWidth={{sm:'97%', md:'90%', lg:'87%', xl:'69%'}} iconUri='/images/comment-quote-outline.svg' isname={false} 
          title='“I CANT BELIEVE THE RESULTS I GOT. THE PLAN WAS SO EASY TO FOLLOW AND THE ACCOUNTABILITY WAS EXACTLY WHAT I NEEDED. I CAN NOW SAY FOR THE FIRST TIME IN MY LIFE THAT I UNDERSTAND HOW TO EAT AND BE HEALTHY”'/>
        </Container>
      </Flex>

      <Box padding={{ sm: '50px 0', md: '70px 0', xl:"80px 0" }} backgroundColor='#f7f7f7'>
        <Container maxW='container.xl'>
          <Heading
            variant="extralarge"
            title='HOW DOES THE NUTRITION PROGRAM WORK?'
            margin={{ sm: '0px auto 50px', md: '0px auto 86px' }}
            width={{ base: '90%', md: '60%' }}
            textAlign='center'
            lineHeight='1'
            color='#000'
          />
          <SimpleGrid
            columns={{ sm: '1', md: '3' }}
            spacing={5}
            textAlign='center'
            justifyContent='center'
          >
            <GridItem>
              <Heading title='01' color='Red' textAlign='center' />
              <Heading
                title='ARRANGE A CONSULTATION'
                color='#000'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title='Before you begin the program, you arrange a consultation where they will do an in-body analysis with you, find out about your training and eating habits and explain the process.'
                color='#000'
                padding='15px 20px'
              />
            </GridItem>
            <GridItem>
              <Heading title='02' color='Red' textAlign='center' />
              <Heading
                title='START YOUR PROGRAM'
                color='#000'
                variant='small'
                padding= '15px 20px'
              />
              <Text
                title='When you receive your program, you will track your daily intake through our online program platform and give feedback. This is essential for our coaches to monitor your habits and see what changes are needed.'
                padding='15px 20px'
                color='#000'
              />
            </GridItem>
            <GridItem>
              <Heading title='03' color='Red' textAlign='center' />
              <Heading
                title='WEEKLY CHECK-INS'
                color='#000'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title=' Each week, the coach will analyze your feedback and progress. Based on this analysis, they will determine any necessary adjustments to your current plan and provide detailed feedback on the data you have submitted.'
                color='#000'
                padding='15px 20px'
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      {/* <TabComponent /> */}
      
      <Box padding={{sm:'50px 0px 30px', md:'80px 0px' }} background="#fff">
        <Container maxW='container.xl' >
          <Flex  margin="auto" textAlign={'center'} width={{sm:'100%', md:'50%' }} flexDirection="column" paddingBottom="30px">
            <Heading 
              lineHeight={"1"} 
              variant="large" 
              title="Frequently Asked Questions" 
              marginBottom='30px' 
            />
            <Box mb="40px">
                <Text 
                    title="Questions about the classes or next steps? We got you. If you need more information, please feel free to contact us hello@perpetua.ie "
                /> 
            </Box>
          </Flex>
          
          <Flex flexDirection={{sm: "column", md:"row"}}>
            <Box width={{sm:'100%', md:'33.33%' }} paddingBottom={{sm: "30px", md: 0}}>
              <Heading 
                lineHeight={"1"} 
                variant="smallmid" 
                title="BEFORE STARTING" 
                marginBottom='20px' 
                padding="0 15px"
              />
              <Accordian data={accoData} />
            </Box>
            <Box width={{sm:'100%', md:'33.33%' }} paddingBottom={{sm: "30px", md: 0}}> 
              <Heading 
                lineHeight={"1"} 
                variant="smallmid" 
                title="DURING THE PROGRAM" 
                marginBottom='20px' 
                padding="0 15px"
              />
              <Accordian data={accoData2} />
            </Box>
            <Box width={{sm:'100%', md:'33.33%' }} paddingBottom={{sm: "30px", md: 0}}>
              <Heading 
                lineHeight={"1"} 
                variant="smallmid" 
                title="AFTER COMPLETING THE PROGRAM" 
                marginBottom='20px' 
                padding="0 15px"
              />
              <Accordian data={accoData3} />
            </Box>
          </Flex>
        </Container>
      </Box>

      <BlogSection catSlug = 'nutrition' />

      <AppDownload 
        // bg="url('/images/App-section-bg.webp')"
        bg="url('/images/progra-t-banner.png')" 
        heading='Your Daily Dose of Online Workouts Is Live'
        para='The Best Online Training Platform'
        imageUri='/images/iphone.webp'
        appstore='/images/app-store.png'
        googleplay='/images/google-play.png'
        className="_appdownload_section"
        googleplaylink="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
        appstorelink="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131"
        isAppButton={false}
        link="/online-gym-programming"
        alt="iphone - Nutrition Coaching Dublin"
      />
      {/*  */}

      <LookingInformation pageName="Nutrition" heading='LOOKING FOR MORE INFORMATION'  subHeading='Chat with a member of the team' buttonTitle='BOOK A CALL'/>

      
    </>      
  );
}

export default Nutrition;