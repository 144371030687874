import React from 'react';
import { Flex, Box, Container, Stack, Text as PText, Image, UnorderedList, ListItem, SimpleGrid, GridItem, Link } from '@chakra-ui/react';
import Commoncard from '../component/Application/CommonCard';
import { CheckIcon } from '@chakra-ui/icons';
import Heading from "../component/Heading/Heading";
import Text from "../component/SubHeading/SubHeading";
import MetaTitle from './MetaTitle';

const MarketingAndEventsManager = () => {
  return (
    <>
      <MetaTitle title='Marketing Manager | Perpetua Fitness' />
      <Container maxW='container.xl' padding={{ sm: '150px 5px 0px 10px', md: '160px 0 0', xl: '185px 0px 60px' }} >
        <Flex flexDirection={{ sm: "column", md: "row" }} >
          <Box w={{ sm: "100%", md: "50%" }} h={{ sm: "auto" }} top={{ sm: "0", md: '100px' }} paddingLeft={{ sm: '20px', md: '30px' }} paddingBottom={{ sm: "30", md: '10px' }}>
            <Box marginTop={{ lg: '50px' }}>
              <Heading title='OPEN POSITION' cfSize='20px' lineHeight='1'></Heading>
              <Heading title='MARKETING MANAGER' cfSize={{ sm: '30px', md: '50px' }} lineHeight='1'></Heading>
            </Box>
            <Box marginTop={{ lg: '80px' }}>
              <UnorderedList paddingBottom='30px' m='10px 0px 0px' width={{ base: '90%', sm: '90%', md: '70%' }} listStyleType='none' color='black' fontFamily='Poppins' fontWeight='300' >
                <ListItem borderBottom="1px solid #DFDFDF" padding='10px 0px' position="relative" paddingLeft="30px"
                  _after={{ w: '10px', h: '10px', position: 'absolute', content: '""', top: '15px', left: '0', background: 'Red', borderRadius: '50px' }}>
                  <Heading title='FULL-TIME / PART-TIME' cfSize="20px" fontWeight='500'></Heading>
                  <Text title='Full-time' />
                </ListItem>
                <ListItem borderBottom="1px solid #DFDFDF" padding='10px 0px' position="relative" paddingLeft="30px"
                  _after={{ w: '10px', h: '10px', position: 'absolute', content: '""', top: '15px', left: '0', background: 'Red', borderRadius: '50px' }}>
                  <Heading title='LOCATION' cfSize="20px" fontWeight='500' />
                  <Text title='Dublin City' />
                </ListItem>
                {/* <ListItem borderBottom="1px solid #DFDFDF" padding='10px 0px' position="relative" paddingLeft="30px"
                  _after={{ w: '10px', h: '10px', position: 'absolute', content: '""', top: '15px', left: '0', background: 'Red', borderRadius: '50px' }}>
                  <Heading title='PERKS' cfSize="20px" fontWeight='500' />
                  <Text title='Flexible working hours, Company events & social hours and Gym and Studio access ' />
                </ListItem> */}
              </UnorderedList>
            </Box>
          </Box>
          <Box w={{ sm: "100%", md: "45%" }} >
            <Stack>
              <Image src='/images/MarketingAndEventsManager2.webp'></Image>
              <Image src='/images/MarketingAndEventsManager3.webp'></Image>
            </Stack>
          </Box>
        </Flex>
      </Container>

      <PText width={{ sm: '90%', md: "90%" }} borderTop="1px solid #DFDFDF" padding={{ md: "0px 0px 40px" }} margin='0 auto' marginTop={{ sm: '50px', md: '30px' }} marginBottom={{ sm: '20px', md: '0px' }} />
      {/* //////// */}


      <Box marginTop='40px'>
        <Container maxW='container.xl'>
          <SimpleGrid
            columns={{ sm: '1', md: '2' }}
            columnGap={0}
            rowGap={2}
            width="full" >
            <GridItem borderRight={{ sm: '0', md: "2px solid #DFDFDF" }} position='relative' >
              <PText width='25px' marginTop='8px' top='0' right='0' height='1' position='absolute' marginRight='10px' borderTop={{ sm: '0', md: '1px solid black' }}                               >
              </PText>
              <Box width='15px' height='15px' borderRadius='50px' boxShadow={{ sm: '0', md: '0px 0px 0px 5px rgba(209,44,65,0.3)' }} top='0' right='-9px' position='absolute' backgroundColor={{ sm: '', md: 'Red' }}>
              </Box>
              <Box paddingLeft={{ sm: '7px', md: '0' }} paddingBottom={{ sm: '20px', md: '0' }}>
                <Heading
                  title='WHO WE ARE'
                  textAlign={{ sm: 'left', md: "right" }}
                  fontSize={{ sm: '30px', md: '36px', lg: '50' }}
                  lineHeight="1"
                  width='90%'
                  padding={{ sm: '0px 0px 16px', md: '0px 5px' }}
                  margin={{ md: '-15px 0 0 0 ' }}
                />

                <Text
                  title="At Perpetua, we're dedicated to providing exceptional experiences that leave a lasting impression on our members. Our commitment to excellence is evident in everything we do, from our innovative products to our dedicated team. "
                  width='90%'
                  textAlign={{ sm: 'left', md: "right" }}
                  padding={{ md: '20px 5px 10px' }}
                />
                <Text
                  title="As a Marketing Manager, you'll play a crucial role in shaping our brand identity and driving growth. You'll be responsible for developing and executing effective marketing strategies, building strong relationships with our members, and ensuring that our brand message resonates with our target audience. "
                  width='90%'
                  textAlign={{ sm: 'left', md: "right" }}
                  padding={{ md: '10px 5px' }}
                />
              </Box>
            </GridItem>
          </SimpleGrid>
          <Flex>
            <SimpleGrid
              columns={{ sm: '1', md: '2' }}
              columnGap={0}
              rowGap={2}
              width="full"
            >
              <GridItem>
              </GridItem>
              <GridItem borderLeft={{ sm: '0', md: "2px solid #DFDFDF" }} position='relative'>
                <PText width='25px' marginTop='8px' top='0' left='0' height='1' position='absolute' marginLeft='10px' borderTop={{ sm: '0', md: '1px solid black' }}                              >
                </PText>
                <Box width='15px' height='15px' borderRadius='50px' boxShadow={{ sm: '0', md: '0px 0px 0px 5px rgba(209,44,65,0.3)' }} top='0' left='-8px' position='absolute' backgroundColor={{ sm: '', md: 'Red' }}>
                </Box>
                <Box paddingLeft={{ sm: '7px', md: '40px' }}>
                  <Heading
                    title='Join our Marketing Team'
                    cfSize={{ sm: '30px', md: '36px', lg: '50px' }}
                    textAlign={{ sm: 'left', md: "left" }}
                    lineHeight="1"
                    width={{ sm: '100%', md: '90%' }}
                    marginTop={{ md: '-20px' }}
                    paddingBottom={{ sm: '15px', md: '25px' }}
                    paddingLeft={{ md: '24px' }}
                    textTransform='uppercase'
                  />

                  <Text
                    title='Perpetua Fitness is seeking a motivated and enthusiastic Marketing Manager to join the team! The individual will report directly to the Owner/GM and will primarily be responsible for managing Perpetua’s internal & external marketing and sales efforts.'
                    width='90%'
                    textAlign="left"
                    padding={{ sm: '10px 0 0', md: '20px 25px 0' }}
                  />
                  <Text
                    title='This individual will own the following functions: email marketing and management of our CRM system; front end website development; content management systems; internal and external management of our booking/scheduling systems and mobile app; content marketing strategy and execution; organic and paid social media; digital advertising; corporate sales outreach. '
                    width='90%'
                    textAlign="left"
                    padding={{ sm: '10px 0 0', md: '10px 25px 0' }}
                  />
                  <Text
                    title='The type of person who will thrive in this role is a critical thinker and able to navigate and prioritize multiple projects and responsibilities at the same time while maintaining a positive attitude. You don’t have to be the best athlete out there, but we are seeking an individual that is passionate about health and wellness.'
                    width='90%'
                    textAlign="left"
                    padding={{ sm: '10px 0 0', md: '10px 25px 0' }}
                  />
                </Box>
              </GridItem>
            </SimpleGrid>
          </Flex>
          <Flex>
            <SimpleGrid
              columns={{ sm: '1', md: '2' }}
              columnGap={0}
              rowGap={2}
              width="full"
            >
              <GridItem borderRight={{ sm: '0', md: "2px solid #DFDFDF" }} position='relative' >
                <PText width='25px' marginTop='8px' top='0' right='0' height='1' position='absolute' marginRight='10px' borderTop={{ sm: '0', md: '1px solid black' }}                               >
                </PText>
                <Box width='15px' borderRadius='50px' boxShadow={{ sm: '0', md: '0px 0px 0px 5px rgba(209,44,65,0.3)' }} top='0' right='-9px' height='15px' position='absolute' backgroundColor={{ sm: '', md: 'Red' }}>
                </Box>
                <Box paddingLeft={{ sm: '0px', md: '0' }}>

                  <Heading
                    title='Specific Responsibilities'
                    textAlign={{ sm: 'left', md: "right" }}
                    cfSize={{ sm: '30px', md: '36px', lg: '50px' }}
                    lineHeight="1"
                    width='90%'
                    margin={{ sm: '30px 0 0', md: '-15px 0 0' }}
                    padding={{ sm: '10px 10px 20px', md: '0 0 20px' }}

                  />

                  <UnorderedList textAlign={{ sm: 'left', md: "right" }} paddingRight={{ md: '65px' }} m='0px' width={{ base: '90%', sm: '100%' }} listStyleType='none' color='black' fontFamily='Poppins' fontWeight='normal'>
                    <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                      Craft and execute a brand marketing strategy spanning all functions including social media, email marketing, brand partnerships, and corporate sales initiatives. <Box><CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' /></Box>
                    </ListItem>

                    <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                      Manage Perpetua’s digital product roadmap for the website, CRM system, and mobile app while appropriately prioritizing initiatives based on business needs.  <Box> <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' /></Box>
                    </ListItem>
                    <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                      Own and develop lifecycle marketing programs; creating email marketing flows and automations designed for customer retention and revenue generation.  <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='7px' color='#D3D3D3' />
                    </ListItem>
                    <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end' }}>
                      Creation and execution of digital advertising campaigns .  <Box> <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' /> </Box>
                    </ListItem>
                    <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                      Support company’s corporate sales program including prospecting partnerships, creating outreach and conversion plan, and ongoing administrative support.<CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                    </ListItem>
                    <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                      Understanding strategic business goals in order to create detailed briefs for new initiatives and effectively communicating to relevant teams. <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                    </ListItem>
                    <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                      Collaborating with GM, ownership, and coaching teams to ensure the timely and successful launch of new products, digital initiatives and marketing campaigns. <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                    </ListItem>
                    <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                      Overseeing all internal software including Perpetua’s content management systems, scheduling software/booking system, point of sale . <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                    </ListItem>
                    <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                      Maintaining ongoing communication and collaboration with our brand ambassadors.   <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                    </ListItem>
                    <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                      Organizing and hosting brand events, including product launches, meet-ups, and workshops. <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                    </ListItem>
                    <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                      Maintaining ongoing communication and collaboration with brand ambassadors and partners.  <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                    </ListItem>
                  </UnorderedList>
                </Box>
              </GridItem>
            </SimpleGrid>
          </Flex>
          <Flex>
            <SimpleGrid
              columns={{ sm: '1', md: '2' }}
              columnGap={0}
              rowGap={2}
              width="full"
            >
              <GridItem>
              </GridItem>
              <GridItem
                position='relative' _after={{ position: 'absolute', content: '""', width: { md: '2px' }, height: '90%', top: '0', left: '0', background: '#DFDFDF' }} paddingBottom={{ sm: "10px", md: '20px' }}
              >
                <PText width='25px' marginTop='8px' top='0' left='0' height='1' position='absolute' marginLeft='10px' borderTop={{ sm: '0', md: '1px solid black' }}                              >
                </PText>
                <Box width='15px' height='15px' zIndex='1' borderRadius='50px' boxShadow={{ sm: '0', md: '0px 0px 0px 5px rgba(209,44,65,0.3)' }} top='0' left='-8px' position='absolute' backgroundColor={{ sm: '', md: 'Red' }}>
                </Box>
                <Box paddingLeft={{ sm: '7px', md: '40px' }}>
                  <Heading
                    title='Skills & Experience Required'
                    fontSize={{ sm: '30px', md: '36px', lg: '50px' }}
                    textAlign={{ sm: 'left', md: "left" }}
                    lineHeight="1"
                    width={{ sm: '100%', md: '90%' }}
                    margin={{ sm: '20px 0 0', md: '-20px 0 0' }}
                    padding={{ sm: '0 0 15px ', md: '0 24px 25px' }}
                  />

                  <UnorderedList textAlign="left" paddingLeft={{ md: '22px' }} paddingBottom='30px' m='0px' width={{ base: '90%', sm: '100%' }} listStyleType='none' color='black' fontFamily='Poppins' fontWeight='normal' >
                    <ListItem padding='5px 0px' display='flex'>
                      <CheckIcon marginRight='10px' color='#D3D3D3' />
                      University degree in marketing, communications, or related field .
                    </ListItem>
                    <ListItem padding='5px 0px' display='flex'>
                      <CheckIcon marginRight='10px' color='#D3D3D3' />1+ years of marketing experience specific to digital products, lifecycle marketing, and social media
                    </ListItem>
                    <ListItem padding='5px 0px' display='flex'>
                      <CheckIcon marginRight='10px' color='#D3D3D3' />Knowledge of content management systems and digital marketing tools. Experience with scheduling software (e.g, Mindbody or similar)
                    </ListItem>
                    <ListItem padding='5px 0px' display='flex'>
                      <CheckIcon marginRight='10px' color='#D3D3D3' /> Strong analytical skills with the ability to generate meaningful and timely insights to drive action
                    </ListItem>
                    <ListItem padding='5px 0px' display='flex'>
                      <CheckIcon marginRight='10px' color='#D3D3D3' />A self-motivated individual with strong problem-solving skills and the ability to thrive in a fast-paced work environment
                    </ListItem>
                    <ListItem padding='5px 0px' display='flex'>
                      <CheckIcon marginRight='10px' color='#D3D3D3' />Strong written and oral communication skills.
                    </ListItem>
                    <ListItem padding='5px 0px' display='flex'>
                      <CheckIcon marginRight='10px' color='#D3D3D3' />Highly interested in fitness and wellness.
                    </ListItem>
                  </UnorderedList>
                </Box>
              </GridItem>
            </SimpleGrid>
          </Flex>
          {/* <Flex paddingBottom={{ sm: '50px', md: '70px' }} marginTop={{ md: '-90px' }}>
            <SimpleGrid
              columns={{ sm: '1', md: '2' }}
              columnGap={0}
              rowGap={2}
              width="full"
            >
              <GridItem position='relative' _before={{ position: 'absolute', content: '""', width: { md: '2px' }, height: '100%', top: '0', right: '0', background: '#DFDFDF' }} >
                <PText width='25px' marginTop='8px' top='0' right='0' height='1' position='absolute' marginRight='10px' borderTop={{ sm: '0', md: '1px solid black' }}                               >
                </PText>
                <Box width='15px' borderRadius='50px' boxShadow={{ sm: '0', md: '0px 0px 0px 5px rgba(209,44,65,0.3)' }} top='0' right='-7px' height='15px' position='absolute' backgroundColor={{ sm: '', md: 'Red' }}>
                </Box>
                <Box paddingLeft={{ sm: '7px', md: '0' }}>

                  <Heading
                    title='Benefits '
                    textAlign={{ sm: 'left', md: "right" }}
                    cfSize={{ sm: '30px', md: '36px', lg: '50px' }}
                    lineHeight="1"
                    width='90%'
                    margin={{ md: '-15px 0 0' }}
                    padding={{ sm: '0 0 10px', md: '0 0 20px' }}
                  />



                  <UnorderedList textAlign={{ sm: 'left', md: "right" }} paddingRight={{ md: '65px' }} m='0px' width={{ base: '90%', sm: '100%' }} listStyleType='none' color='black' fontFamily='Poppins' fontWeight='normal' >
                    <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', md: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                      Flexible working hours  <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft={{ md: '10px' }} color='#D3D3D3' />
                    </ListItem>
                    <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', md: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                      Company events & social hours <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='2px' color='#D3D3D3' />
                    </ListItem>
                    <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', md: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                      Gym and Studio access   <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='2px' color='#D3D3D3' />
                    </ListItem>

                  </UnorderedList>

                </Box>
              </GridItem>
            </SimpleGrid>
          </Flex> */}
        </Container>
      </Box>



      {/*  application  */}
      <Commoncard
        backgroundColor='rgb(238, 238, 238)'
        width={{ sm: '90%', md: '60%' }}
        fontSizeHeading={{ sm: '30px', md: '50px' }}
        fontSize='20px'
        color='#585858'
        padding='20px 0px'
        textAlign='center'
        title='JOIN TEAM Perpetua'
        subHeading="Full-time positions are available."
        subtitle="The type of person who will thrive in this role is a critical thinker and able to navigate and prioritize multiple projects and responsibilities at the same time while maintaining a positive attitude. You don’t have to be the best athlete out there, but we are seeking an individual that is passionate about health and wellness. "
        buttontitle='Application'
        isButton={true}
        buttonLink='https://6ys5dtrn9e0.typeform.com/to/T3jdkofB'
        isExternal='true'
      />
    </>
  )
}

export default MarketingAndEventsManager;