import { Box,Text,Heading,Image, Flex} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
// import { device } from "../../Device.types";

export const OutsideStudio=styled(Box)(props => ({
    padding:'0', margin:0,background:'#fff',
    "@media screen and (max-width:640px)": {
        padding:'50px 0 0',
    }
}))

export const HeadingSection=styled(Flex)(props => ({
    padding:0, margin:0,alignItems:'center', justifyContent:'center',textAlign:'center'
}))
export const MainHeading= styled(Heading)(props => ({
    paddingBottom:'30px', margin:0,fontWeight:'bold'
}))


export const ContentSection=styled(Flex)(props => ({
    padding:0, marginBottom:'50px',alignItems:'start', justifyContent:'Start',gap:'10px',
    "@media screen and (max-width:640px)": {
        flexDirection:'column',flexWrap:'wrap'
    }
}))

export const ContentsectionBox=styled(Flex)(props => ({
    padding:'20px', margin:0,alignItems:'start', justifyContent:'Start', flexDirection:'column',width:'49%',
    "@media screen and (max-width:640px)": {
        width:'100%',padding:'0'
    }
}))

export const AminitySection=styled(Flex)(props => ({
    paddingTop:'30px', margin:0,alignItems:'start', justifyContent:'Start', flexDirection:'row',gap:'15px', flexWrap:'wrap',
    borderTop: '1px solid #ccc',
}))

export const AminitySectionHeading=styled(Flex)(props => ({
    padding:0, margin:0,alignItems:'start', justifyContent:'start', flexDirection:'column', marginBottom:'15px',
}))

export const AminityBox= styled(Box)(props => ({
    padding:0, margin:0, width:'24%', marginBottom:'15px',
    
    "@media screen and (max-width:1199px)": {
        width:'32%',
    },
    "@media screen and (max-width:991px)": {
        width:'48%',
    },
    "@media screen and (max-width:640px)": {
        width:'100%',
    }
}))

export const AminityIconWrapper= styled(Box)(props => ({
    padding:0, margin:0,width:'50px',height:'50px',display:'flex',alignItems:'center',justifyContent:'center',textAlign:'center',marginBottom:'15px',
}))

export const AminityIcon= styled(Image)(props => ({
    padding:0, margin:0, objectFit:'cover',objectPosition:'center', width:'100%',height:'100%',
}))

export const AminityHeading= styled(Heading)(props => ({
    padding:0, margin:0,fontSize:'20px',fontWeight:'bold',textTransform:'uppercase',marginBottom:'5px',
}))

export const Aminitypara= styled(Text)(props => ({
    padding:0, margin:0,fontSize:'16px',
}))