import React from 'react'
import { Box, Container,Flex,GridItem, Link, SimpleGrid, Image } from '@chakra-ui/react';
import * as Style from './LandingPage1.style';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';
import Accordian from '../Accordion/Accordian';
import { NavLink } from 'react-router-dom';
import Button from '../Button/CustomeButton';
import VideoBanner from '../VideoBanner/VideoBanner';

const accoData = [
    {
        "id": 1,
        "title": "When should I arrive for class?",
        "description": "We recommend arriving at the gym 15 minutes before class for check-in. This will allow time for our Front Desk staff to get you checked into class and show you around the facility.",
    },
    {
        "id": 2,
        "title": "What do I do when I get there?",
        "description": "Say hello to whoever is at the front desk and mention that it is your first time. They’ll get you taken care of.",
    },
    {
        "id": 3,
        "title": "What if I'm running late?",
        "description": "To ensure that your spot is not released to the waitlist, please arrive at least 5 minutes prior to the start of your class.",
    },
    {
        "id": 3,
        "title": "Good Vibes Zone",
        "description": "We want you to bring your good vibes! We truly believe in making our gyms a space where members can check their stress at the door. In our gyms you’ll find a community built on sweat and support, all are welcome here. All we ask is that you show up, bring a smile, and get in a great workout. It’s that simple.",
    }
  
  ]
  const accoData2 = [
    {
        "id": 1,
        "title": "What is a Tread and Shred class like?",
        "description": "Our signature Tread&Shred class is a group fitness experience unmatched in Ireland. You’ll alternate between treadmill and floor rounds focused on strength, power, and endurance. You’ll torch calories and get stronger and faster every class.",
    },
    {
        "id": 2,
        "title": "I've never done this style of training before, can I keep up?",
        "description": "Absolutely! Our workouts are beginner friendly and designed to allow all athletes to workout at their own pace. Our Coaches are trained to modify and make adjustments to the workout on the fly to meet you where you are.",
    },
    {
        "id": 3,
        "title": "What should I wear?",
        "description": "Wear athletic clothes that are comfy for you to sweat and move in.",
    },
    {
        "id": 3,
        "title": "What shoes should I wear?",
        "description": "Most athletes wear traditional cross-training shoes suitable for cardio & strength training.",
    },
    {
        "id": 3,
        "title": "Do you guys provide water?",
        "description": "Each of our gyms are equipped with filtered water stations. Bring a reusable water bottle with you and fill up before class!",
    }
  
  ]
  const accoData3 = [
    {
        "id": 1,
        "title": "Do you have showers?",
        "description": "Yes! Each of the gyms have showers that you are free to use. Towels and bath & body products are also provided.",
    },
    {
        "id": 2,
        "title": "That was fun! What's next?",
        "description": "Awesome! Schedule your next workout through the Perpetua Fitness app. If you have any questions, please feel free to reach out to our team at any facility or through hello@perpetua.ie",
    }
  
  ]
  
  

const LandingPage1 = (props) => {
  return (
    <Style.PageWrapper>

        <VideoBanner
        videobanner='../video/T&S-banner.mp4'
        subtitle='A group fitness experience that is unmatched in Ireland'
        title={<Box>TREAD & SHRED <br /> WHAT TO EXPECT </Box>}
        isButton={true}
        issubtextpara={false}
        videoModal='https://www.youtube.com/embed/tezObtQQLIo'
        targetBlank={false}
        modalButtontitle="Watch First Timer Video"
        issubtitleRight={false}
        />

        <Style.ContentCard1 className='wow animate fadeIn'>
            <Container maxW="container.xl">
                <Heading title="Welcome to Tread & Shred " variant="extralarge" margin="0px 0px 25px"></Heading>
                <Text title="Our signature Tread & Shred class is a group fitness experience that is unmatched in Ireland. It is tailored towards complete beginners as well as professional athletes." as='p' margin="0px auto 15px" fontSize="18px" width="90%" ></Text>
                <Text title="You’ll alternate between treadmill and floor exercises focused on strength, power, and endurance. You’ll burn calories and get stronger and faster every class." as='p' margin="0px auto 15px" fontSize="18px" width="90%"></Text>
                <Text title="Each class is different with a mix between upper, lower and full body days so you never get bored. " as='p' margin="0px auto 15px" fontSize="18px" width="90%"></Text>
                <Text title="If you’re a newbie to Tread & Shred or need a little refresher, watch this short video so you know what to expect ahead of your first class." as='p' margin="0px auto 15px" fontSize="18px" width="90%" ></Text>
            </Container>
        </Style.ContentCard1>
          
        <Box padding="0 10px 50px">
            <Heading
                title='HALF TREADMILL, HALF FLOOR '
                textAlign='center'
                variant='large'
                padding='0 0 15px'
            />
            <Text
                title='45 mins with Ireland’s best coaches. Zero experience necessary. '
                textAlign='center'
            />
        </Box>
          
         <Container maxW="container.xl">
            <SimpleGrid
                columns={{ sm: '1', md: '2' }}
                columnGap={10}
                rowGap={2}
                padding={{ sm: "25px 0", md: "50px 0" }}
                alignItems='center'
                justifyContent='center'
            >
                <GridItem>
                    <Image src='./images/ThredAndshredFirsTimers1.webp' />
                </GridItem>
                <GridItem padding={{sm:"20px 0 0", md:"0"}}>
                    <Image src='./images/timer-icon-2.svg' margin={{sm:"0 auto", md:"0"}} className='firstTimersSvg' />
                    <Heading title='15 Mins Before Class' variant='small' padding='15px 0' textAlign={{sm:"center",md:"start"}} />
                    <Text title="Make sure you have an account with us, and you’re booked into class (that's
                        important). You can create an account here if you don’t have one." />
                    <Text title='Arrive 15 minutes prior to class time, so you can check in at Front of House,  get your spot number either starting on the floor or on the treadmill (so listen up!), pre-order a shake & make yourself comfortable.' padding='5px 0' />
                    <Text title='10 minutes before class you’ll receive and introduction to the SWEAT studio from
                        your coach'  />
                </GridItem>
            </SimpleGrid>
            <SimpleGrid
                columns={{ sm: '1', md: '2' }}
                columnGap={10}
                rowGap={2}
                padding={{ sm: "25px 0", md: "50px 0" }}
                alignItems='center'
                justifyContent='center'
            >
                <GridItem>
                    <Image src='./images/ThredAndshredFirsTimers2.webp' />
                </GridItem>
                <GridItem padding={{sm:"20px 0 0", md:"0"}}>
                    <Image src='./images/heart-icon.svg' margin={{sm:"0 auto", md:"0"}} className='firstTimersSvg' />
                    <Heading title='Your Introduction ' variant='small' padding='15px 0' textAlign={{sm:"center",md:"start"}} />
                    <Text title="10 minutes before class you’ll receive and introduction to the SWEAT studio from
                        your coach." />

                </GridItem>
            </SimpleGrid>

            <SimpleGrid
                columns={{ sm: '1', md: '2' }}
                columnGap={10}
                rowGap={2}
                padding={{ sm: "25px 0", md: "50px 0" }}
                alignItems='center'
                justifyContent='center'
            >
                <GridItem> 
                    <Image src='./images/ThredAndshredFirsTimers4.webp' />
                </GridItem>
                <GridItem padding={{sm:"20px 0 0", md:"0"}}>
                     <Image src='./images/bbbb-removebg-preview.svg' margin={{sm:"0 auto", md:"0"}} className='firstTimersSvg' />
                    <Heading title='Treadmill' variant='small' padding='15px 0' textAlign={{sm:"center",md:"start"}} />
                    <Text title="Lace up your trainers and get ready to work. your coach will guide you through the likes of lung busting sprints, incline intervals and energy sapping sled pushes. not a runner? no bother. your coach will guide you every step of the way - beginner, intermediate, and advanced runners out there, we have you covered!" />
                </GridItem>
            </SimpleGrid>

            <SimpleGrid
                columns={{ sm: '1', md: '2' }}
                columnGap={10}
                rowGap={2}
                padding={{ sm: "25px 0", md: "50px 0" }}
                alignItems='center'
                justifyContent='center'
            >
                <GridItem>
                    <Image src='./images/ThredAndshredFirsTimers7.webp' />
                </GridItem>
                <GridItem padding={{sm:"20px 0 0", md:"0"}}>
                    <Image src='./images/strength-icon-1.svg' margin={{sm:"0 auto", md:"0"}} className='firstTimersSvg' />
                    <Heading title='Floorwork' variant='small' padding='15px 0' textAlign={{sm:"center",md:"start"}} />
                    <Text title="Let’s get spicy. our floor rounds incorporate a variety of movements and equipment, including boxes, trx ropes, and our signature torpedoes. floor rounds are designed to maximise your strength and agility, complimenting the work you’re doing on the tread." />
                </GridItem>
            </SimpleGrid>

            <SimpleGrid
                columns={{ sm: '1', md: '2' }}
                columnGap={10}
                rowGap={2}
                padding={{ sm: "25px 0 50px", md: "50px 0 100px" }}
                alignItems='center'
                justifyContent='center'
            >
                <GridItem>
                    <Image src='./images/ThredAndshredFirsTimers6.webp' />
                </GridItem>
                <GridItem padding={{sm:"20px 0 0", md:"0"}}>
                    <Image src='./images/aaaaa-removebg-preview.svg' margin={{sm:"0 auto", md:"0"}} className='firstTimersSvg' />
                    <Heading title='After Class' variant='small' padding='15px 0' textAlign={{sm:"center",md:"start"}} />
                    <Text title="Give yourself a pat on the back for completing your first class!" />
                    <Text title="Collect your shake at Mojo Health Bar (look for your name in the fridge)." padding='5px 0' />
                    <Text title="Go to perpetua.ie, purchase your next studio credit pack (if you’re on an Intro Offer, check your emails!) or membership and pre-book for your next class." />
                </GridItem>
            </SimpleGrid>

        </Container>

        <Box padding={{sm:'35px 0px 30px', md:'80px 0px' }} background="#f7f7f7" className='wow animate fadeIn'>
            <Container maxW='container.xl' >
            <Flex  margin="auto" textAlign={'center'} width={{sm:'100%', md:'50%' }} flexDirection="column" paddingBottom="30px">
                <Heading 
                lineHeight={"1"} 
                variant="large" 
                title="FREQUENTLY ASKED QUESTIONS" 
                marginBottom='30px' 
                />
                <Box mb="40px">
                    <Text 
                        title="Questions about the classes or next steps? We got you. If you need more information, please feel free to contact us hello@perpetua.ie"
                    /> 
                </Box>
            </Flex>
            
            <Flex flexDirection={{sm: "column", md:"row"}}>

                <Box width={{sm:'100%', md:'33.33%' }} paddingBottom={{sm: "30px", md: 0}}>
                <Heading 
                    lineHeight={"1"} 
                    variant="smallmid" 
                    title="BEFORE CLASS" 
                    marginBottom='20px' 
                    padding="0 15px"
                />
                <Accordian data={accoData} />
                </Box>
                <Box width={{sm:'100%', md:'33.33%' }} paddingBottom={{sm: "30px", md: 0}}> 
                <Heading 
                    lineHeight={"1"} 
                    variant="smallmid" 
                    title="DURING CLASS" 
                    marginBottom='20px' 
                    padding="0 15px"
                />
                <Accordian data={accoData2} />
                </Box>
                <Box width={{sm:'100%', md:'33.33%' }} paddingBottom={{sm: "30px", md: 0}}>
                <Heading 
                    lineHeight={"1"} 
                    variant="smallmid" 
                    title="AFTER CLASS" 
                    marginBottom='20px' 
                    padding="0 15px"
                />
                <Accordian data={accoData3} />
                </Box>
            </Flex>
            </Container>
        </Box>
        <Style.tremsandcondition className='wow animate fadeIn'>
            <Container maxW="container.xl">
                <Style.tremsandconditionWrapper>
                    <Heading title="TERMS & CONDITIONS" variant="large" margin="0px 0px 5px" color='#000'/>
                    <Text title="Offer valid only for first-time referral clients on an Intro Offer 3 for €33. Promotion can only be used for one #PlusOne at a time. First-timer must purchase an Intro Offer online and take a class with the existing client. Both parties must be present and check in at the front of house together to be eligible for the promotion. Not applicable for private events or corporate buy-outs, including full and/or partial buy-outs. All complimentary class credits expire 30 days from the date credited. Promotion ends 30/09/2023." as='p' fontSize="18px" color='#000'/>
                </Style.tremsandconditionWrapper>
            </Container>
        </Style.tremsandcondition>
          
        <Flex
            flexDirection='column'
            padding={{ sm: '0px 0px 50px', md: '50px 0px 100px' }}
            textAlign='center'
            justifyContent='center'
            justifyItems='center'
        >
            <Container maxW='container.xl'>
                <Box>
                    <Heading
                        as="h2"
                        title="ARE YOU READY?"
                        variant='extralarge'
                        textAlign="center"
                        lineHeight="1"
                        padding={{ base: '0px 0px 15px', sm: '0px 0px 30px' }}
                    >
                    </Heading>
                    <Text
                        title="Try 3 classes for €33. All fitness levels welcome "
                        as='p'
                    >
                    </Text>
                    <NavLink to='/contact-us' _hover={{ textDecoration: 'none' }}><Button title='Get Started' className="wow fadeInUp" color='black' margin='40px 0px 0px ' /> </NavLink>
                    <Box>
                        <Heading
                            as="h5"
                            title="Download our app "
                            variant='extrasmall'
                            textAlign="center"
                            lineHeight="1"
                            padding={{ sm: '15px 0px 0px', md: '30px 0px 0px' }}
                            display='inline-block'
                        >

                        </Heading>

                        <Link href="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness" target='_blank' >
                            <Heading
                            as="h5"
                            title="Here"
                            variant='extrasmall'
                            textAlign="center"
                            lineHeight="1"
                            padding={{ sm: '15px 0px 0px', md: '30px 0px 0px' }}
                            display='inline-block'
                            margin='0px 0px 0px 5px'
                            color='Red'
                            textDecoration='underline'
                            ></Heading>
                        </Link>
                    </Box>
                </Box>
            </Container>
        </Flex>
          
    </Style.PageWrapper>
  )
}

export default LandingPage1