
import React from 'react';
import { Box, Stack, Image } from '@chakra-ui/react';
import Heading from '../Heading/Heading';
import LazyLoad from "react-lazy-load";


function WhatsincludedCard(props) {
    return (
        <>
            <Stack direction={{ base: 'row' }} spacing='0px' textAlign={{ base: 'left' }} alignItems='center' padding={props.spacing} minHeight={{ sm: 'auto', md: '65px', lg:"80px" }}>
                <Box width='20px' height='20px' position='relative' top='0'>
                    <LazyLoad offset={300}>
                        <Image src='/images/arrow-icon.png' width='100%' alt={props.alt} />
                    </LazyLoad>
                </Box>
                <Box paddingLeft={{ base: '10px', xl: '25px' }} width={{ base: '90%' }}>
                    <Heading variant='extrasmall' title={props.title} as='h4' color={props.headingColor} />
                </Box>
            </Stack>
        </>
    )
}
export default WhatsincludedCard;
