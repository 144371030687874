import React, {useEffect} from 'react';
import {Container,Box,SimpleGrid, Flex,Text} from '@chakra-ui/react';
import JointeamCard from '../component/JoinTeamCard/JointeamCard';
import InnerBanner from '../component/InnerBanner/InnerBanner';
import Heading from '../component/Heading/Heading';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';

function WorkforPerpetua(props) {

  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])

  return (
    <>
      <MetaTitle title='Work for perpetua | Perpetua Fitness'/>
      <InnerBanner 
        backgroundColor='black' 
        backgroundImage={{sm:'url(./images/work_at_perpetua_m.jpg)', md:'url(./images/work_for_perpetua_banner.png)'}} 
        subtitle={false}
        title='WORK AT PERPETUA'
        subtitleRight='The Best Studio Experience In Ireland'
        subtextpara='Want to work with a team that focuses on career progression? Look no further'
        isButton={false} 
        issubtitleRight={false} 
        issubtextpara={true}
        width="100%"
        height={{sm:'400px', md:'400px', lg:'460px', xl:'460px'}}
        align='0px auto'
        textAlign='center'
        maxW = {{sm:"100%", md:"800px"}}
        />
        <Container maxW='container.xl'  padding={{sm:'100px 15px'}}>
          <SimpleGrid columns={{base:'1',md:'2',lg:'3'}} spacing={6} >
            <Box>
              <JointeamCard 
                Imguri="url('/images/WFP-box1.jpg')" 
                heading='CROSSFIT COACH' 
                to='/crossfit-coach' 
                subheadingfontSize='17px'
                spacing='20px 0px 0px'
                linktitle='Read more'               
              />
            </Box>
            <Box>
              <JointeamCard 
                Imguri="url('/images/WFP-box2.jpg')" 
                heading='STUDIO COACH' 
                to='/treadandshred-instructor' 
                subheadingfontSize='17px'
                spacing='20px 0px 0px'
                linktitle='Read more'
              />
            </Box>
            <Box>
              <JointeamCard 
                Imguri="url('/images/WFP-box3.jpg')" 
                heading='FRONT OF HOUSE' 
                to='/font-of-house' 
                subheadingfontSize='17px'
                spacing='20px 0px 0px'
                linktitle='Read more'
              />
            </Box>
            <Box>
              <JointeamCard 
                Imguri="url('/images/WFP-box4.jpg')" 
                heading='FACILITIES' 
                to='/facilitics' 
                subheadingfontSize='17px'
                spacing='20px 0px 0px'
                linktitle='Read more'             
              />
          </Box>
          <Box>
              <JointeamCard 
                Imguri="url('/images/MarketingAndEventsManager5.webp')" 
                heading='Marketing Manager' 
                to='/marketing-manager' 
                subheadingfontSize='17px'
                spacing='20px 0px 0px'
                linktitle='Read more'             
              />
            </Box>
          </SimpleGrid>
        </Container>

        <Flex background='#f7f7f7' borderBottom='1px solid #232323' padding={{sm:"50px 0", md:"70px 0", xl:"80px 0"}}>
          <Container maxW='container.xl'>
            <Box margin="0px auto" w='90%' textAlign='center'>
              <Heading as='h2' color="#000" variant='medium' className='wow animate fadeInUp'  lineHeight='1.3' margin='0px 0px 52px' title='AT PERPETUA Community and movement is at the heart of everything we do here. We live for intelligent programming, functional fitness and having a good time together while sweating it out.' />
              <Text 
                className='wow animate fadeInUp' 
                fontSize={"2xl"} 
                as="p" 
                fontFamily='Poppins' 
                marginTop='50px' 
                color='#000'
                fontWeight='300'
                textTransform='uppercase'
              > 
                <Text as="b" paddingRight='8px'>Make</Text> 
                Your Move
              </Text>
            </Box>
          </Container>
        </Flex>
    </>
  );
}

export default WorkforPerpetua;