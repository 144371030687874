import React from 'react';
import { Box, Container, GridItem, SimpleGrid } from '@chakra-ui/layout';
import ClassCard from '../component/Classcard/ClassCard';
import MetaTitle from './MetaTitle';

const StudioClassDescriptions = () => {

    const metaTitle = "Studio Class Description | Perpetua Fitness";

    return (
        <Box>
            <MetaTitle title={metaTitle} />

            <Container maxW='container.xl'>
                <SimpleGrid
                    columns={{ sm: '1', md: '2', xl: '3', }}
                    columnGap={4}
                    rowGap={5}
                    width="full"
                    padding={{ sm: '130px 10px 50px', md: '150px 0 70px' }}
                >
                    <GridItem data-wow-duration="1s" data-wow-delay="0.7s" paddingTop={{ sm:"20px", md:'0px'}}>
                        <ClassCard
                            title='TREAD & SHRED'
                            subtitle='Our signature Tread & Shred class is a group fitness experience that is unmatched in Ireland. It is
                            tailored towards complete beginners as well as professional athletes.
                            You’ll alternate between treadmill and floor exercises focused on strength, power, and endurance.
                            You’ll burn calories and get stronger and faster every class.
                            Each class is different with a mix between upper, lower and full body days so you never get bored.
                            If you’re a newbie to Tread & Shred or need a little refresher, watch this short video so you know
                            what to expect ahead of your first class.'
                            bg="url('/images/TreadAndShread/WarmUp.jpg')"
                            isButton={false}
                            marginBottom='30px'
                            transform="uppercase"
                            minHeight={{ sm: '500px', md: '450px',  xl: '500px', xxl: '600px' }}
                        />
                    </GridItem>
                    <GridItem paddingTop={{ sm:"20px", md:"0"}} data-wow-duration="1s" data-wow-delay="0.9s">
                        <ClassCard
                            title='RHYTHM RIDE'
                            subtitle='MORE THAN A SPIN CLASS. AN EXPERIENCE. FULLY EQUIPPED WITH KEISER M3I BIKES AND THE BEST
                            LIGHTING AND SOUND SYSTEM IN IRELAND. OUR RIDE45, AND RHYTHM RIDE CLASSES OFFER
                            SOMETHING FOR EVERYONE.'
                            bg="url('/images/RhythmRide/Beat.jpg')"
                            isButton={false}
                            marginBottom='30px'
                            transform="uppercase"
                            minHeight={{ sm: '500px', md: '450px', xl: '500px', xxl: '600px' }}
                        />
                    </GridItem>
                    <GridItem  paddingTop={{ sm:"20px", md:'0px', lg:"0"}} data-wow-duration="1s" data-wow-delay="0.9s">
                        <ClassCard
                            title='RIDE 45'
                            subtitle="RIDE45 is for everyone, the class scales based on the gears you select,so you're in
                            total control of the difficuly. The useof the Keiser Group app shows the
                            participants how far they have travelled and incorporates some group
                            challenges,so if you're a competitive or data-driven person, this is could be further
                            to your liking. You also have the option of staying off the screen, so don't let that
                            beter you."
                            bg="url('/images/Ride45/Metric.jpg')"
                            isButton={false}
                            marginBottom='30px'
                            transform="uppercase"
                            minHeight={{ sm: '500px', md: '450px', xl: '500px', xxl: '600px' }}
                        />
                    </GridItem>
                    <GridItem paddingTop={{ sm:"20px", md:'0px', xl:"30px"}} data-wow-duration="1s" data-wow-delay="0.9s">
                        <ClassCard
                            title='RUMBLE BOXING'
                            subtitle='We’ve ripped up the boxing rulebook to bring you a fast-paced workout that improves your strength,
                            endurance and power all while working up a sweat to some big beats.
                            Rumble is a group fitness experience where you’ll alternate between the treadmill and boxing so
                            lace up your trainers and get your gloves on!
                            Get ready to learn some legendary combinations mixed in with running intervals.
                            If you’re a newbie to Rumble or need a little refresher, watch this short video so you know what to
                            expect ahead of your first class.'
                            bg="url('/images/Rumble/Cooldown.jpg')"
                            isButton={false}
                            marginBottom='30px'
                            transform="uppercase"
                            minHeight={{ sm: '500px', md: '450px', xl: '500px', xxl: '600px' }}
                        />
                    </GridItem>
                    <GridItem paddingTop={{ sm:"20px", md:'0px', xl:"30px"}} data-wow-duration="1s" data-wow-delay="0.9s">
                        <ClassCard
                            title='RUMBLE 12 ROUNDS'
                            subtitle='OUR FIRST CLASS CONCEPT THAT FOCUSES SOLELY ON IMPROVING YOUR BOXING SKILLS – OUR 12
                            ROUND CLASS IS A GROUP FITNESS EXPERIENCE WHERE YOU WILL GO THROUGH 12 ROUNDS OF
                            SKILLS DRILLS ON OUR AQUA BAGS WITH THE GOAL OF BECOMING A BETTER BOXER.'
                            bg="url('/images/Rumble/BagWork.jpg')"
                            isButton={false}
                            marginBottom='30px'
                            transform="uppercase"
                            minHeight={{ sm: '500px', md: '450px', xl: '500px', xxl: '600px' }}
                        />
                    </GridItem>
                </SimpleGrid>
            </Container>
        </Box>
    )
}

export default StudioClassDescriptions;