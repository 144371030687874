import React, {useEffect} from "react";
import InnerBanner from '../component/InnerBanner/InnerBanner';
import * as Style from './InBody.style';
import Heading from "../component/Heading/Heading";
import Text from "../component/SubHeading/SubHeading";
import VideoCard from "../component/VideoCard/VideoCard";
import InbodyForm from "../component/Form/InbodyForm";
import { Container, ListItem, UnorderedList, Image, Box } from '@chakra-ui/react';
import InBodyBox from "../component/InbodyBox/InbodyBox";
import MetaTitle from "./MetaTitle";
import WOW from 'wowjs';

const InBodyBoxs = [
    {
        "Image": "url(/images/Crossfit-banner.jpg)",
        "Heading": "Sign Up Today",
        "Paragraph": "We have a wide range of membership options available from single, family, student and more. Payment options include direct debit or annual membership.",
        "buttonTitle": "Join Online ",
        "isdropdown": true,
        "link": "/contact-us"
    },
    {
        "Image": "url(/images/Crossfit-banner.jpg)",
        "Heading": "Become a Member",
        "Paragraph": "Learn more about our wide range of Membership Options available and unique benefits of an Aura Membership",
        "buttonTitle": "Learn More",
        "isdropdown": false,
        "link": "/contact-us"
    },
    {
        "Image": "url(/images/Crossfit-banner.jpg)",
        "Heading": "Pay As You Go",
        "Paragraph": "If a direct debit or upfront membership isn’t for you, why not Pay As You Go? Options available for pool, gym and classes.",
        "buttonTitle": "Register Now",
        "isdropdown": true,
        "link": "/contact-us"
    },
]

const InBody = (props) => {

    useEffect(() => {
        new WOW.WOW({
          live: true,
          offset: 0,
          mobile: true,
        }).init();
      }, [])

    return (
        <>
        <MetaTitle title='Inbody | Perpetua Fitness' />
        <Style.InBodyWraper>

            <InnerBanner
                backgroundColor='black'
                backgroundImage={{ sm:'url(./images/inbody_m.jpg)' ,md:'url(./images/Crossfit-banner.jpg)'}}
                subtitle='InBody'
                title='Body Composition Analysers'
                subtextpara='THE MOST ACCURATE body composition analyser available
                Measures fat mass, muscle mass and body water levels in JUST 2 MINUTES'
                issubtextpara={true}
                isButton={false}
                buttontitle='ENQUIRE NOW'
                issubtitleRight={false}
                isSubtext={true}
                width={{ sm: "100%" }}
                height={{ sm: '450px', md: '500px', lg: '550px', xl: '767px' }}
                Link='/contact-us'
                margin='10px 0px 0px'
            />

            <Style.BodyCompositionAnalysers background='#f7f7f7'>
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                    <Style.BodyCompositionAnalysersHeader>
                        <Heading title='Now available at Perpetua Fitness'></Heading>
                        <Text title='So much more than a weighing scales, the InBody scanner provides you with an in-depth analysis of your body composition and gives you specific targets to either lose or gain weight, fat and muscle . This accurate and simple 2 minute process gives you a snap shot of your health and the foundation to improve on specific areas like fat loss, muscle tone, visceral fat loss, BMI, lean muscle mass, general health and illness prevention. Your results combined with the support of our highly trained staff will guide you to your goals.'></Text>
                    </Style.BodyCompositionAnalysersHeader>
                    <Style.BodyCompositionAnalysersBody>
                        <Style.BodyCompositionAnalysersBodyLeft>
                            <Text title=' We are thrilled to introduce our amazing InBody 270 Body Composition Scanner, now available at PERPETUA fitness!'></Text>
                            <UnorderedList>
                                <ListItem>
                                    <Image src="./images/Vector.png" width='13px' height='13px' marginTop='5px' marginRight='7px' />
                                    <Text as='p' title=' THE MOST ACCURATE body composition analyser available'></Text>
                                </ListItem>
                                <ListItem>
                                    <Image src="./images/Vector.png" width='13px' height='13px' marginTop='5px' marginRight='7px' />
                                    <Text as='p' title=' Measures fat mass, muscle mass and body water levels in JUST 2 MINUTES'></Text>
                                </ListItem>
                                <ListItem>
                                    <Image src="./images/Vector.png" width='13px' height='13px' marginTop='5px' marginRight='7px' />
                                    <Text as='p' title=' FREE ANALYSIS for Personal Training Clients'></Text>
                                </ListItem>
                                <ListItem>
                                    <Image src="./images/Vector.png" width='13px' height='13px' marginTop='5px' marginRight='7px' />
                                    <Text as='p' title='InBody Bundle available to purchase'></Text>
                                </ListItem>
                            </UnorderedList>

                        </Style.BodyCompositionAnalysersBodyLeft>
                        <Style.BodyCompositionAnalysersBodyRight>
                            <Box className="background-image" backgroundImage='url(./images/Crossfit-banner.jpg)'></Box>
                        </Style.BodyCompositionAnalysersBodyRight>
                    </Style.BodyCompositionAnalysersBody>
                </Container>
            </Style.BodyCompositionAnalysers>

            <Style.BodyCompositionForm >
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }} textAlign='center'>
                    <Heading title='Get in touch to Book InBody' margin='0px 0px 30px'></Heading>
                    <Style.FormBoxWraper>
                        <Style.FormImage backgroundImage='url(./images/Crossfit-banner.jpg)'></Style.FormImage>
                        <Style.FormContent>
                            <InbodyForm></InbodyForm>
                        </Style.FormContent>
                    </Style.FormBoxWraper>
                </Container>
            </Style.BodyCompositionForm>

        </Style.InBodyWraper>
        </>
    )
};

export default InBody;