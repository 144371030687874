import React, { useEffect, useRef } from "react";

import {Container,Box,Stack,Link, Center} from '@chakra-ui/react';
import * as Style from './LandingStyle.style';
import { Tabs, TabList, TabPanels, TabPanel } from '@chakra-ui/react';
import { Modal, ModalOverlay,ModalContent,ModalBody,ModalCloseButton, useDisclosure, } from '@chakra-ui/react';
import ImagePalce from '../component/Image/Image';
import { ImagePlace } from '../component/Classcard/ClasscardV2.style';
import Button from '../component/Button/CustomeButton';
import ContactusForm from '../component/Form/Form';

const ValentinesTakeover = () => {

const videoEl = useRef(null);    
const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
        <Style.PageBanner backgroundImage={{sm:'url(./images/small-view.webp)' ,md:'url(./images/Valentines-Takeover.webp)'}} href="/timetable" target='_blank'></Style.PageBanner>

        <Style.CenterContent backgroundColor='#f7f7f7'>
            <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                <Style.VideoSection>
                    <Style.VideoContent>
                        <Style.VideoBox>
                            <video
                            style={{ maxWidth: "100%",width: "100%", margin: "0 auto", objectFit:'cover',objectPosition:'center top',flex: '1 1 0%', }}
                            playsInline
                            loop={true}
                            muted
                            controls={false}
                            alt="All the devices"
                            src={require('../../src/video/Valentines-Promo.mp4')}
                            ref={videoEl}
                            autoPlay={true}
                            />
                        </Style.VideoBox>
                    </Style.VideoContent>
                    <Style.VideoBoxContent>
                        <Style.CenterContentWrapper marginBottom='20px'>
                            <Style.CenterContentHeader alignItems='flex-start' justifyContent='flex-start' textAlign='left'>
                                <Style.h2 variant='medium' title='Valentines Takeover in the Ride Studio' color='#000' width={{sm:'100%', md:'70%'}}/>
                                <Style.SubText >
                                    <Style.p title='At' fontSize={{lg:'30px', xl:'30px',md:'25px',sm:'18px'}} color='#000'/>
                                    <Style.bold title='Perpetua' fontSize={{lg:'30px', xl:'30px',md:'25px',sm:'18px'}} color='#E7354D'/>
                                    <Style.p title='Fitness' fontSize={{lg:'30px', xl:'30px',md:'25px',sm:'18px'}} color='#000'/>
                                </Style.SubText>
                            </Style.CenterContentHeader>
                        </Style.CenterContentWrapper>
                    </Style.VideoBoxContent>
                </Style.VideoSection>
            </Container>
        </Style.CenterContent>

        <Style.ContentSection>
            <Style.LineTop backgroundImage={'url(./images/line-top.svg)'}/>
            {/* <Style.WaveGraphics backgroundImage={'url(./images/boot-graphics.svg)'}/> */}
            <Container maxW={{ md: 'container.lg', xl: 'container.xl' }} position='relative' zIndex='1'>
                <Tabs variant='soft-rounded' alignItems='center' justifyContent='center' defaultIndex={0}>
                    <Box>
                        <Modal isOpen={isOpen} onClose={onClose} size={{sm:'sm', md:'3xl'}} color='#000' >
                          <ModalOverlay />
                          <ModalContent marginTop='100px'>
                              {/* <ModalHeader>Modal Title</ModalHeader> */}
                              <ModalCloseButton color='#000'/>
                              <ModalBody background='#fff' padding={4}>
                                    <Box>
                                        <ContactusForm></ContactusForm>
                                    </Box>
                              </ModalBody>
                          </ModalContent>
                        </Modal>
                    </Box>
                    <TabList alignItems='center' justifyContent='center' className="scroll-view">
                        <Style.TabItem className='tab'>Single Era</Style.TabItem>
                        <Style.TabItem className='tab'>Love At First (Rhythm) Ride</Style.TabItem>
                        <Style.TabItem className='tab'>The Break Up</Style.TabItem>
                        <Style.TabItem className='tab'> THe Glow Up</Style.TabItem>
                        <Style.TabItem className='tab'> February Schedule</Style.TabItem>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Style.TabContent backgroundImage={'url(./images/bg-valantine.webp)'}>
                                <Style.CenterContentWrapper>
                                    <Style.VideoSection padding={{sm:'20px', md:'30px'}} borderBottom='2px dashed rgba(240, 103, 118, 0.53)'>
                                        <Style.VideoBoxContent paddingLeft={{md:'20px', sm:'0px'}}>
                                            <Box textAlign='center'>
                                                <Box position='relative'>
                                                    <Style.h2 variant='extralarge' title='Single Era' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='25px' color='#F6536F'/>
                                                    <Style.highlight backgroundImage={'url(./images/highlight.svg)'}/>
                                                </Box >
                                                <Style.p title='Join us for your Single Era with a Magic Mike themed Rhythm Ride followed by a Makeup 
                                                Masterclass by Painted by Ciara with Perpetua & Prosecco and some special guests' 
                                                fontSize={{lg:'16px', xl:'18px'}} width={{sm:'100%'}}  />
                                            </Box>
                                        </Style.VideoBoxContent>
                                        <Box width={{md:'45%',sm:'100%'}} alignItems={{md:'flex-start', sm:'center'}} justifyContent={{md:'flex-start', sm:'center'}} display='flex' paddingLeft={{md:'80px', sm:'0px'}}>
                                            <Box width='60%' borderRadius='15px' overflow='hidden'>
                                                <video
                                                style={{ maxWidth: "100%",width: "100%", margin: "0 auto", objectFit:'cover',objectPosition:'center top',flex: '1 1 0%', }}
                                                playsInline
                                                loop={true}
                                                muted
                                                controls={false}
                                                alt="All the devices"
                                                src={require('../../src/video/Reel7.mp4')}
                                                ref={videoEl}
                                                autoPlay={true}
                                                />
                                            </Box>
                                        </Box>
                                    </Style.VideoSection>

                                    <Stack direction={{sm:'column', md:'row'}} spacing='24px' width='100%' padding={{lg:'65px', md:'25px', sm:'15px'}} paddingTop={{lg:'20px', md:'20px', sm:'0px'}} alignItems={{sm:'center'}}>
                                        <Box w={{md:'50%', sm:'100%'}} textAlign='center'>
                                            <Box padding='10px'>
                                                <ImagePlace src='/images/calender.svg' ali='logo-icon' />
                                            </Box>
                                            <Link href='/timetable' _hover={{textDecoration:'none'}} marginTop='20px' display='inline-flex'>
                                                <Button title='View Schedule' color='#000' _hover={{color:'#fff'}}/>
                                            </Link>
                                        </Box>
                                        <Box w={{md:'50%', sm:'100%'}}>
                                            <Box textAlign='center'>
                                                <Box padding='5px' width='126px' height='72px' alignSelf='Center' textAlign='center' justifyContent='center' margin='0px auto' marginBottom='15px'>
                                                    <ImagePlace src='/images/logo-icon-small.svg' ali='logo-icon' />
                                                </Box>
                                                <Box position='relative'>
                                                    <Style.h2 variant='small' title='Friday Feb 2nd   |   5:30 pm   |   WML' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='15px' color='#F6536F'/>
                                                </Box >
                                                <Style.p title='Magic Mike Themed Rhythm Ride & Makeup Masterclass With Painted By Ciara' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  />

                                                <Link _hover={{textDecoration:'none'}} onClick={onOpen} marginTop='20px' display='inline-flex' alignSelf='center' justifyContent='center'>
                                                    <Button title='Get Started' color='#000' _hover={{color:'#fff'}}/>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Stack>  
                                </Style.CenterContentWrapper> 
                            </Style.TabContent>
                        </TabPanel>
                        <TabPanel>
                            <Style.TabContent backgroundImage={'url(./images/bg-valantine.webp)'}>
                                <Style.CenterContentWrapper>
                                    <Style.VideoSection padding={{sm:'20px', md:'30px'}} borderBottom='2px dashed rgba(240, 103, 118, 0.53)'>
                                        <Style.VideoBoxContent paddingLeft={{md:'20px', sm:'0px'}}>
                                            <Box textAlign='center'>
                                                <Box position='relative'>
                                                    <Style.h2 variant='extralarge' title='LOVE AT FIRST (RHYTHM) RIDE' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='25px' color='#F6536F'/>
                                                    <Style.highlight backgroundImage={'url(./images/highlight.svg)'}/>
                                                </Box >
                                                <Style.p title='Calling all singles! Dive into the Love at First Ride event where connections spark and hearts flutter. To settle the nerves, we&#039;ll have Perpetua &amp; Prosecco (Zero Available!) post-workout to get your chat on.
        The event will consist of 30 minutes of Rhythm Ride &amp; 30 minutes of Rumble in Lennox Street.' 
                                                fontSize={{lg:'16px', xl:'18px'}} width={{sm:'100%'}}  />
                                            </Box>
                                        </Style.VideoBoxContent>
                                        <Box width={{md:'45%',sm:'100%'}} alignItems={{md:'flex-start', sm:'center'}} justifyContent={{md:'flex-start', sm:'center'}} display='flex' paddingLeft={{md:'80px', sm:'0px'}}>
                                            <Box width='60%' borderRadius='15px' overflow='hidden'>
                                                <video
                                                style={{ maxWidth: "100%",width: "100%", margin: "0 auto", objectFit:'cover',objectPosition:'center top',flex: '1 1 0%', }}
                                                playsInline
                                                loop={true}
                                                muted
                                                controls={false}
                                                alt="All the devices"
                                                src={require('../../src/video/Reel8.mp4')}
                                                ref={videoEl}
                                                autoPlay={true}
                                                />
                                            </Box>
                                        </Box>
                                    </Style.VideoSection>

                                    <Stack direction={{sm:'column', md:'row'}} spacing='24px' width='100%' padding={{lg:'65px', md:'25px', sm:'15px'}} paddingTop={{lg:'20px', md:'20px', sm:'0px'}} alignItems={{sm:'center'}}>
                                        <Box w={{md:'50%', sm:'100%'}}textAlign='center'>
                                            <Box padding='10px'>
                                                <ImagePlace src='/images/calender2.svg' ali='logo-icon' />
                                            </Box>
                                            <Link href='/timetable' _hover={{textDecoration:'none'}} marginTop='20px' display='inline-flex'>
                                                <Button title='View Schedule' color='#000' _hover={{color:'#fff'}}/>
                                            </Link>
                                        </Box>
                                        <Box w={{md:'50%', sm:'100%'}}>
                                            <Box textAlign='center'>
                                                <Box padding='5px' width='126px' height='72px' alignSelf='Center' textAlign='center' justifyContent='center' margin='0px auto' marginBottom='15px'>
                                                    <ImagePlace src='/images/logo-icon-small.svg' ali='logo-icon' />
                                                </Box>
                                                <Box position='relative'>
                                                    <Style.h2 variant='small' title='FRIDAY FEB 9TH | 5.15PM | LNX' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='15px' color='#F6536F'/>
                                                </Box >
                                                <Style.p title='Singles Event | Rhythm X Rumble With Perpetua  Prosecco' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  />

                                                <Link _hover={{textDecoration:'none'}} onClick={onOpen} marginTop='20px' display='inline-flex' alignSelf='center' justifyContent='center'>
                                                    <Button title='Get Started' color='#000' _hover={{color:'#fff'}}/>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Stack>  
                                </Style.CenterContentWrapper> 
                            </Style.TabContent>
                        </TabPanel>
                        <TabPanel>
                        <Style.TabContent backgroundImage={'url(./images/bg-valantine.webp)'}>
                                <Style.CenterContentWrapper>
                                    <Style.VideoSection padding={{sm:'20px', md:'30px'}} borderBottom='2px dashed rgba(240, 103, 118, 0.53)'>
                                        <Style.VideoBoxContent paddingLeft={{md:'20px', sm:'0px'}}>
                                            <Box textAlign='center'>
                                                <Box position='relative'>
                                                    <Style.h2 variant='extralarge' title='THE BREAK UP' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='25px' color='#F6536F'/>
                                                    <Style.highlight backgroundImage={'url(./images/highlight.svg)'}/>
                                                </Box >
                                                <Style.p title='Prepare for a dramatic experience highlighting the ups and downs of break-ups.
Join us for a Rhythm Ride led by the &#039;Side Piece - our guest Instructor, Darryl Walkers.' 
                                                fontSize={{lg:'16px', xl:'18px'}} width={{sm:'100%'}}  />
                                            </Box>
                                        </Style.VideoBoxContent>
                                        <Box width={{md:'45%',sm:'100%'}} alignItems={{md:'flex-start', sm:'center'}} justifyContent={{md:'flex-start', sm:'center'}} display='flex' paddingLeft={{md:'80px', sm:'0px'}}>
                                            <Box width='60%' borderRadius='15px' overflow='hidden'>
                                                <video
                                                style={{ maxWidth: "100%",width: "100%", margin: "0 auto", objectFit:'cover',objectPosition:'center top',flex: '1 1 0%', }}
                                                playsInline
                                                loop={true}
                                                muted
                                                controls={false}
                                                alt="All the devices"
                                                src={require('../../src/video/Reel9.mp4')}
                                                ref={videoEl}
                                                autoPlay={true}
                                                />
                                            </Box>
                                        </Box>
                                    </Style.VideoSection>

                                    <Stack direction={{sm:'column', md:'row'}} spacing='24px' width='100%' padding={{lg:'65px', md:'25px', sm:'15px'}} paddingTop={{lg:'20px', md:'20px', sm:'0px'}} alignItems={{sm:'center'}}>
                                        <Box w={{md:'50%', sm:'100%'}} textAlign='center'>
                                            <Box padding='10px'>
                                                <ImagePlace src='/images/calender3.svg' ali='logo-icon' />
                                            </Box>
                                            <Link href='/timetable' _hover={{textDecoration:'none'}} marginTop='20px' display='inline-flex'>
                                                <Button title='View Schedule' color='#000' _hover={{color:'#fff'}}/>
                                            </Link>
                                        </Box>
                                        <Box w={{md:'50%', sm:'100%'}}>
                                            <Box textAlign='center'>
                                                <Box padding='5px' width='126px' height='72px' alignSelf='Center' textAlign='center' justifyContent='center' margin='0px auto' marginBottom='15px'>
                                                    <ImagePlace src='/images/logo-icon-small.svg' ali='logo-icon' />
                                                </Box>
                                                <Box position='relative'>
                                                    <Style.h2 variant='small' title='FEB 13TH | ALL DAY | WML & LNX (Galentines theme wear pink!)' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='15px' color='#F6536F'/>

                                                    <Style.h2 variant='small' title='FEB 16TH | 5.30PM | WML' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='15px' color='#F6536F'/>
                                                    <Style.h2 variant='small' title='FEB 17TH | 8.30AM &amp; 9.30AM | LNX ' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='15px' color='#F6536F'/>
                                                    <Style.h2 variant='small' title='FEB 18TH | 11AM &amp; 12PM | WML' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='15px' color='#F6536F'/>

                                                </Box >
                                                <Style.p title='"RHYTHM GUEST INSTRUCTOR - DARRYL WALKER AKA &quot;THE SIDE PIECE"' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  />

                                                <Link _hover={{textDecoration:'none'}} onClick={onOpen} marginTop='20px' display='inline-flex' alignSelf='center' justifyContent='center'>
                                                    <Button title='Get Started' color='#000' _hover={{color:'#fff'}}/>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Stack>  
                                </Style.CenterContentWrapper> 
                            </Style.TabContent> 
                        </TabPanel>
                        <TabPanel>
                            <Style.TabContent backgroundImage={'url(./images/bg-valantine.webp)'}>
                                <Style.CenterContentWrapper>
                                    <Style.VideoSection padding={{sm:'20px', md:'30px'}} borderBottom='2px dashed rgba(240, 103, 118, 0.53)'>
                                        <Style.VideoBoxContent paddingLeft={{md:'20px', sm:'0px'}}>
                                            <Box textAlign='center'>
                                                <Box position='relative'>
                                                    <Style.h2 variant='extralarge' title='THE GLOW UP' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='25px' color='#F6536F'/>
                                                    <Style.highlight backgroundImage={'url(./images/highlight.svg)'}/>
                                                </Box >
                                                <Box padding='10px' width='193px' height='95px' margin='0px auto'>
                                                    <ImagePlace src='/images/gloup.jpg' ali='logo-icon' />
                                                </Box>
                                                <Style.p title='Embrace the Glow Up weekend! Immerse yourself in a Rhythm Ride followed by a tranquil sound bath, led by Rachel Lenny, to rejuvenate your mind and body with special goodies from our sponsors!' 
                                                fontSize={{lg:'16px', xl:'18px'}} width={{sm:'100%'}}  />
                                            </Box>
                                        </Style.VideoBoxContent>
                                        <Box width={{md:'45%',sm:'100%'}} alignItems={{md:'flex-start', sm:'center'}} justifyContent={{md:'flex-start', sm:'center'}} display='flex' paddingLeft={{md:'80px', sm:'0px'}}>
                                            <Box width='60%' borderRadius='15px' overflow='hidden'>
                                                <video
                                                style={{ maxWidth: "100%",width: "100%", margin: "0 auto", objectFit:'cover',objectPosition:'center top',flex: '1 1 0%', }}
                                                playsInline
                                                loop={true}
                                                muted
                                                controls={false}
                                                alt="All the devices"
                                                src={require('../../src/video/Valentines-Promo.mp4')}
                                                ref={videoEl}
                                                autoPlay={true}
                                                />
                                            </Box>
                                        </Box>
                                    </Style.VideoSection>
                                    <Stack direction={{sm:'column', md:'row'}} spacing='24px' width='100%' padding={{lg:'65px', md:'25px', sm:'15px'}}  paddingTop='0px' alignItems={{sm:'center'}}>
                                        <Box w={{md:'50%', sm:'100%'}} textAlign='center'>
                                            <Box padding='10px'>
                                                <ImagePlace src='/images/calender4.svg' ali='logo-icon' />
                                            </Box>
                                            <Link href='/timetable' _hover={{textDecoration:'none'}} marginTop='20px' display='inline-flex'>
                                                <Button title='View Schedule' color='#000' _hover={{color:'#fff'}}/>
                                            </Link>
                                        </Box>
                                        <Box w={{md:'50%', sm:'100%'}}>
                                            <Box textAlign='center'>
                                                <Box padding='5px' width='126px' height='72px' alignSelf='Center' textAlign='center' justifyContent='center' margin='0px auto' marginBottom='15px'>
                                                    <ImagePlace src='/images/logo-icon-small.svg' ali='logo-icon' />
                                                </Box>
                                                <Box position='relative'>
                                                    <Style.h2 variant='small' title='FEB 25TH | 12PM - 2PM' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='15px' color='#F6536F'/>
                                                </Box >
                                                <Style.p title='RHYTHM X SOUNDBATH W/ RACHEL LENNY' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  />

                                                <Link _hover={{textDecoration:'none'}} onClick={onOpen} marginTop='20px' display='inline-flex' alignSelf='center' justifyContent='center'>
                                                    <Button title='Get Started' color='#000' _hover={{color:'#fff'}}/>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Stack>  
                                </Style.CenterContentWrapper> 
                            </Style.TabContent>  
                        </TabPanel>
                        <TabPanel>
                        <Style.TabContent backgroundImage={'url(./images/bg-valantine.webp)'}>
                                <Style.CenterContentWrapper>
                                    <Box textAlign='center' padding='40px'>
                                        <Box position='relative'>
                                            <Style.h2 variant='extralarge' title='FEBRUARY SCHEDULE-2024' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='25px' color='#F6536F'/>
                                            <Style.highlight backgroundImage={'url(./images/highlight.svg)'}/>
                                        </Box >
                                    </Box>
                                    <Stack direction={{sm:'column', md:'row'}} spacing='24px' width='100%' padding={{lg:'65px', md:'25px', sm:'15px'}}  paddingTop='0px' alignItems={{sm:'center'}}>
                                        <Box w={{md:'50%', sm:'100%'}} textAlign='center'>
                                            <Box padding='10px'>
                                                <ImagePlace src='/images/calender5.svg' ali='logo-icon' />
                                            </Box>
                                            <Box marginTop='20px' textAlign='left' >
                                                <Style.p title='FEB 2ND | 5.30PM | WML' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  marginBottom='10px' color='#F6536F' />
                                                <Style.p title='FEB 9TH 5.15PM | LNX' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  marginBottom='10px' color='#F6536F' />
                                                <Style.p title='FEB 13TH | ALL DAY | WML & LNX' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  marginBottom='10px' color='#F6536F' />
                                                <Style.p title='FEB 16TH | 5.30PM | WML' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  marginBottom='10px' color='#F6536F' />
                                                <Style.p title='FEB 17TH | 8.30AM &amp; 9.30AM | LNX ' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  marginBottom='10px' color='#F6536F' />
                                                <Style.p title='FEB 18TH | 11AM &amp; 12PM | WML' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  marginBottom='10px' color='#F6536F' />
                                                <Style.p title='FEB 25TH | 12PM - 2PM ' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  marginBottom='10px' color='#F6536F' />
                                            </Box>
                                            <Link href='/timetable' _hover={{textDecoration:'none'}} marginTop='20px' display='inline-flex'>
                                                <Button title='View Schedule' color='#000' _hover={{color:'#fff'}}/>
                                            </Link>
                                        </Box>
                                        <Box w={{md:'50%', sm:'100%'}}>
                                            <Box textAlign='center'>
                                                <Box position='relative'>
                                                    <Style.h2 variant='small' title='THEME' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='15px' color='#000'/>
                                                    <Style.h2 variant='small' title='SINGLE ERA' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='15px' color='#F6536F'/>
                                                    <Style.h2 variant='small' title='LOVE AT FIRST RHYTHM RIDE' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='15px' color='#F6536F'/>
                                                    <Style.h2 variant='small' title='THE BREAK UP' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='15px' color='#F6536F'/>
                                                    <Style.h2 variant='small' title='THE GLOW UP' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='15px' color='#F6536F'/>
                                                </Box >
                                                <Style.p title='Magic Mike Theme &amp; Makeup Masterclass With Painted By Ciara' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  />
                                                <Style.p title='Singles Event | Rhythm X Rumble With Perpetua &amp; Prosecco' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  />
                                                <Style.p title='Galentines Theme - Dress Pink!' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  />
                                                <Style.p title='Rhythm Guest Instructor Darryl Walker Aka "The Side Piece"' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  />
                                                <Style.p title='Rhythm X Soundbath W/ Rachel Lenny' fontSize={{lg:'16px', xl:'18px'}} width={{lg:'100%'}}  />

                                                <Link _hover={{textDecoration:'none'}} onClick={onOpen} marginTop='20px' display='inline-flex' alignSelf='center' justifyContent='center'>
                                                    <Button title='Get Started' color='#000' _hover={{color:'#fff'}}/>
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Stack>  
                                </Style.CenterContentWrapper> 
                            </Style.TabContent>    
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <Box textAlign='center' paddingTop='20px' >
                    <Box position='relative'>
                        <Style.h2 variant='small' title='BOOKING WINDOW OPENS 4 DAYS IN ADVANCE WITH ANY STUDIO CREDIT.' alignSelf='center' justifyContent='center' alignItems='center' marginBottom='15px' color='#F6536F'/>
                    </Box >
                </Box>
            </Container>
        </Style.ContentSection>

    </>
  );
}

export default ValentinesTakeover;