import React from 'react';
import {Box,Image,Modal, ModalOverlay,ModalContent, ModalBody,ModalCloseButton, useDisclosure } from '@chakra-ui/react'
import * as Style from './LocationCard.style';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';
import VideoCard from '../VideoCard/VideoCard';

function LocationCard(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
  return (
        <Style.LocationBox>
            <Heading title={props.title} as='h3' variant='medium' margin='0px 0px 30px' lineHeight='0.7' color={props?.textColor ? props?.textColor : "#fff"} />
            <Style.LocationInfo>
                <Box as='span' width='50px' height='50px' display='flex' alignItems='center' justifyContent='center'>
                    <Image src='../images/map-white.svg' />
                </Box>
                <Text as='span' 
                title={props.location} 
                fontFamily="'BlenderProBold', sans-serif"
                textTransform='inherit'
                fontSize='xs2'
                lineHeight='1.2'
                color={props?.textColor ? props?.textColor : "#fff"}
                />
            </Style.LocationInfo>
            <Style.LocationInfo>
                <Box as='span' width='50px' height='50px' display='flex' alignItems='center' justifyContent='center'>
                    <Image src='../images/phone-white.svg' />
                </Box>
                <Text as='span' 
                title={props.phone} 
                fontFamily="'BlenderProBold', sans-serif"
                textTransform='inherit'
                fontSize='xs2'
                lineHeight='1.2'
                color={props?.textColor ? props?.textColor : "#fff"}
                />
            </Style.LocationInfo>
            <Style.ButtonSection>
                <Style.LinkButton href='/contact-us'>Contact</Style.LinkButton>
                <Style.ButtonModal onClick={onOpen} color='white'>View Gym</Style.ButtonModal>
            </Style.ButtonSection>
            <Box marginLeft={{md:'10px'}} marginTop='30px'>
                <Modal isOpen={isOpen} onClose={onClose} size={{sm:'sm', md:'3xl'}} >
                <ModalOverlay />
                <ModalContent marginTop='100px'>
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody >
                    <VideoCard src={props.videolink} width={{sm:'100%', md:'700px'}}   margin={{sm:'30px 3px', md:'30px 9px'}} maxHeight='540px' />
                    </ModalBody>
                </ModalContent>
                </Modal>
            </Box>
        </Style.LocationBox>
 
    );
}

export default LocationCard;