import { Box, Flex, UnorderedList, ListItem, Image, Link } from '@chakra-ui/react'
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";
import { SearchIcon, ChevronRightIcon } from "@chakra-ui/icons";

export const BlogHeading = styled(Box)(props => ({
    textAlign: 'center',
    padding: '50px 0px',
    background: '#EEEEF1',

    [`@media ${device.tabletM}`]: {
        padding: '70px 0px',
    },

}))
export const BlogInfo = styled(Box)(props => ({
    paddingTop: '20px',
}))

export const BlogAppInfo = styled(Box)(props => ({
    paddingTop: "20px",
    paddingBottom: '0px',

    [`@media ${device.tabletM}`]: {
        paddingLeft: '60px',
    }

}))
export const BlogAppImage = styled(Image)(props => ({
    width: '100%',

    [`@media ${device.tabletM}`]: {
        width: '262px',

    }

}))


export const BlogUnorderedList = styled(UnorderedList)(props => ({
    padding: '0',
    margin: '10px 0px 0px',
    listStyleType: 'none',
    color: 'black',
    fontFamily: 'Poppins',
    fontWeight: '300'
}))
export const BlogListItems = styled(ListItem)(props => ({
    borderTop: '1px solid LightGrey',
    padding: '10px 0px',
    position: "relative",
    fontSize: "16px",
    paddingLeft: "30px",
    ":after": {
        width: '9px',
        height: '9px',
        position: 'absolute',
        content: '""',
        top: '15px',
        left: '0',
        background: '#D12C41',
        borderRadius: '50px'
    }

}))

export const BlogListEpisode = styled(Box)(props => ({
    textAlign: 'Left',
    width: '100%',
    margin: "0px auto",
    paddingBottom: "50px",

    [`@media ${device.tabletM}`]: {
        width: '90%',
        paddingBottom: "60px",
    },
}))

// Blog page //



export const BlogSearchInfo = styled(Flex)(props => ({
    justifyContent: 'space-between'
}))

export const PerpetuaBlogContent = styled(Box)(props => ({
    maxW: '300px',
    textAlign: 'left'
}))
export const BlogContentSearch = styled(Box)(props => ({
    maxW: '300px',
    textAlign: 'Right'
}))

export const BlogCategoryListingcontent = styled(Flex)(props => ({
    justifyContent: 'space-between',
    flexDirection: 'column',

    [`@media ${device.tabletM}`]: {
        flexDirection: 'row'
    },

    [`@media ${device.tabletL}`]: {
        flexDirection: 'row'
    },
}))

export const BlogCategorySearchInfo = styled(Box)(props => ({
    textAlign: 'left',
    width: '100%',
    height: '100%',
    padding: '10px',
    paddingRight: '10px',
    position: 'relative',
    top: '30px',
    background: 'white',
    marginBottom: '50px',
    zIndex: '11',

    [`@media ${device.tabletM}`]: {
        padding: '0px',
        top: '110px',
        marginBottom: '0px',
        paddingRight: '35px',
        width: '25%',
    },
    [`@media ${device.tabletL}`]: {
        position: 'sticky',
        width: '25%',


    },
}))

export const BlogCategorySearchBy = styled(Box)(props => ({
    borderBottom: '2px solid',
    borderBottomColor: 'LightGrey',
    paddingBottom: '15px',
    position: 'relative',
    ":after": {
        position: 'absolute',
        content: '""',
        width: '195px',
        height: '2px',
        top: 'auto',
        left: '0',
        background: '#D12C41',
        zIndex: '1',
        bottom: '-1.5px'
    }

}))


export const BlogCategoryUnorderedList = styled(UnorderedList)(props => ({
    display: 'flex',
    gridGap: '12px',
    //flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '18px 0px',
    margin: '0',

    [`@media ${device.tabletM}`]: {
        flexDirection: 'column',
        flexWrap: 'rap'
    },
    [`@media ${device.tabletL}`]: {
        flexDirection: 'column',
        flexWrap: 'nowrap'
    },
}))
export const BlogCategoryListItems = styled(ListItem)(props => ({
    paddingLeft: '18px',
    position: 'relative',
    listStyleType: 'none',
    borderBottom: '1px solid',
    borderBottomColor: 'LightGrey',
    paddingBottom: '12px'

}))

export const BlogCategoryIcon = styled(ChevronRightIcon)(props => ({
    position: 'absolute',
    left: '-5px',
    top: '-10px',
    bottom: '0',
    margin: 'auto',
    fontSize: '20px'
}))

export const BlogCategoryLink = styled(Link)(props => ({
    /* ':hover': {
        textDecoration:'none'
    } */
}))

export const ViewRelatedPage = styled(Link)(props => ({
    padding: '8px 20px',
    borderRadius: '30px',
    background: '#D12C41',
    color: '#fff!important',
    fontSize: '14px',
    fontFamily: ' Poppins',
    fontWeight: 500,
    height: 45,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',

}))
