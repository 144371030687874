import React from 'react';
import { Box, Container, SimpleGrid, ListItem, UnorderedList, Image, GridItem, Link, Center } from '@chakra-ui/react';
import InnerBanner from '../component/InnerBanner/InnerBanner';
import Text from '../component/SubHeading/SubHeading';
import Heading from '../component/Heading/Heading';
import MetaTitle from '../pages/MetaTitle';
import Button from '../component/Button/CustomeButton';

const RhythmRideLiquidDeath = () => {
    return (
        <>
            <MetaTitle title='Halloween Spin Class | Liquid Death & Perpetua Fitness' />
            <Box paddingTop='80px' />
            <InnerBanner
                backgroundColor='black'
                class='summer-closing-party-banner'
                backgroundImage={{ sm: 'url(./images/RhythmRideLiquidDeath_img_mb1.webp)', md: 'url(./images/RhythmRideLiquidDeath_img.webp)' }}
                isHeading={false}
                issubtextpara={false}
                isButton={false}
                isButton2={false}
                issubtitleRight={false}
                isSubtext={false}
            />

            <Box padding={{ sm: "50px 5px", md: "80px 0" }} backgroundColor='#f7f7f7'>
                <Container maxW='container.xl'>
                    <Heading title='Halloween Spin Class Murder On The Dance Floor with Liquid Death' as='h1' textAlign='center' variant='largheading' color='#000' lineHeight='0.9' />
                    <Text title='This Halloween, brace yourself for the deadliest Rhythm Ride you’ve ever experienced with our Metal themed Spin Class' textAlign='center' width={{ md: "70%" }} margin='0 auto' fontSize={{ sm: "20px", md: "22px" }} color='#000' padding='20px 0 0' />
                </Container>
            </Box>
            <Box padding={{ sm: "25px 5px 50px", md: "50px 0" }}>
                <Container maxW='container.xl'>
                    <SimpleGrid
                        columns={{ sm: "1", md: "2" }}
                        columnGap={10}
                        rowGap={2}
                        alignItems='center'
                        justifyContent='center'
                    >
                        <GridItem>
                            <Image src='./images/liquid-death2.webp' margin="0 auto" />
                        </GridItem>
                        <GridItem>
                            <Text title='We’re teaming up with Liquid Death to murder your thirst and unleash your inner beast with two deadly Rhythm Ride classes on Tues, Oct 29th. ' />
                            <Text title='These high-octane, 45-minute classes are not for the faint of heart. Expect a hardcore, head-banging playlist to fuel your power and push your limits. ' padding='15px 0' />
                            <Text title='Liquid Death is on a mission to bring Death to Plastic and Murder Your Thirst—and this ride is no exception. Hydrate with the world’s healthiest beverage in infinitely recyclable cans, and join the movement to kill boring workouts.  ' />

                        </GridItem>
                    </SimpleGrid>
                </Container>
            </Box>
            <Box paddingBottom='50px'>
                <Container maxW='container.xl'>
                    <Heading title='Enjoy complimentary Liquid Death water in our Windmill Lane studio Tues 29th - Thurs 31st.' textAlign='center' variant='medium' lineHeight='0.9' />
                    <UnorderedList listStyleType='none' display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap="5px" padding='20px 0 0'>
                        <ListItem display="flex" alignItems="center" gap="15px" fontSize={{ sm: "15px", md: "22px" }} >
                            <span style={{fontFamily: 'Poppins', fontWeight:"700"}}>Date:</span>
                            <Text title='October 29th' />
                        </ListItem>
                        <ListItem display="flex" alignItems="center" gap="15px" fontSize={{ sm: "15px", md: "22px" }}>
                            <span style={{fontFamily: 'Poppins', fontWeight:"700"}}>Class Times:</span>
                            <Text title='5.45 pm & 6.45 pm' />
                        </ListItem>
                        <ListItem display="flex" alignItems="center" gap="15px" fontSize={{ sm: "15px", md: "22px" }}>
                            <span style={{fontFamily: 'Poppins', fontWeight:"700"}}>Location: </span>
                            <Text title='Windmill Lane, Dublin 2' />
                        </ListItem>
                    </UnorderedList>
                    <Center paddingTop='30px'>
                        <Link href='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=-106&sTG=100&sView=day&sLoc=1&date=10/29/24' isExternal={true}>
                            <Button title='Book now' color='#000' />
                        </Link>
                    </Center>
                </Container>
            </Box>

        </>
    )
}

export default RhythmRideLiquidDeath;