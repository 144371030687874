import { Box, Flex} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";
import { Link } from "react-router-dom";

export const CrossfitInfo = styled(Flex)(props => ({
    flexDirection: 'column-reverse',

    [`@media ${device.tablet}`]: {
        flexDirection: 'row',
    },

}))
export const CrossfitContent = styled(Box)(props => ({
    width:'100%',
    padding:'0px 0px 20px',
    
    [`@media ${device.tablet}`]: {
        width:'50%',
    },
    [`@media ${device.tabletM}`]: {
        padding:'40px 0px',
        width:'50%',
    },
}))
export const CrossfitImage = styled(Flex)(props => ({
    backgroundRepeat: 'no-repeat', 
    backgroundPosition: 'center', 
    backgroundSize: 'cover',
    height: '300px',
    width: '100%',
   
    [`@media ${device.tablet}`]: {
        height: '558px',
        width: '50%',
    },
}))

// justGym content, image
export const JustGymInfo = styled(Flex)(props => ({
    flexDirection: 'column',


    [`@media ${device.tablet}`]: {
        flexDirection: 'row',
    },

}))
export const JustGymContent = styled(Box)(props => ({
    width:'100%',
    padding:'0px 0px 20px',
    
    [`@media ${device.tablet}`]: {
        padding:'0px',
        width:'50%',
    },
    [`@media ${device.tabletL}`]: {
        padding:'40px 0px',
        width:'50%',
    },
   
}))
export const JustGymImage = styled(Flex)(props => ({
    backgroundRepeat: 'no-repeat', 
    backgroundPosition: 'center', 
    backgroundSize: 'cover',
    height: '300px',
    width: '100%',
   
    [`@media ${device.tablet}`]: {
        height: '558px',
        width: '50%',
    },
}))

// pricing 

export const PriceInfo = styled(Box)(props => ({
   
    padding: '10px 0px 30px',

    [`@media ${device.tablet}`]: {
        padding: '50px 0px'
    },
}))

// button
export const ButtonContent = styled(Box)(props => ({
    padding:'40px 30px',
    background:'#f7f7f7',
    
}))

export const ButtonLink = styled(Box)(props => ({
    width:'100%',
    height:'50px',
    border:'2px solid grey',
    color:'#000',
    display:'flex',
    justifyContent:'center',
    alignItems:'center', 
    borderRadius:'5px',
    ":hover": {
        color: 'white',
        backgroundColor: 'grey'
    },
   
}))

//Link 
export const ButtonLinkUrl = styled(Link)(props => ({
    ":hover": {
        textDecoration: 'none'
    }
   
}))

