import { Flex,Box } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";


export const IconicWraper = styled(Box)(props => ({
    padding:'0',
    margin:'0',
        
    }))

export const InnovationPassionWrapper = styled(Flex)(props => ({
    flexDirection:'column',
    background:'#f7f7f7'
        
    }))

export const InnovationPassion = styled(Flex)(props => ({
    flexDirection:'column',
    gap:'15px',
    alignItems:'center',
    justifyContent:'center',
    padding:'50px 0px',
    textAlign:'center'
        
    }))

export const IconicOfferWraper = styled(Box)(props => ({
    padding:'50px 0px',
    margin:'0',

    "@media screen and (max-width:480px)": {
        padding:'50px 20px',
    },  
        
    }))

export const StudioClassesWraper = styled(Flex)(props => ({
    padding:'50px 0px',
    margin:'0',    
    background:'#f7f7f7'
}))

export const StudioClasses= styled(Flex)(props => ({
    columns:'2', 
    alignItems:'center',

    "@media screen and (max-width:767px)": {
        flexDirection:'column'
    },

}))

export const StudioClassesBox= styled(Box)(props => ({
    padding:'0',
    margin:'0', 
    width:'50%' ,
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    height:'350px',
    padding:'60px',

    "@media screen and (max-width:991px)": {
        height:'265px',
        padding:'25px',
        },

    "@media screen and (max-width:767px)": {
        width:'100%',
        height:'auto',
        ":first-child":{
            height:'265px',
        }
    },

}))

export const buttonGroup= styled(Flex)(props => ({
    padding:'0px',
    margin:'0',
    justifyContent:'center',
    alignItems:'center',
    "button":{
        "@media screen and (max-width:991px)": {
            marginTop:'10px',
            },
    },
    "@media screen and (max-width:991px)": {
        flexDirection:'column',
        paddingTop:'40px'
        },
}))

export const BookInWrapper= styled(Flex)(props => ({
    flexDirection:'column',
    padding:'50px 0px',
    margin:'0',

    "@media screen and (max-width:991px)": {
        paddingBottom:'0px'
        },
}))

export const BookIn= styled(Flex)(props => ({
    flexDirection:'column',
    padding:'0px',
    margin:'0',
    alignItems:'center',
    justifyContent:'center'
}))

export const AllClassesWrapper= styled(Flex)(props => ({
    flexDirection:'column',
    padding:'50px 0px 40px',
    margin:'0',
    alignItems:'center',
    justifyContent:'center'
}))

export const AllClassesBody= styled(Flex)(props => ({
    flexDirection:'row',
    padding:'0px',
    margin:'0',
    alignItems:'center',
    justifyContent:'center',

    "@media screen and (max-width:991px)": {
        flexDirection:'column'
        },
}))

export const AllClassesBox= styled(Flex)(props => ({
    padding:'0',
    margin:'0', 
    width:'50%' ,
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    minHeight:'500px',
    height:`(${props.height})`,
    padding:'60px',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    textAlign:'left',
    backgroundColor:`(${props.backgroundColor})`,
    order:`(${props.order})`,

    "@media screen and (max-width:1680px)": {
        minHeight:'580px',
        },
    "@media screen and (max-width:1366px)": {
        minHeight:'620px',
        },
    "@media screen and (max-width:991px)": {
        width:'100%',
        minHeight:'auto',
        },  
    "@media screen and (max-width:480px)": {
        padding:'40px',
        },  
}))



export const AnyWhereWrapper= styled(Flex)(props => ({
    padding:'50px 0px 70px',
    margin:'0',
    background:'#f7f7f7',
    alignItems:'center',
    justifyContent:'center',
    justifyItems:'center', 
    textAlign:'center',
    flexDirection:'column',
}))
export const AnyWherebody= styled(Flex)(props => ({
    flexDirection:'column',
    padding:'60px',
    margin:'0',
    alignItems:'center',
    justifyContent:'center',
    justifyItems:'center', 
    textAlign:'center', 
    boxShadow:'0px 0px 100px rgba(0, 0, 0, 0.1)', 
    background:'white',
    "@media screen and (max-width:480px)": {
        padding:'20px',
        },  
}))



    

