import React from 'react';
import { Container, Box, SimpleGrid, GridItem } from '@chakra-ui/react';
import ClassCard from '../component/Classcard/ClassCard';
import MetaTitle from './MetaTitle';
import Heading from '../component/Heading/Heading';

const OurStudios = () => {

    const metaTitle = "Our Studios | Perpetua Fitness";

    return (
        <>
            <MetaTitle title={metaTitle} />

            <Container maxW='container.xl' padding={{ sm: '140px 20px 30px', md: "140px 20px 50px", xl:"130px 0 50px" }}>
                <Box>
                    <Heading title='Windmill Lane' variant='large' padding='0 0 20px' />
                    <SimpleGrid
                        columns={{ sm: '1', md: '2', xl: '3' }}
                        columnGap={4}
                        rowGap={2}
                        width="full"
                    >
                        <GridItem className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
                            <ClassCard
                                title='Sweat Studio'
                                subtitle=''
                                bg="url('/images/TreadAndShread/WarmUp.jpg')"
                                isButton={false}
                                marginBottom='30px'
                                transform="uppercase"
                                minHeight={{ sm: '300px', md: '450px', lg: '500px' }}
                            // linkBg='/treadandshred-first-timers'
                            />
                        </GridItem>
                        <GridItem className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
                            <ClassCard
                                title='Ride Studio'
                                subtitle=''
                                bg="url('/images/RhythmRide/Playlist.jpg')"
                                isButton={false}
                                marginBottom='30px'
                                transform="uppercase"
                                minHeight={{ sm: '300px', md: '450px', lg: '500px' }}
                            //linkBg='/rumble-first-timers'
                            />
                        </GridItem>
                    </SimpleGrid>
                </Box>
                <Box padding={{sm:"30px 0 0",  md: "50px 0 0" }}>
                    <Heading title='Lennox Street' variant='large' padding='0 0 20px' />
                    <SimpleGrid
                        columns={{ sm: '1', md: '2', xl: '3' }}
                        columnGap={4}
                        rowGap={2}
                        width="full"
                    >
                        <GridItem className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
                            <ClassCard
                                title='Sweat Studio'
                                subtitle=''
                                bg="url('/images/TreadAndShread/WarmUp.jpg')"
                                isButton={false}
                                marginBottom='30px'
                                transform="uppercase"
                                minHeight={{ sm: '300px', md: '450px', lg: '500px' }}
                            //linkBg='/treadandshred-first-timers'
                            />
                        </GridItem>
                        <GridItem className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
                            <ClassCard
                                title='Rumble Studio'
                                subtitle=''
                                bg="url('/images/Rumble/Cooldown.jpg')"
                                isButton={false}
                                marginBottom='30px'
                                transform="uppercase"
                                minHeight={{ sm: '300px', md: '450px', lg: '500px' }}
                            //linkBg='/rumble-first-timers'
                            />
                        </GridItem>
                        <GridItem className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
                            <ClassCard
                                title='Ride Studio'
                                subtitle=''
                                bg="url('/images/RhythmRide/Playlist.jpg')"
                                isButton={false}
                                marginBottom='30px'
                                transform="uppercase"
                                minHeight={{ sm: '300px', md: '450px', lg: '500px', }}
                            //linkBg='/ride-first-timers'
                            />
                        </GridItem>
                    </SimpleGrid>
                </Box>
            </Container>
        </>
    )
}

export default OurStudios;