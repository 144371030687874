import React, {useEffect} from "react";
import { Box, Container, GridItem, SimpleGrid, Image, VStack, UnorderedList, ListItem, Link,  } from '@chakra-ui/react';
import InnerBanner from "../component/InnerBanner/InnerBanner";
import Heading from "../component/Heading/Heading";
import Text from "../component/SubHeading/SubHeading";
import PrivateHireAndCorporateForm from "../component/Form/PrivateHireAndCorporateForm";
import LinkButton from "../component/Button/LinkButton";
import MetaTitle from "./MetaTitle";
import WOW from 'wowjs';

function PrivateHire(){

    useEffect(() => {
        new WOW.WOW({
          live: true,
          offset: 0,
          mobile: true,
        }).init();
    }, [])

    return(
        <>
            <MetaTitle title='Private Gym Hire Dublin | Perpetua Fitness'/>
            <InnerBanner 
            backgroundColor='black' 
            backgroundImage={{sm:'url(./images/private_hire_m.jpg)', md:'url(./images/PrivateHire.jpg)'}} 
            subtitle='PERPETUA FITNESS'
            title='PRIVATE GYM HIRE'
            subtextpara="WELLNESS PROGRAMME WITH IRELAND'S #1 HEALTH & FITNESS BRAND"
            issubtextpara={true}
            isButton={false} 
            issubtitleRight={false} 
            width={{sm:"100%" }}
            height={{ sm:'100vh', md:'500px', lg:'550px', xl:'767px'}}
            />

            <Container maxW={{md:'container.lg',xl:'container.xl'}}>
                <SimpleGrid
                columns={{sm:'1',md:'2'}} 
                columnGap={10} 
                rowGap={2} 
                width="full"
                padding='50px 0'
                >
                
                    <GridItem>
                        <Image margin={{sm:'0px auto',md:'0'}} src='./images/PrivateStudioHire.png'
                         className='wow animate fadeInUp' alt="private gym hire dublin" />
                    </GridItem>
                    <GridItem marginTop={{lg:'20px', xl:'70px'}} > 
                        <Box padding={{sm:'30px 0 0', md:'0'}} >
                            <Heading title='PRIVATE STUDIO HIRE DUBLIN' variant='large' />
                            <Heading title='Fancy a unique personalised experience for your employees?' color='Red' variant='extrasmall' />
                            <Text title='Host your own group or corporate class in our ride or sweat studios. You can hire our studios 
                                to schedule a weekly or monthly private class for your employees. The Perpetua team can assist with any 
                                special requirements and will provide any of our in-house instructors upon request.' 
                                padding='10px 0px'
                             />
                            <Text title='Exclusive add ons include shakes and water are available to enhance your event. As Well as special in house lighting, visuals and music playlists are available upon request.'/>

                        </Box>
                    </GridItem>
                    
                </SimpleGrid>
            </Container>

            <Box backgroundColor='#f7f7f7' padding={{ sm:'50px 10px 0' ,md:'80px 10px 30px' }}>
                <Container maxW={{md:'container.lg',xl:'container.xl'}}>
                    <Box textAlign='center'>
                        <Heading title='WHO ARE WE?' color='#000' padding={{sm:'0 0 10px', md:'0'}} />
                        <Text title="Perpetua is Dublin's first premium fitness brand, offering a comprehensive wellness experience in our two physical locations and through our Perpetua Training mobile app. We offer fitness and wellness solutions for the hybrid world and are thrilled to create bespoke corporate wellness packages for companies of all sizes."
                            color='#000' width={{md:'85%'}} margin='0 auto'/>
                    </Box>
                    <SimpleGrid 
                        columns={{sm:'1',md:'3'}} 
                        columnGap={10} 
                        rowGap={2} 
                        width="full"
                        padding='50px 10px'>
                        <GridItem padding='5px 0'>
                            <Heading title='PASSION' color='#D12C41' variant='small' />
                            <Text title='Our passion is to create a safe and effective training environment with a supportive community of like-minded individuals. Our coaches are full time professionals who have dedicated their lives to helping others through health and fitness.' 
                            color='#000' />
                        </GridItem>
                        <GridItem padding='5px 0'>
                            <Heading title='EXPERIENCE' color='#D12C41' variant='small' />
                            <Text title='We’ve built an environment where doing the right thing on a daily basis is rewarded. A place where we help each other as a group become better, stronger, more educated and more tolerant people by training with professional systems and support.'
                            color="#000" />
                        </GridItem>
                        <GridItem padding='5px 0'>
                            <Heading title='COMMUNITY' color='#D12C41' variant='small' />
                            <Text title='We pride ourselves on the community we have built across the last decade. You are not just joining a gym. You become part of a community here at Perpetua. It is a place to 
                            connect and make new friends. You will find yourself with a new social and support network that will help you on your continued path to greater health and happiness.'
                            color="#000" />
                        </GridItem>
                    </SimpleGrid>
                </Container>
            </Box>
            <Box   backgroundImage='url(./images/Privatehire1.jpg)' backgroundPosition='center' backgroundRepeat='no-repeat'backgroundSize='cover' >
                <Container maxW={{md:'container.lg',xl:'container.xl'}}>
                    <SimpleGrid
                      columns={{sm:'1',md:'2'}} 
                      spacing={{sm:'20px', md:'50px'}}                      
                      width="full"  
                      padding={{sm:'50px 10px', md:'80px 0 125px'}}              
                    >   
                        <GridItem>
                            <Heading title='WHAT WE OFFER' color='white' />
                            <Heading title='STUDIOS' color='#D12C41' variant='medium' padding='10px 0' />
                            <Heading title='ON-SITE' color='white'  variant='small' />
                            <Text title="Offering Dublin's first and best boutique fitness experience." color='white' padding='10px 10px 10px 0'/>
                            <Text title='Get your team into our RIDE and Tread & Shred classes with corporate class packs or private company classes.'color='white' padding='10px 0' width='93%' />
                        </GridItem>
                        <GridItem marginTop={{md:'40px',lg:'50px', xl:'78px'}}>
                            <Heading title='Perpetua Training' color='#D12C41' variant='medium' padding='10px 0' />
                            <Heading title='Off-SITE' color='white' variant='small' />
                            <Text title="Built for our hybrid world, Perpetua Training is an app available on all devices, encompassing every companies health, fitness and wellness needs from training at home or in the gym to customising your own nutrition." color='white' padding='10px 10px 10px 0' />
                            <Text title='We cater for everyone from beginner to an advanced level of fitness.' color='white' padding='10px 0' width='93%' />
                        </GridItem>
                    </SimpleGrid> 
                   
                </Container>
            </Box>

            <Box padding={{sm:'50px 0' ,md:'80px 0'}}>
                <Container maxW={{md:'container.lg',xl:'container.xl'}}>
                    <SimpleGrid
                    columns={{sm:'1',md:'2'}} 
                    spacing='50px'                      
                    width="full"  
                    >
                        <GridItem>
                            <Heading title='BESPOKE WELLNESS PACKAGES' variant='large' lineHeight='0.8' padding='0 0 20px' />
                            <Heading title="LET PERPETUA FITNESS TAKE CARE OF YOUR EMPLOYEE'S MENTAL AND PHYSICAL WELLBEING" color='#D12C41' variant='extrasmall' padding='10px 0' width='90%' />
                            <Text title="We are happy to create bespoke corporate programs for your employees - the possibilities are endless, so let's chat!" padding=' 0 0 10px ' />
                            <Heading title='OUR MOST POPULAR CORPORATE WELLNESS PACKAGE INCLUDES:' color='#D12C41' variant='extrasmall' />
                           
                           <UnorderedList listStyleType='none' fontFamily='Poppins' marginInlineStart='0px' marginLeft='0' marginRight='0'>                    
                                <ListItem padding='5px 0' display='flex'>
                                  <Image src="./images/Vector.png" width='13px' height='13px' marginTop='5px' marginLeft='0' marginRight='0'/>  
                                  <Text as='span' padding='0px 5px'title='Shared Credit Pool based on number of employees'></Text>
                                </ListItem>
                                <ListItem padding='5px 0' display='flex'>
                                    <Image src="./images/Vector.png"  width='13px' height='13px' marginTop='5px' marginLeft='0' marginRight='0'/>
                                    <Text as='span' padding='0px 5px' title='1 Private Class per month or quarterly'></Text>
                                </ListItem>
                                <ListItem padding='5px 0' display='flex'>
                                    <Image src="./images/Vector.png" width='13px' height='13px' marginTop='5px' marginLeft='0' marginRight='0' />
                                    <Text as='span' padding='0px 5px' title='Perpetua Training membership for each employee'></Text>
                                </ListItem>
                            </UnorderedList>
                            <Heading title='Perpetua Training membership for each employee' variant='extrasmall' color='#D12C41' padding='10px 0' />
                            
                            <UnorderedList listStyleType='none' fontFamily='Poppins' marginInlineStart='0px' marginLeft='0' marginRight='0'> 
                                <ListItem padding='5px 0' display='flex'>
                                <Image src="./images/Vector.png" width='13px' height='13px' marginTop='5px' marginLeft='0' marginRight='0' /> 
                                <Text as='span'padding='0px 5px' title='Wellness talk or seminar'></Text>
                                </ListItem>
                            </UnorderedList>

                        </GridItem>
                        <GridItem>
                            <Image margin={{sm:'0px auto',md:'0'}} src='./images/Besopke_ wellness.png' 
                            className='wow animate fadeInUp' alt="private gym hire dublin"/>
                        </GridItem>
                    </SimpleGrid>
                </Container>
            </Box>

            <VStack backgroundColor='#f7f7f7' padding={{ sm:'30px 0px',md:'96px 0px'}} borderBottom='1px solid #232323'>
                <Container 
                display='flex'
                maxW={{md:'container.lg',xl:'container.xl'}}
                flexDirection={{sm:'column',md:'row' }}
                alignItems='top'
                alignContent='center'
                justifyContent='center'
                justifyItems='center'
                >
                    <Box w={{sm:'100%', md:'50%',lg:'40%'}} textAlign={{base:'center', lg:'left'}} marginBottom={{base:'50px', lg:'0'}}>
                        <Heading title='LOOKING FOR MORE INFORMATION' variant='extralarge' lineHeight='1' margin='0px 0px 40px' color='#000' />
                        <Heading title='Chat with a member of the team' variant='smallmid' lineHeight='1' margin='0px 0px 30px' color='#000' />
                        <Link href='/contact-us' textDecoration='none' display='inline-block' _hover={{textDecoration:'none' }}>
                            <LinkButton 
                                title='BOOK A CALL' 
                                Imguri='/images/red_arrow.svg' 
                                border='2px solid' borderColor='Red' 
                                padding='20px 25px'
                                color='#000'
                            />
                        </Link>
                    </Box>
                    <Box w={{sm:'100%', md:'50%',lg:'60%'}}>
                        <PrivateHireAndCorporateForm  textColor='#000'/>
                    </Box>
                </Container>
            </VStack>
        </>
    )
}

export default PrivateHire;