import React from 'react'
import { Box, Container,Flex,SimpleGrid,UnorderedList,    ListItem,  } from '@chakra-ui/react';
import * as Style from './LandingPage4.style';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';
import ClasscardV2 from '../Classcard/ClasscardV2';
import JointodayForm from '../Form/JoinToday';

const LandingPage4 = (props) => {
  return (
    <Style.PageWrapper>
        <Style.ContentCard1 className='wow animate fadeIn' backgroundColor='#fff' >
            <Container maxW="container.xl">
                <SimpleGrid columns={{md:'2', sm:'1'}} spacing={{md:'10',sm:'4'}} alignItems='center'>
                    <Box textAlign='left'>
                        <Heading title="Book a" variant="extralarge" color='#000' padding='0' lineHeight='1'/>
                        <Heading title="consultation " variant="extralarge" color='#000' padding='0' lineHeight='1' />
                    </Box>
                    <Box textAlign='left'>
                        <UnorderedList padding='0px' margin='0px'>
                            <ListItem display='flex' alignItems='center' gap='4px' marginBottom='10px'>
                                <Box color='#D12C41' marginRight='5px' display='inline-flex'  className='_icon' fontSize={{sm:"16px",md:"20px"}}><i className="bi bi-check2-circle"></i></Box>  
                                <Text title="Pick a service" as='span' fontSize={{md:"18px",sm:'14px'}} fontWeight={{md:'bold',sm:'500'}} color='#000'/>
                            </ListItem>
                            <ListItem display='flex' alignItems='center' gap='4px' marginBottom='10px'>
                                <Box color='#D12C41' marginRight='5px' display='inline-flex'  className='_icon' fontSize={{sm:"16px",md:"20px"}}><i className="bi bi-check2-circle"></i></Box>  
                                <Text title="Fill out the contact form" as='span' fontSize={{md:"18px",sm:'14px'}} fontWeight={{md:'bold',sm:'500'}} color='#000'/>
                            </ListItem>
                            <ListItem display='flex' alignItems='center' gap='4px' marginBottom='10px'>
                                <Box color='#D12C41' marginRight='5px' display='inline-flex'  className='_icon' fontSize={{sm:"16px",md:"20px"}}><i className="bi bi-check2-circle"></i></Box>  
                                <Text title="Schedule your free consult call or meeting" as='span' fontSize={{md:"18px",sm:'14px'}} fontWeight={{md:'bold',sm:'500'}} color='#000'/>
                            </ListItem>
                        </UnorderedList>
                    </Box>
                </SimpleGrid>
            </Container>
        </Style.ContentCard1>
        <Style.FromSection>
            <Container maxW="container.xl">
                <Flex gap='50px' alignItems='start' flexDirection={{md:'row',sm:'column'}}>
                    <Box textAlign='left' width={{md:'40%',sm:'100%'}}>
                        <Heading title='Perpetua Fitness' variant='large' margin='0px 0px 15px' lineHeight='0.8'color='#000' />
                        <Heading title='Dublin, Ireland' variant='smallmid' margin='0px 0px 15px' lineHeight='0.8'color='#000' />
                        <Style.ImageWrapper>
                            <Style.FormImage src='/images/wramup.jpg'></Style.FormImage>
                        </Style.ImageWrapper>
                    </Box>
                    <Box textAlign='left' width={{md:'60%',sm:'100%'}}>
                        <JointodayForm textColor='#000'/>
                    </Box>
                </Flex>
                
            </Container>
        </Style.FromSection>
        <Flex 
        p={{sm:'30px 0px', md:'50px 0px'}}
        backgroundColor='#fff'
        borderBottom='1px solid #232323'
        >
            <Container maxW={"container.xl"}>
                <Box textAlign='left'>
                    <Heading 
                        marginBottom='50px' 
                        variant="medium"
                        title="Make Your Move"
                        className='wow animate fadeInUp' 
                        color='#000'
                    />
                </Box>
                <Style.ClasslistingBody>
                    <ClasscardV2
                        width={{xl:'23%', lg:'22%',md:'46%',sm:'100%'}}
                        title='CROSSFIT'
                        subtitle='COACHING. COMMUNITY. PASSION'
                        imageUri='/images/crossfit_new.webp'
                        link="/crossfit-dublin"
                        margin="0"
                        transform="uppercase"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                        textColor='#000'
                    />
                    <ClasscardV2
                        width={{xl:'23%', lg:'22%',md:'46%',sm:'100%'}}
                        title='Personal Training '
                        subtitle='WE KNOW EXACTLY WHAT IT TAKES TO GET RESULTS'
                        imageUri='/images/personal_training.webp'
                        link="/personal-training-dublin"
                        margin={{lg:'60px 0px 0px', md:'60px 0px 0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                        textColor='#000'
                    />
                    <ClasscardV2
                        width={{xl:'23%', lg:'22%',md:'46%',sm:'100%'}}
                        title='JUSTGYM'
                        subtitle='FULLY EQUIPPED GYM'
                        imageUri='/images/just_gym_new.webp'
                        link="/luxury-gym-dublin-city-centre"
                        margin={{lg:'120px 0px 0px', md:'0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                        textColor='#000'
                    />
                    <ClasscardV2
                        width={{xl:'23%', lg:'22%',md:'46%',sm:'100%'}}
                        title='HYROX'
                        subtitle='HYROX is a global fitness race for Every Body.'
                        imageUri='/images/cooldown.jpg'
                        link="/hyrox-dublin"
                        margin={{lg:'200px 0px 0px', md:'80px 0px 0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                        textColor='#000'
                    />
                </Style.ClasslistingBody>
            </Container>
        </Flex>
    </Style.PageWrapper>
  )
}

export default LandingPage4