import React from 'react';
import * as Style from './videosection.style';
import Heading from '../Heading/Heading';
import { Box, Container} from '@chakra-ui/react';
import LazyLoad from 'react-lazy-load';

const VideoSection = (props) => {
    return (
    <Box backgroundColor={props?.bgColor ? props?.bgColor : 'black'} padding={{ sm: '60px 0px', md: '80px 0px' }} borderTop={props.borderT} borderBottom={props.borderB}>
        <Container maxW='container.xl'>
        <Style.Wrapper>
            <Style.LeftBox textAlign={{sm:'center', md:'left'}}>
            <Heading 
            variant="extralarge" 
            title={props.heading}
            lineHeight='1'
            color={props?.textColor ? props?.textColor : 'white'}
            />
            {props.headingSub && <Heading 
            variant="extralarge" 
            title={props.headingSub} 
            lineHeight='1'
            color={props?.textColor ? props?.textColor : 'white'}
            />}
            </Style.LeftBox>
            <Style.RightBox>
            <Style.VideoWrapper>
                <LazyLoad offset={300}>
                <Box as='iframe' src={props.video1}  width={{sm:'100%', md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                <Heading 
                variant="smallheading" 
                title={props.smallHeading} 
                lineHeight='1'
                color={props.textColor ? props.textColo : 'white'}
                marginBottom='5px'
                />
                <Heading 
                variant="medium" 
                title={props.midHeading} 
                lineHeight='1'
                color={props.textColor ? props.textColo : 'white'}
                />
                </Style.TextWrapper>
            </Style.VideoWrapper>
            <Style.VideoWrapper>
                <LazyLoad offset={300}>
                <Box as='iframe' src={props.video2} width={{sm:'100%', md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                <Heading 
                variant="smallheading"
                title={props.smallHeading2}  
                lineHeight='1'
                color={props.textColor ? props.textColo : 'white'}
                marginBottom='5px'
                />
                <Heading 
                variant="medium" 
                title={props.midHeading2}
                lineHeight='1'
                color={props.textColor ? props.textColo : 'white'}
                />
                </Style.TextWrapper>
            </Style.VideoWrapper>
            </Style.RightBox>
        </Style.Wrapper>
        </Container>
    </Box>
 )
}
export default VideoSection
