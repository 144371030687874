import React from 'react';
import { Box, Container, GridItem, Image, SimpleGrid } from '@chakra-ui/react';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import { NavLink } from 'react-router-dom';
import Button from '../component/Button/CustomeButton';
import DropInForm from '../component/Form/DropInForm';

const DropIn = () => {
    return (
        <>
            <Box paddingTop='80px'>
                <SimpleGrid
                    columns={{ base: '1', lg: '2' }}
                    spacing='20px'
                >
                    <GridItem position='relative'>
                        <Image src='../images/landingpage1.png' alt='Drop-In Policy' height={{lg:'100%' }} objectFit="cover" />
                        <Box backgroundColor='rgba(0,0,0,0.6)' position='absolute' left="0" right='0' top='0' bottom='0' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                            <Heading title={<Box>Drop-In + <br /> Visitors</Box>} variant='largheading' padding='0 0 30px' color='#fff' lineHeight='0.9' />
                            <NavLink to='/contact-us' >
                                <Button title='Contact Us' color='#fff' />
                            </NavLink>
                        </Box>
                    </GridItem>
                    <GridItem padding={{ sm: "20px 15px 0", md: "", xl: '50px 130px 50px 30px' }}>
                        <Heading title=' Drop-In Policy ' variant='medium' padding='' />
                        <Heading title='Please read before filling out the form ' variant='smallheading' padding='15px 0' />
                        <Text title='The drop-in policy applies to experienced CrossFitters only (experience meaning you worked out at an affiliate for 6 months or more, you know how to scale workouts and are familiar with common CrossFit movements). Drop-in rate is €25 for a day pass.' color='grey' padding='' />
                        <Text title='Drop-Ins are meant for visitors from out of town.' color='grey' padding='15px 0' />
                        <Text title={<Box>If you new to Dublin and CrossFit and want to learn more about getting started, please visit our <NavLink to='/crossfit-dublin'><span style={{ color: '#D12C41' }}>CrossFit page.</span></NavLink> </Box>} color='grey' padding='' />
                        <Box>
                            <DropInForm />
                        </Box>
                    </GridItem>
                </SimpleGrid>
            </Box>

            <Box padding={{ sm: "50px 0", md: "100px 0" }}>
                <Container maxW='container.xl'>
                    <Heading title='Our Location ' variant='medium' padding='' />

                    <SimpleGrid
                        columns={{ base: '1', lg: '2' }}
                        spacing='20px'
                        paddingTop={{ sm: "30px", md: "30px" }}
                    >
                        <GridItem>
                            <Image src='../images/windmill-lane-tss.webp' alt='Windmill Lane studio' />
                            <Heading title='CrossFit Dublin' variant='small' padding='25px 0 0' />
                            <Text title='Perpetua Fitness, 2 Windmill Lane, Dublin 2, D02 F206' padding='15px 0 5px' />
                            <Text title='Phone - 01-524 2635' />
                        </GridItem>
                    </SimpleGrid>
                </Container>

            </Box>
        </>
    )
}

export default DropIn;