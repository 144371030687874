import React, {useEffect} from 'react';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import {Box,Stack,Container,Flex,SimpleGrid,Accordion, AccordionButton,AccordionItem, AccordionPanel, AccordionIcon,Link,Image, GridItem} from '@chakra-ui/react';
import WhatsincludedCard from '../component/WhatsIncluded Card/WhatsincludedCard';
import Testimonial from '../component/Testimonial/Testimonial';
import PriceBox from '../component/Price Component/PriceBox';
import MarqueLoop from '../component/Marque Loop/MarqueLoop';
import WhyMembershipCard from '../component/WhyMemberShip/WhyMembershipCard';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';
import VideoSection from '../component/VideoSection/VideoSection';
import VideoBanner from '../component/VideoBanner/VideoBanner';
import LookingInformation from '../component/LookingInformation/LookingInformation';
import BlogSection from '../component/Blog/BlogSection';


const metaTitle="Personal Training Dublin | Fitness Trainer Dublin"
const metaDesc="Personal training in Dublin with Perpetua Fitness. Find a fitness trainer in Dublin. We know what it takes."


function PersonalTraining(props) {

  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc}/>

      <VideoBanner 
        videobanner='../video/PT-banner.mp4'
        subtitle='We know exactly what it takes to get results'
        title='PERSONAL TRAINING DUBLIN'
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image>Available at our Windmill Lane & Lennox Street Facility</Box>} 
        isButton={true} 
        issubtextpara={false} 
        buttontitle="Book a consult"
        margin="0px 0 0"
        Link="/join-today"
        videoModal='https://www.youtube.com/embed/FxuKfzfqP6A' 
        targetBlank={false}
        modalButtontitle="View The Experience"
        issubtitleRight={true} 
      />

      <Flex  backgroundColor='#fff' padding={{sm:"60px 0 30px", md:"70px 0 35px", xl:"80px 0 40px"}}>
        <Container maxW='container.xl'>
          <MarqueLoop textColor='#000' />
        </Container>
      </Flex>
      <Flex  backgroundColor='#fff' padding={{sm:"30px 0 60px", md:"35px 0 70px", xl:"40px 0 80px"}}>
        <Container maxW='container.xl'>
          <Stack direction={{base:'column',md:'row'} } spacing='30px' textAlign={{base:'left', lg:'left'}} alignItems='center'>
              <Box w={{ base:'100%' ,lg:'50%'}} padding={{md:'0px 20px',lg:'0px 0px'}}>
                <PriceBox 
                  color='#000'
                  currency='€'
                  priceValue='From 60'
                  time='/Session'
                  description='Arrange a call with our Head Coach to discuss the options available'
                  buttonText='Book a consult'
                  link='/join-today'
                  buttonPading='0px'
                  issubHeading={false}
                  manheadind='1-1 Personal Training'
                  width={{base:'100%', sm:'70%'}}
                />
              </Box>
              <Box w={{ base:'100%' ,lg:'50%'}} padding={{md:'0px 20px',lg:'0px 0px'}}>
                <Heading title='WHATS INCLUDED ' variant='small' color='#000' margin='0px 0px 30px' padding={{sm:'0', md:'0 0 0 31px'}} textAlign={{sm:'center', ms:'left'}}></Heading>
                <SimpleGrid columns={{base:'1',lg:'2'}} spacing='0px' className='_whatsinclude_box_body_blar _whatsinclude_box_body_white'>
                  <Box className='_whatsinclude_box_blar' padding={{base:'15px 10px',xl:'20px'}}>
                    <WhatsincludedCard
                      title='1-1 or 2-1 PERSONAL TRAINING ' 
                      headingColor='#000'
                      buttonColor='white'
                      ButtonMargin='0px 0px 0px auto'
                      padding='0px'
                      spacing={props.padding}
                      alt="Personal Training Dublin - arrow icon"
                    />
                  </Box>
                  <Box className='_whatsinclude_box_blar' padding={{base:'15px 10px',xl:'20px'}}>
                    <WhatsincludedCard
                      title='1 HOUR SESSIONS' 
                      headingColor='#000'
                      buttonColor='white'
                      ButtonMargin='0px 0px 0px auto'
                      padding='0px'
                      spacing={props.padding}
                      alt="Personal Training Dublin - arrow icon"
                      />
                  </Box>
                  <Box className='_whatsinclude_box_blar' padding={{base:'15px 10px',xl:'20px'}}>
                    <WhatsincludedCard
                      title='MOVEMENT ASSESSMENT AND
                      IN-BODY ANALYSIS' 
                      headingColor='#000'
                      buttonColor='white'
                      ButtonMargin='0px 0px 0px auto'
                      padding='0px'
                      spacing={props.padding}
                      alt="Personal Training Dublin - arrow icon"
                    />
                  </Box>
                  <Box className='_whatsinclude_box_blar' padding={{base:'15px 10px',xl:'20px'}}>
                    <WhatsincludedCard
                      title='FREE 14 DAY TRIAL TO OUR ONLINE PROGRAMMING' 
                      headingColor='#000'
                      buttonColor='white'
                      ButtonMargin='0px 0px 0px auto'
                      padding='0px'
                      spacing={props.padding}
                      alt="Personal Training Dublin - arrow icon"
                    />
                  </Box>
                </SimpleGrid>
              </Box>
          </Stack>
        </Container>
      </Flex>
     
      <Box padding={{sm:"60px 0", md:"70px 0", xl:"80px 0"}} backgroundColor='#f7f7f7'>
        <Container maxW='container.xl'>
          <Heading
            variant="extralarge"
            title='WHY CHOOSE PERPETUA'
            margin={{ sm: '0px auto 50px', md: '0px auto 86px' }}
            width={{ base: '90%', md: '60%' }}
            textAlign='center'
            lineHeight='1'
            color='#000'
          />
          <SimpleGrid
            columns={{ sm: '1', md: '3' }}
            spacing={5}
            textAlign='center'
            justifyContent='center'
          >
            <GridItem>
              <Heading title='01' color='Red' textAlign='center' />
              <Heading
                title='RELATIONSHIP BUILDING'
                color='#000'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title='Perpetuas coaches would love to meet new clients at our facility and hear about your goals and how we can help. From there, the team can organise the sessions for you and work together to achieve the goals you’ve always wanted.'
                color='#000'
                padding='15px 20px'
              />
            </GridItem>
            <GridItem>
              <Heading title='02' color='Red' textAlign='center' />
              <Heading
                title='WE KNOW WHAT IT TAKES'
                color='#000'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title='We have been working with clients all over Ireland for the past 15 years. Our team of experienced coaches know what it takes to get results in a safe and fun environment.'
                padding='15px 20px'
                color='#000'
              />
            </GridItem>
            <GridItem>
              <Heading title='03' color='Red' textAlign='center' />
              <Heading
                title='PROFESSIONAL SYSTEMS '
                color='#000'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title='We have systems in place to make sure you are receiving the best service available. Each session will delivered online, you can track results, see your progress, communicate with your coach and receive daily accountability.'
                color='#000'
                padding='15px 20px'
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Container maxW='container.xl' padding={{ sm: '60px 30px 0px', md: '80px 30px 10px' }}>
        <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='top' paddingBottom={{ sm: '20px', md: '70px' }}>
          <Box w={{ md: '50%' }} position={{ base: 'relative', md: 'sticky' }} top={{ base: '0', md: '100px' }} height={{ base: 'auto', md: '150px' }} textAlign={{ sm: 'center', md: 'left' }}>
            <Heading title='WHAT YOU GET' lineHeight='1' />
          </Box>
          <Box width={{ md: '50%', lg: '50%' }}>

            <SimpleGrid
              columns={{ sm: '1', lg: '2' }}
              spacing={8}

            >
              <WhyMembershipCard Imguri='/images/check.png' title='EXPERT COACHING' paragraph='We have been working with clients all over Ireland for the past 15 years. Our team of experienced coaches know what it takes to get results in a safe and fun environment' />

              <WhyMembershipCard Imguri='/images/check.png' title='MOVEMENT ASSESSMENT AND IN-BODY ANALYSIS' paragraph='When you begin our sessions, you start with a movement assessment. This allows us to find out everything about you and discuss where you want to start. We also perform an in-body analysis to get a starting point.' />

              <WhyMembershipCard Imguri='/images/check.png' title='PERSONAL PROGRAM ' paragraph='You will receive your weekly program via an app so you can understand exactly what you are working towards, log your numbers and stats and see the progress you are making with the team.' />

              <WhyMembershipCard Imguri='/images/check.png' title='NUTRITION AND FOLLOW UP ' paragraph='If you would like accountability when it comes to nutrition, then the coach will provide a plan for you to follow via the program app where you can log your daily food intake, upload progress photos and see the progress timeline.' />

            </SimpleGrid>

          </Box>
        </Stack>
      </Container>

      <VideoSection bgColor='#f7f7f7' textColor='#000' heading='Get Started with' headingSub='Personal Training' video1='https://youtube.com/embed/FxuKfzfqP6A?si=ylqL_5KQNHc_-A1f' video2='https://youtube.com/embed/aTJcGjVLKj8?si=wcQaynEXpb6Ixigp' smallHeading='Personal Training' smallHeading2='Inbody Machine' midHeading='' midHeading2=''/>
       
      <Flex flexDirection='column' padding={{sm:'60px 0', md:'70px 0', xl:"80px 0" }} position="relative" >
        <Container maxW='container.xl'>
          <Testimonial headingWidth={{sm:'97%',md:'90%'}} iconUri='/images/comment-quote-outline.svg' isname={true} 
          title='“100% CROSSFIT DUBLIN IS THE BEST GYM IN IRELAND. THE COACHING AND PROGRAMMING PROVIDED BY THE 
          DEDICATED TEAM IS EXACTLY WHAT THEY PROVIDE FOR EVERY SINGLE MEMBER”' name='- PPERPETUA CLIENT'/>
        </Container>
      </Flex>

      {/* Faq */}

      <Flex p={{ sm: '0px 0px 70px', md: '20px 0px 90px' }}>
        <Container maxW={{ sm: 'Container.sm', md: 'container.lg', lg: 'container.lg', xl: 'container.xl' }}>
          <Stack direction={{ sm: 'column', md: 'row' }} spacing='24px'>
            <Box width={{ sm: '100%', md: '50%' }}>
              <Box paddingLeft='30px' position={'relative'} _after={{ position: 'absolute', content: '""', width: '3px', height: '100%', top: '0', left: '0', background: 'Red' }}>
                <Heading title='FREQUENTLY ASKED QUESTIONS' lineHeight='1' padding='0 0 30px' margin='0px auto' />
                <Text title='Questions about the workout or our gyms? We got you. Check out the FAQ below or contact us.' width='80%' />
              </Box>
            </Box>
            <Box width={{ sm: '100%', md: '50%' }}>
              <Accordion defaultIndex={[0]} allowMultiple className='Accor_item _black_acording  white_acording'>
                <AccordionItem >
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title='How do I get Started?' variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title=' To discuss the process, please arrange a time for a call or to meet our head coach in one of our facilities. From there, we will organise a coach who will best suit your needs to get you started on your fitness journey.' margin='0 0 20px' />

                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="What availability do the coaches have?" variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title="New clients let us know their preferred days and times of when they would like to train. We then find a coach to suit your preference. We would ask that clients would have some flexibility around days and times. " margin='0 0 20px' />

                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title='What do I get for my personal training payment?' variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title="Each session is one hour long where you receive the best coaching and the results you desire. Receive an In-Body check at the start of each block and monitor training progress through an app where all your training will be delivered." margin='0px 0px 20px' />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="What are the options with payment?" variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title=' You can choose between purchasing a block of sessions in either 10, 15 or 20 sessions.' margin='0 0 20px' />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="What are your hours of operation?" variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='Windmill Lane Studio: Monday-Thursday 6am-9pm | Friday 6am-8pm | Saturday-Sunday 8am-2pm' margin='0 0 20px' />
                    <Text title='Lennox Street Studio: Monday-Friday 6am-12pm, 4pm-8pm | Saturday-Sunday 9am-12pm' margin='0 0 20px' />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Do you have a place I can securely leave my stuff while I work out?" variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title="We have lockers in our men’s and women’s changing rooms where you can leave your belongings. Each locker allows you to create a single-use code while you’re in the facility." margin='0 0 20px' />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Do you have showers?" variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='Yes, we have showers in both men’s and women’s changing rooms. We also provide shampoo, conditioner, body wash, hair dryers & straighteners (plus a few extra goodies you might need to replenish after your sweaty session with us!)' margin='0 0 20px' />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Stack>
        </Container>
      </Flex>


      {/*  */}
      <BlogSection catSlug = 'personal-training' />

      <LookingInformation pageName="PersonalTraining" heading='LOOKING FOR MORE INFORMATION'  subHeading='Chat with a member of the team' buttonTitle='BOOK A CALL'/>

    </>
  );
}

export default PersonalTraining;