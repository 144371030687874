import React from 'react';
import { Box, Flex, Text, Container, Link, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, useDisclosure, } from '@chakra-ui/react';
import Button from '../component/Button/CustomeButton';
import AppDownload from '../component/AppDownload/AppDownload';
import ClassListing from '../component/ClassListing/ClassListing';
import Heading from '../component/Heading/Heading';
// import{Link} from 'react-router-dom';
// import SlideCarousel from '../component/carousel/carousel';
import MetaTitle from './MetaTitle';
import VideoSection from '../component/VideoSection/VideoSection';
import VideoBanner from '../component/VideoBanner/VideoBanner';
import InnerBanner from '../component/InnerBanner/InnerBanner';
import * as Style from './Banner.style';
import { NavLink } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import VideoCard from '../component/VideoCard/VideoCard';

function Home() {

  const banners = [
    {
      "image": "url(/images/home_banner.jpg)",
      "subtitle": "MAKE YOUR MOVE",
      "title": "THE FITNESS EXPERIENCE YOU WON'T FORGET",
    }
  ]
  const metaTitle = "Perpetua Fitness | CrossFit Dublin | Make Your Move"
  const metaDesc = "The fitness experience you won't forget. Community and movement is at the heart of everything we do here at Perpetua Fitness. Make your move."

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />
      {/* when temporary Landing page needed open banner and coment slider carousel banner */}
      {/* <SlideCarousel banners={banners} />  */}

      {/* <Style.LandindBanner backgroundImage={{sm: 'url(../images/homepage_banner1_mb.webp)', md: 'url(../images/homepage_banner1.webp)' }}>
        <Container maxW='container.xl'>
          <Style.BannerWrapper>
            <Style.ButtonWrapper>
              <Flex direction={{ sm: 'column', md: 'row'}} gap='15px' width='100%'
                justifyContent={{ md: 'center' }} alignItems='center'>
                <Style.externalBtn href='/timetable'>Book a Class</Style.externalBtn>
                <Style.Button to='/pricing'>Find Out More</Style.Button>
              </Flex>
            </Style.ButtonWrapper>
          </Style.BannerWrapper>
        </Container>
      </Style.LandindBanner> */}

      {/* <Style.LandindBanner backgroundImage={{ sm: 'url(./images/HomepageBannerMb3.webp)', md: 'url(./images/lulu-home-banner.webp)' }}>
        <Container maxW='container.2xl' p={{ sm: '0 20px', md: "0 30px", lg: "0 70px", xxl:"0 130px" }}>
          <Style.BannerWrapper>
            <Flex alignItems={{ md: 'center' }} width="100%" gap='15px' position="absolute" left={{sm:'0px', md:"0"}} top={{ md: "0" }} bottom={{sm:'70px', md:"0"}}>
              <Box width="100%">
                <Heading textAlign={{sm:"center", md:"left"}} title=''  color='#fff' variant="smallmid"></Heading>
                <Heading color='#fff' textAlign={{sm:"center", md:"left"}}  title="" width={{ sm: "100%", md: "50%", lg:"50%", xl:"60%", xxl:"50%" }} lineHeight="1"  variant="largheading" ></Heading>
                <Flex direction={{ sm: 'column', md: 'row' }} gap='15px' marginTop='20px'
                  justifyContent={{ sm: 'center', md: "flex-start" }} alignItems={{ sm: 'center', md: "flex-start" }}>
                  <Style.Button to='/unbroken-powered-by-lululemon'>Find Out More</Style.Button>
                <Style.externalBtn isExternal={true} href='https://cart.mindbodyonline.com/sites/76074/cart'>Sign up Now</Style.externalBtn>
                </Flex>
              </Box>
            </Flex>
          </Style.BannerWrapper>
        </Container>
      </Style.LandindBanner> */}

      {/* <Style.LandindBanner backgroundImage={{ sm: 'url(./images/mobile._screenHomeBanner.webp)', md: 'url(./images/HomepageSummerClosingParty.webp)' }}>
        <Container maxW='container.2xl' p={{ sm: '0 20px', md: "0 30px", lg: "0 70px", xxl:"0 130px" }}>
          <Style.BannerWrapper>
            <Flex alignItems={{ md: 'center' }} width="100%" gap='15px' position="absolute" left={{sm:'0px', md:"0"}} top={{ md: "0" }} bottom={{sm:'70px', md:"0"}}>
              <Box width="100%">
                <Heading textAlign={{sm:"center", md:"left"}} title=''  color='#fff' variant="smallmid" />
                <Heading color='#fff' textAlign={{sm:"center", md:"left"}}  title="" width={{ sm: "100%", md: "50%", lg:"50%", xl:"60%", xxl:"50%" }} lineHeight="1"  variant="largheading" />
                <Style.ButtonWrap>
                  <Style.Button to='/summer-closing-party'>Purchase Now</Style.Button>
                  <Style.externalBtn href='/timetable'>Book a Class</Style.externalBtn>
                </Style.ButtonWrap>
              </Box>
            </Flex>
          </Style.BannerWrapper>
        </Container>
      </Style.LandindBanner> */}

      {/* <VideoBanner
        videobanner='../video/Home-banner1.mp4'
        title="THE FITNESS EXPERIENCE YOU WON'T FORGET"
        subtitle="MAKE YOUR MOVE"
        isButton={true}
        issubtitleRight={false}
        buttontitle="BOOK A CLASS"
        margin="0px 0 0"
        Link="/timetable"
        videoModal='https://youtube.com/embed/2ukYI8KIwUk?si=fKCNCc7rXezitDjQ'
        targetBlank={false}
        modalButtontitle="GO INSIDE PERPETUA"
      /> */}

      <Box
        alignItems={"center"}
        justifyContent={"center"}
        height={{ sm: '100vh', md: '595px', lg: '595px', xl: '650px', xxl: '720px' }}
        display='flex'
        flexDirection='column'
        position='relative'
        backgroundImage="url(./images/homePageBanner.webp)"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      >
        <Container maxW='container.xl' p={{ sm: '0 20px', md: "0 70px", lg: "0 130px" }} >
          <Box
            minWidth={{ sm: '100%', md: '500px' }}
            paddingTop={"40px"}
            paddingBottom={"60px"}
            textTransform="uppercase"
            className='wow animate fadeInUp'
            position='relative'
            zIndex='111'
            margin='0 auto'
            display='flex'
            alignItems='center'
            flexDirection='column'
          >
            <Heading as='small' cfSize={{ sm: 'xs', md: 'sm' }} variant="smallmid" m={"0"} p={"0"} color='#fff' title='MAKE YOUR MOVE' fontFamily='BlenderProBold' textAlign='center' />
            <Heading as='h1' cfSize={{ sm: 'md', md: 'lg', lg: 'xl', xl: 'xxl' }} color='#fff' title="THE FITNESS EXPERIENCE YOU WON'T FORGET" lineHeight="1" padding="0px 0px 5px" variant="largheading" textAlign='center' />
            <Box display='flex'
              alignItems='center'
              justifyContent='flex-start'
              flexDirection='row'
              gap='10px'
              margin="10px 0">
              <NavLink to=''>
                <Button title='Book a Class' />
              </NavLink>

              <Style.ButtonModal onClick={onOpen} color='white'>go inside perpetua</Style.ButtonModal>
              <Box marginLeft={{ md: '10px' }} >
                <Modal isOpen={isOpen} onClose={onClose} size={{ sm: 'sm', md: '3xl' }} >
                  <ModalOverlay />
                  <ModalContent marginTop='100px'>
                    <ModalCloseButton />
                    <ModalBody >
                      <LazyLoad offset={300}>
                        <VideoCard src='https://youtube.com/embed/2ukYI8KIwUk?si=fKCNCc7rXezitDjQ' width={{ sm: '100%', md: '700px' }} margin={{ sm: '30px 3px', md: '30px 9px' }} maxHeight='540px'></VideoCard>
                      </LazyLoad>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Flex
        alignItems="center"
        justifyContent="center"
        background="fff"
      >
        <Container maxW='container.xl'>
          <Box
            width="90%"
            p={{ base: '20px 0px', sm: '30px 0px', md: '50px 0px' }}
            textAlign="center"
            color="000"
            textTransform="uppercase"
            m="auto"
          >
            <Heading
              color="#000"
              variant="medium"
              title="Community and movement is at the heart of everything we do here at Perpetua. We live for intelligent programming, functional fitness and having a good time together."
              ineHeight='1'
              lineHeight={{ sm: ".9", md: "1.3" }}
              className='wow animate fadeInUp'
            >
            </Heading>
            <Text
              className='wow animate fadeInUp'
              fontSize={{ sm: "16px", md: "24px" }}
              as="p"
              fontFamily='Poppins'
              marginTop='20px'
              marginBottom='20px'
              color='000'
              fontWeight='300'
            >
              <Text as="b">Make</Text> Your Move</Text>
            <Link href="/timetable">
              <Button title='BOOK A CLASS' className='wow animate fadeInUp' color='000' />
            </Link>
          </Box>
        </Container>
      </Flex>

      <ClassListing />
      <AppDownload
        bg="url('/images/home-app-banner.webp')"
        heading='We care a lot about our values. They underpin our culture, helping us to achieve our goals and create a great place to work'
        para=''
        imageUri='/images/iphone.webp'
        appstore='/images/app-store.png'
        googleplay='/images/google-play.png'
        className="_appdownload_section"
        googleplaylink="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
        appstorelink="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131"
        isAppButton={false}
        link="/story"
        buttonTitle='Read Our Values'
        alt="iphone - perpetua fitness"
        color='#000'
        variant='small'
      />
    </>
  );
}

export default Home;
