import React from 'react';
import * as Style from './Aminitybox.style';
import Text from '../SubHeading/SubHeading';
import LazyLoad from "react-lazy-load";
const AminityBox = (props) => {
  return (
    <>
        <Style.AminityBox>
            <Style.AminityIconWrapper>
                <LazyLoad offset={300}>
                    <Style.AminityIcon src={props.Icon}  alt={props.alt} />
                </LazyLoad>
            </Style.AminityIconWrapper>
            <Style.AminityHeading color='#000' fontSize='30px' marginBottom='15px' >{props.Heading}</Style.AminityHeading>
            <Text title={props.Description} as='p' fontSize="14px" color='#000' fontWeight='300'/>
        </Style.AminityBox>
    </>
  )
}

export default AminityBox;
