import React, {useState, useEffect, useRef, lazy, Suspense} from "react";
import { FormControl, Stack, Box, Input, Flex, Checkbox, Link } from '@chakra-ui/react';
import Button from '../Button/CustomeButton';
import axios from 'axios';
import Text from "../SubHeading/SubHeading";
import Heading from '../Heading/Heading';
const ReCAPTCHA = lazy(()=>import('react-google-recaptcha')) ;

function OnlineApplication() {
    const [successFrom, setsuccessFrom] = useState('');
    const [btnDisable, setBtnDisable] = useState(false);

    const [isCaptcha, setIsCaptcha] = useState(null);
    const [errorCaptcha, setErrorCaptcha] = useState('');

    // checkbox value
    const [checked, setChecked] = useState(false);
    const handleChange = () => {
        setChecked(!checked);
    };
    useEffect(() => {
        contactForm.check = checked;
    }, [checked]);

    const getReCaptchaVal = (value) => {
        setIsCaptcha(value);
        setErrorCaptcha('');
    }

    const [contactForm, setContactForm] = useState({
        name: "",
        address: "",
        phone: "",
        date: "",
        position: "",
        email: "",

        fullname: "",
        company: "",
        relationship: "",
        refphone: "",
        refaddress: "",

        employcompany: "",
        employaddress: "",
        jobtitle: "",
        responsibilities: "",
        from: "",
        to: "",
        reasonleaving: "",
        check: "",
        uploadfile: "",

    });
    const handelInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setContactForm({ ...contactForm, [name]: value });
    }

    const filePush = (event) => {
        const selectedFile = event.target.files[0];
        setContactForm({
            ...contactForm,
            uploadfile: selectedFile,
        });
    };

    const handelSubmit = (e) => {
        e.preventDefault();
        setsuccessFrom('');
        setBtnDisable(true);
        console.log(contactForm)

        if (isCaptcha === null) {
            setBtnDisable(false);
            setsuccessFrom('');
            setErrorCaptcha('Please verify that you are not a robot.');
            return false;
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios.post(process.env.REACT_APP_ADMIN_API_BASE_URL + '/career-application', {
            name: contactForm.name,
            phone_no: contactForm.phone,
            email: contactForm.email,
            address: contactForm.address,
            date: contactForm.date,
            position: contactForm.position,

            references_full_name: contactForm.fullname,
            references_company: contactForm.company,
            references_relationship: contactForm.relationship,
            references_phone: contactForm.refphone,
            references_address: contactForm.refaddress,

            previous_employment_company: contactForm.employcompany,
            previous_employment_address: contactForm.employaddress,
            previous_employment_jobtitle: contactForm.jobtitle,
            previous_employment_responsibilities: contactForm.responsibilities,
            previous_employment_from: contactForm.from,
            previous_employment_to: contactForm.to,
            previous_employment_reasonleaving: contactForm.reasonleaving,
            previous_employment_check: contactForm.check,
            file_upload: contactForm.uploadfile,
        },config)
            .then((response) => {
                setBtnDisable(false);
                console.log(response.data.success);
                // setsuccessFrom(response.data.success);
                setsuccessFrom("Your application has been submitted successfully");
                window.grecaptcha.reset();
                contactForm.name = "";
                contactForm.address = "";
                contactForm.phone = "";
                contactForm.date = "";
                contactForm.email = "";
                contactForm.position = "";

                contactForm.fullname = "";
                contactForm.company = "";
                contactForm.relationship = "";
                contactForm.refphone = "";
                contactForm.refaddress = "";

                contactForm.employcompany = "";
                contactForm.employaddress = "";
                contactForm.jobtitle = "";
                contactForm.responsibilities = "";
                contactForm.from = "";
                contactForm.to = "";
                contactForm.reasonleaving = "";
                contactForm.check = "";
                contactForm.uploadfile = "";

            })
            .catch((error) => {
                setBtnDisable(false);
                setsuccessFrom('');
                window.grecaptcha.reset();
            });
    }
    const fileInputRef = useRef(null);

    return (

        <>
            <form onSubmit={handelSubmit} className="OnlineApplication" id="OnlineApplication">

                <FormControl width={{ sm: '95%', md: '80%' }} p={{ sm: '20px', md: '30px 20px', lg: '20px' }} m='0px auto' border='2px solid' borderColor='Red' borderRadius='20px'>
                    <Flex direction={{ md: 'row', sm: 'column' }} gap={{ md: '30', sm: '15' }} justifyContent={{ md: 'space-between', sm: 'flex-start' }} alignItems={{ md: 'space-between', sm: 'flex-start' }} flexWrap='wrap'>
                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '47%', sm: '100%' }}>
                            <Input placeholder="Name *" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' name="name" value={contactForm.name} onChange={handelInput} required />
                        </Stack>
                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '47%', sm: '100%' }}>
                            <Input placeholder="Address *" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' name="address" value={contactForm.address} onChange={handelInput} required />
                        </Stack>
                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '47%', sm: '100%' }}>
                            <Input placeholder="Phone *" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' name="phone" type='number' value={contactForm.phone} onChange={handelInput} required />
                        </Stack>
                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '47%', sm: '100%' }}>
                            <Input placeholder="Date Available*" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' type='number' name="date" value={contactForm.date} onChange={handelInput} required />
                        </Stack>
                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '47%', sm: '100%' }}>
                            <Input placeholder="Position Applied For*" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' name="position" value={contactForm.position} onChange={handelInput} required />
                        </Stack>
                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '47%', sm: '100%' }}>
                            <Input placeholder="Email *" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' type='email' name="email" value={contactForm.email} onChange={handelInput} required />
                        </Stack>
                    </Flex>
                    <Box width='100%'>
                        <Heading title='REFERENCES:' variant='smallmid' as='h4' color='#000' margin='30px 0px 0px' />
                    </Box>
                                        
                    <Flex direction={{ md: 'row', sm: 'column' }} gap={{ md: '30', sm: '15' }} justifyContent={{ md: 'space-between', sm: 'flex-start' }} alignItems={{ md: 'space-between', sm: 'flex-start' }} flexWrap='wrap' marginTop={{ sm: '10px' }}>

                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '47%', sm: '100%' }}>
                            <Input placeholder="Full Name" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' type='text' name="fullname" value={contactForm.fullname} onChange={handelInput} />
                        </Stack>
                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '47%', sm: '100%' }}>
                            <Input placeholder="Company" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' type='text' name="company" value={contactForm.company} onChange={handelInput} />
                        </Stack>
                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '47%', sm: '100%' }}>
                            <Input placeholder="Relationship" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' type='text' name="relationship" value={contactForm.relationship} onChange={handelInput} />
                        </Stack>
                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '47%', sm: '100%' }}>
                            <Input placeholder="Phone" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' type='number' name="refphone" value={contactForm.refphone} onChange={handelInput} />
                        </Stack>
                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '100%', sm: '100%' }}>
                            <Input placeholder="Address" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' type='text' name="refaddress" value={contactForm.refaddress} onChange={handelInput} />
                        </Stack>
                    </Flex>

                    <Box width='100%' marginTop='35px'>
                        <Heading title='PREVIOUS EMPLOYMENT:' variant='smallmid' as='h4' color='#000' margin='30px 0px 0px' />
                    </Box>
                    <Flex direction={{ md: 'row', sm: 'column' }} gap={{ md: '30', sm: '15' }} justifyContent={{ md: 'space-between', sm: 'flex-start' }} alignItems={{ md: 'space-between', sm: 'flex-start' }} flexWrap='wrap' marginTop={{ sm: '10px' }}>

                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '47%', sm: '100%' }}>
                            <Input placeholder="Company" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' type='text' name="employcompany" value={contactForm.employcompany} onChange={handelInput} />
                        </Stack>
                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '47%', sm: '100%' }}>
                            <Input placeholder="Address" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' type='text' name="employaddress" value={contactForm.employaddress} onChange={handelInput} />
                        </Stack>
                        <Stack direction={['column']} spacing='24px' position='relative' width={{ sm: '100%' }}>
                            <Input placeholder="Job Title" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' type='text' name="jobtitle" value={contactForm.jobtitle} onChange={handelInput} />
                        </Stack>
                        <Stack direction={['column']} spacing='24px' position='relative' width={{ sm: '100%' }}>
                            <Input placeholder="Responsibilities" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' type='text' name="responsibilities" value={contactForm.responsibilities} onChange={handelInput} />
                        </Stack>
                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '47%', sm: '100%' }}>
                            <Input placeholder="From" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' type='text' name="from" value={contactForm.from} onChange={handelInput} />
                        </Stack>

                        <Stack direction={['column']} spacing='24px' position='relative' width={{ md: '47%', sm: '100%' }}>
                            <Input placeholder="To" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' type='text' name="to" value={contactForm.to} onChange={handelInput} />
                        </Stack>

                        <Stack direction={['column']} spacing='24px' position='relative' width={{ sm: '100%' }}>
                            <Input placeholder="Reason for Leaving" _placeholder={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #e1e1e1' height='50px' borderRadius='0px' color='#000' fontSize='xs' type='text' name="reasonleaving" value={contactForm.reasonleaving} onChange={handelInput} />
                        </Stack>

                        <Stack direction={['column']} spacing='24px' position='relative' width={{ sm: '100%' }}>
                            <Link onClick={() => fileInputRef.current.click()} style={{ color: '#000', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important', paddingLeft: 10 }} border='0' borderBottom='2px solid #e1e1e1' height='38px' borderRadius='0px'>
                                Upload Your File
                                {contactForm.uploadfile && (
                                    <span style={{
                                        color: '#D12C41', fontFamily: 'Poppins', fontSize: '12px', padding: '0 !important', marginTop: 3, marginLeft: '15px'
                                    }}>
                                        {contactForm.uploadfile.name}
                                    </span>
                                )}
                            </Link>
                            <input
                                multiple={false}
                                ref={fileInputRef}
                                type='file'
                                hidden
                                onChange={filePush}
                                accept='.pdf, .doc, .docx'
                            />
                        </Stack>


                        <Stack direction={['column']} spacing='24px' position='relative' width={{ sm: '100%' }}>
                            <Checkbox size='lg' colorScheme='red' name="check" value={contactForm.check} onChange={handleChange} >
                                <Text title='May we contact your previous supervisor for a reference?' as='p' color='#000' fontSize='16px' width={{ sm: '100%' }} />
                            </Checkbox>
                        </Stack>
                        <Stack direction={{ sm: 'column', md: 'row' }} spacing='0px' position='relative' marginTop='25px' width={{ sm: '100%' }} justifyContent={{ md: 'space-between', sm: 'flex-start' }} alignItems={{ md: 'space-between', sm: 'flex-start' }}>
                            <Suspense fallback={"Loading.."}>
                                <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY} onChange={getReCaptchaVal} className='captcha' theme="dark" />
                            </Suspense>
                            <Box marginTop={{ sm: '15px !important' }} >
                                <Button btnDisable={btnDisable} submit="submit" title='Submit' width='208px' color='#000'></Button>
                            </Box>
                        </Stack>
                    </Flex>
                    {errorCaptcha && <Text title={errorCaptcha} fontSize="14px" color="red" />}
                    <Flex justifyContent="center" margin="30px 0 0"> {successFrom && <Text title={successFrom} fontSize="16px" color="green" />}</Flex>

                </FormControl>
            </form>
        </>
    )
}

export default OnlineApplication;
