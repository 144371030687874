import React from 'react';
import { Box, Container, VStack, Link } from '@chakra-ui/react';
import Heading from '../Heading/Heading';
import ContactForm from '../Form/ContactForm';
import LinkButton from '../Button/LinkButton';

const LookingInformation = (props) => {
    return (
        <>
            <VStack backgroundColor='#f7f7f7' padding='96px 0px'>
                <Container
                    display='flex'
                    maxW='container.xl'
                    flexDirection={{ base: 'column', lg: 'row' }}
                    alignItems='top'
                    alignContent='center'
                    justifyContent='center'
                    justifyItems='center'
                >
                    <Box w={{ base: '100%', lg: '50%' }} textAlign={{ base: 'center', lg: 'left' }} marginBottom={{ base: '50px', lg: '0' }}>
                        <Heading title={props.heading} variant='extralarge' lineHeight='1' margin='0px 0px 40px' color='#000' />
                        <Heading title={props.subHeading} variant='smallmid' lineHeight='1' margin='0px 0px 30px' color='#000' />
                        <Link href='/contact-us' textDecoration='none' display='inline-block' _hover={{ textDecoration: 'none' }}>
                            <LinkButton
                                title={props.buttonTitle}
                                Imguri='/images/red_arrow.svg'
                                border='2px solid' borderColor='Red'
                                padding='20px 25px'
                                color='#000'
                            />
                        </Link>
                    </Box>
                    <Box w={{ base: '100%', lg: '50%' }}>
                        <ContactForm pageName={props.pageName} textColor='#000' />
                    </Box>
                </Container>
            </VStack>
        </>
    )
}

export default LookingInformation;
