import React from "react";
import { Flex, Box,  Container, Stack, Text as PText, Image, UnorderedList, ListItem, SimpleGrid, GridItem, Link} from '@chakra-ui/react';
import Commoncard from '../component/Application/CommonCard';
import {CheckIcon } from '@chakra-ui/icons';
import Heading from "../component/Heading/Heading";
import Text from "../component/SubHeading/SubHeading";
import MetaTitle from './MetaTitle';

function Facilitics(){
    return(
        <>
            <MetaTitle title='Facilitics | Perpetua Fitness'/>
            <Container maxW='container.xl' padding={{sm:'150px 5px 0px 10px',md:'160px 0 0', xl:'185px 0px 60px'}} >
                <Flex flexDirection={{sm: "column", md: "row"}} >
                    <Box w={{sm:"100%", md: "50%" }} h={{sm:"auto" }} top={{sm:"0" , md: '100px'}} paddingLeft={{sm:'20px', md:'30px'}}  paddingBottom={{sm:"30" , md: '10px'}}>
                        <Box marginTop={{lg:'50px'}}>
                            <Heading title='OPEN POSITION' cfSize='20px'  lineHeight='1'></Heading>
                            <Heading title='FACILITIES' cfSize={{sm:'30px', md:'50px'}}  lineHeight='1'></Heading>
                        </Box> 
                        <Box marginTop={{lg:'80px'}}>
                            <UnorderedList paddingBottom='30px' m='10px 0px 0px' width={{base:'90%',sm:'90%', md:'70%'}} listStyleType='none' color='black' fontFamily='Poppins' fontWeight='300' >
                                <ListItem  borderBottom="1px solid #DFDFDF" padding='10px 0px' position="relative"   paddingLeft="30px"
                                    _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'15px', left:'0', background:'Red', borderRadius:'50px'}}>
                                   <Heading title='FULL-TIME / PART-TIME' cfSize="20px" fontWeight='500'></Heading> 
                                   <Text title='Part-Time' /> 
                                </ListItem>
                                <ListItem  borderBottom="1px solid #DFDFDF" padding='10px 0px' position="relative"   paddingLeft="30px"
                                    _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'15px', left:'0', background:'Red', borderRadius:'50px'}}>
                                   <Heading title='LOCATION' cfSize="20px" fontWeight='500' />
                                   <Text title='Dublin City' />
                                </ListItem>
                                <ListItem  borderBottom="1px solid #DFDFDF" padding='10px 0px' position="relative"   paddingLeft="30px"
                                    _after={{w:'10px', h:'10px',position:'absolute', content:'""',top:'15px', left:'0', background:'Red', borderRadius:'50px'}}>
                                   <Heading title='PERKS' cfSize="20px" fontWeight='500' />
                                   <Text title='Flexible working hours, Company events & social hours and Gym and Studio access ' />
                                </ListItem>
                            </UnorderedList>            
                        </Box>
                    </Box>
                    <Box w={{sm:"100%", md:"45%"}} >
                        <Stack>
                            <Image src='/images/application1.png'></Image>
                            <Image src='/images/application2.png'></Image>
                        </Stack>     
                    </Box> 
                </Flex>
            </Container>

            <PText width={{sm:'90%' , md:"90%"}}  borderTop="1px solid #DFDFDF" padding={{md:"0px 0px 40px"}} margin='0 auto' marginTop={{sm:'50px', md:'30px'}} marginBottom={{sm:'20px', md:'0px'}}/> 
            {/* //////// */}


                <Box marginTop='40px'>
                    <Container maxW='container.xl'>
                        <SimpleGrid 
                            columns={{sm:'1',md:'2'}} 
                            columnGap={0} 
                            rowGap={2} 
                            width="full" >
                            <GridItem borderRight={{sm:'0', md:"2px solid #DFDFDF"}} position='relative' >
                                <PText width='25px' marginTop='8px' top='0' right='0' height='1'  position='absolute' marginRight='10px' borderTop={{sm:'0', md:'1px solid black'}}                               >
                                </PText>
                                <Box width='15px' height='15px' borderRadius='50px' boxShadow={{sm:'0', md:'0px 0px 0px 5px rgba(209,44,65,0.3)'}} top='0' right='-9px'  position='absolute' backgroundColor={{sm:'',md:'Red'}}>
                                </Box>   
                                <Box paddingLeft={{sm:'7px', md:'0'}} paddingBottom={{sm:'20px', md:'0'}}>
                                    <Heading  
                                    title='WHO WE ARE'
                                    textAlign={{sm:'left',md:"right"}}
                                    fontSize={{sm:'30px', md:'36px', lg:'50'}}
                                    lineHeight="1"
                                    width='90%'
                                    padding={{sm:'0px 0px 16px', md:'0px 5px' }}
                                    margin={{md:'-15px 0 0 0 '}}
                                    />
                                          
                                    <Text
                                    title="At PERPETUA, we are obsessed with coaching and providing an experience that each member will continue to talk about. We eat, drink, and sleep coaching. The job of our coaches is to provide the best experience to our members every single day. Early alarms and late nights don't phase us – we are focused and committed."
                                    width='90%'
                                    textAlign={{sm:'left', md:"right"}} 
                                    padding={{md:'20px 5px 10px'}}
                                    /> 
                                    <Text
                                    title="In an industry plagued with mediocrity, we strive to set a higher bar. Simply put we are professionals, and we are investing heavily in progressing our coaches to new heights."
                                    width='90%'
                                    textAlign={{sm:'left', md:"right"}} 
                                    padding={{md:'10px 5px'}}
                                    />
                                   
                                    <Text
                                    title='Through a culture of excellence, embracing what it means to represent yourself in the best possible way, and a dynamic development framework we arm our coaches with the tools and resources they need to excel and evolve.'
                                    width='90%'
                                    textAlign={{sm:'left', md:"right"}} 
                                    padding={{md:'10px 5px'}}
                                    />
                                </Box>               
                            </GridItem>   
                        </SimpleGrid>
                        <Flex>
                        <SimpleGrid 
                            columns={{sm:'1',md:'2'}} 
                            columnGap={0} 
                            rowGap={2} 
                            width="full" 
                        >    
                            <GridItem>                 
                            </GridItem>  
                            <GridItem borderLeft={{sm:'0', md:"2px solid #DFDFDF"}} position='relative'>
                                <PText width='25px' marginTop='8px' top='0' left='0' height='1'  position='absolute' marginLeft='10px' borderTop={{sm:'0' ,md:'1px solid black' }}                              >
                                </PText>
                                <Box width='15px' height='15px' borderRadius='50px' boxShadow={{sm:'0', md:'0px 0px 0px 5px rgba(209,44,65,0.3)'}} top='0' left='-8px'  position='absolute' backgroundColor={{sm:'' ,md:'Red'}}>
                                </Box> 
                                <Box paddingLeft={{sm:'7px', md:'40px'}}>
                                    <Heading 
                                    title='Join our Facilities Team! '
                                    cfSize={{sm:'30px', md:'36px', lg:'50px'}}
                                    textAlign={{sm:'left', md:"left"}}
                                    lineHeight="1"
                                    width={{sm:'100%',md:'90%'}}
                                    marginTop={{md:'-20px'}}
                                    paddingBottom={{sm:'15px', md:'25px'}}
                                    paddingLeft={{md:'24px'}}
                                    textTransform='uppercase'
                                    />
                       
                                    <Text
                                    title='Our Facilities Team is crucial to the operation of our fitness studio - they keep our equipment and studio clean and inviting for guests, make sure everything is in operating order, and provide excellent customer service. We are seeking people that are driven, diligent, proactive, trustworthy, and team-oriented and who possess excellent communication and time-management abilities. If you succeed in this position, there are opportunities for growth within the organization.'
                                    width='90%'
                                    textAlign="left"
                                    padding={{sm:'10px 0 0', md:'20px 25px 0'}}    
                                    />
                                 
                                </Box>
                            </GridItem>
                        </SimpleGrid>
                    </Flex>
                    <Flex>
                        <SimpleGrid 
                            columns={{sm:'1',md:'2'}} 
                            columnGap={0} 
                            rowGap={2} 
                            width="full" 
                        >    
                            <GridItem borderRight={{sm:'0', md:"2px solid #DFDFDF"}} position='relative' >
                                <PText width='25px' marginTop='8px' top='0' right='0' height='1'  position='absolute' marginRight='10px' borderTop={{sm:'0', md:'1px solid black'}}                               >
                                </PText>
                                <Box width='15px' borderRadius='50px' boxShadow={{sm:'0' ,md:'0px 0px 0px 5px rgba(209,44,65,0.3)'}} top='0' right='-9px' height='15px'  position='absolute' backgroundColor={{sm:'', md:'Red'}}>
                                </Box> 
                                <Box paddingLeft={{sm:'0px', md:'0'}}>
                                
                                <Heading 
                                title='Facility Team Responsibilities'
                                textAlign={{sm:'left',md:"right"}}
                                cfSize={{sm:'30px', md:'36px', lg:'50px'}}
                                lineHeight="1"
                                width='90%'
                                margin={{sm:'30px 0 0', md:'-15px 0 0'}}
                                padding={{sm:'10px 10px 20px', md:'0 0 20px'}}
                                
                                />
                                  
                                    <UnorderedList  textAlign={{sm:'left' ,md:"right"}}  paddingRight={{ md:'65px'}}  m='0px' width={{base:'90%',sm:'100%'}} listStyleType='none' color='black' fontFamily='Poppins' fontWeight='normal'>
                                        <ListItem padding='5px 0px' flexDirection={{sm:'row-reverse', md:'row'}}display={{sm:'flex'}} justifyContent={{sm:'flex-end',md:'flex-start'}}>
                                            Prepare studio spaces before, after, and in-between classes by cleaning exercise equipment, sweeping, mopping and/or vacuuming floors, and wiping mirrors.  <Box><CheckIcon marginRight={{sm:'10px', md:'0'}} marginLeft='10px' color='#D3D3D3'/></Box>     
                                        </ListItem>
                                        
                                        <ListItem padding='5px 0px' flexDirection={{sm:'row-reverse', md:'row'}} display={{sm:'flex'}} justifyContent={{sm:'flex-end',md:'flex-start'}}>    
                                            Regular deep cleaning, organization, and service of common areas, including the lobby space, Mojo Health Bar, retail shelves, locker rooms, bathrooms, hallways, and storage areas. <Box> <CheckIcon marginRight={{sm:'10px', md:'0'}} marginLeft='10px' color='#D3D3D3'/></Box> 
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{sm:'row-reverse', md:'row'}} display={{sm:'flex', xl:'block'}} justifyContent={{sm:'flex-end',md:'flex-start'}}>
                                            Conduct small studio fixes in all facilities spaces.   <CheckIcon marginRight={{sm:'10px', md:'0'}} marginLeft='7px' color='#D3D3D3'/>    
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{sm:'row-reverse', md:'row'}} display={{sm:'flex'}} justifyContent={{sm:'flex-end',md:'flex-start'}}>
                                            Laundry services, including collecting, washing, and folding towels.   <Box> <CheckIcon marginRight={{sm:'10px', md:'0'}} marginLeft='10px' color='#D3D3D3'/> </Box> 
                                        </ListItem>
                                        <ListItem padding='5px 0px'  flexDirection={{sm:'row-reverse', md:'row'}} display={{sm:'flex',  xl:'block'}} justifyContent={{sm:'flex-end',md:'flex-start'}}>
                                            Adhere to daily and weekly cleaning and maintenance checklists.  <CheckIcon marginRight={{sm:'10px', md:'0'}} marginLeft='10px' color='#D3D3D3'/>
                                        </ListItem>
                                        <ListItem padding='5px 0px'  flexDirection={{sm:'row-reverse', md:'row'}} display={{sm:'flex', xl:'block'}} justifyContent={{sm:'flex-end',md:'flex-start'}}>
                                            Communicate any facilities issues to the management team.    <CheckIcon marginRight={{sm:'10px', md:'0'}} marginLeft='10px' color='#D3D3D3'/>
                                        </ListItem>
                                      
                                    </UnorderedList> 
                                        
                                </Box>
                            </GridItem> 
                        </SimpleGrid> 
                    </Flex>    
                    <Flex>
                        <SimpleGrid 
                            columns={{sm:'1',md:'2'}} 
                            columnGap={0} 
                            rowGap={2} 
                            width="full" 
                        >    
                            <GridItem>                 
                            </GridItem>  
                            <GridItem 
                            position='relative' _after={{position:'absolute',content:'""',width:{ md:'2px' }, height:'90%', top:'0', left:'0',background:'#DFDFDF'}}
                            >
                                <PText width='25px' marginTop='8px' top='0' left='0' height='1' position='absolute' marginLeft='10px' borderTop={{sm:'0' ,md:'1px solid black' }}                              >
                                </PText>
                                <Box width='15px' height='15px' zIndex='1'  borderRadius='50px' boxShadow={{sm:'0', md:'0px 0px 0px 5px rgba(209,44,65,0.3)'}} top='0' left='-8px'  position='absolute' backgroundColor={{sm:'' ,md:'Red'}}>
                                </Box> 
                                <Box paddingLeft={{sm:'7px', md:'40px'}}>
                                    <Heading 
                                    title='Minimum Requirements'
                                    fontSize={{sm:'30px', md:'36px', lg:'50px'}}
                                    textAlign={{sm:'left', md:"left"}}
                                    lineHeight="1"
                                    width={{sm:'100%',md:'90%'}}
                                    margin={{sm:'20px 0 0' ,md:'-20px 0 0'}}
                                    padding={{sm:'0 0 15px ', md:'0 24px 25px'}}                       
                                    />
                            
                                    <UnorderedList  textAlign="left" paddingLeft={{md:'22px'}} paddingBottom='30px' m='0px' width={{base:'90%',sm:'100%'}} listStyleType='none' color='black' fontFamily='Poppins' fontWeight='normal' >
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px'  color='#D3D3D3'/>
                                            Ability to work non-traditional hours, including early mornings, late evenings, weekends, and holidays. Part-time employees must have at least 3 days of availability, with at least one of those days being a Saturday or Sunday.   
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px'  color='#D3D3D3'/>Candidates must be eligible to work in Ireland.
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px'  color='#D3D3D3'/> Candidates must have a high level / be fluent in English. 
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px'  color='#D3D3D3'/> Experience in or desire to learn about facilities management and maintenance. 
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px'  color='#D3D3D3'/>Exceptional cleaning skills and a clear understanding of what constitutes a sanitary environment. 
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px'  color='#D3D3D3'/>Ability to communicate effectively and in a time-sensitive manner with supervisors, peers, subordinates, and clients.
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px'  color='#D3D3D3'/>Ability to follow instructions in a fast-paced environment.    
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px'  color='#D3D3D3'/>Excellent organization and time management skills. 
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px'  color='#D3D3D3'/>Detail-oriented mindset. 
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px'  color='#D3D3D3'/>Proven dependability and reliability.
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px'  color='#D3D3D3'/> Friendly and personable attitude. 
                                        </ListItem>
                                       
                                    </UnorderedList>
                                </Box>
                            </GridItem>
                        </SimpleGrid>
                    </Flex>
                    <Flex paddingBottom={{sm:'50px', md:'70px'}}  marginTop={{md:'-90px'}}>
                        <SimpleGrid 
                            columns={{sm:'1',md:'2'}} 
                            columnGap={0} 
                            rowGap={2} 
                            width="full" 
                        >   
                            <GridItem position='relative' _before={{position:'absolute',content:'""',width:{md:'2px'}, height:'100%', top:'0',right:'0',background:'#DFDFDF'}} >
                                <PText width='25px' marginTop='8px' top='0' right='0' height='1'  position='absolute' marginRight='10px' borderTop={{sm:'0', md:'1px solid black'}}                               >
                                </PText>
                                <Box width='15px' borderRadius='50px' boxShadow={{sm:'0' ,md:'0px 0px 0px 5px rgba(209,44,65,0.3)'}} top='0' right='-7px' height='15px'  position='absolute' backgroundColor={{sm:'', md:'Red'}}>
                                </Box> 
                                <Box paddingLeft={{sm:'7px', md:'0'}}>
                                
                                <Heading 
                                title='Benefits '
                                textAlign={{sm:'left',md:"right"}}
                                cfSize={{sm:'30px', md:'36px', lg:'50px'}}
                                lineHeight="1"
                                width='90%'
                                margin={{md:'-15px 0 0'}}
                                padding={{sm:'0 0 10px', md:'0 0 20px'}}
                                />
                               
                               
                                    
                                    <UnorderedList  textAlign={{sm:'left' ,md:"right"}}  paddingRight={{ md:'65px'}}  m='0px' width={{base:'90%',sm:'100%'}} listStyleType='none' color='black' fontFamily='Poppins' fontWeight='normal' >
                                        <ListItem padding='5px 0px' flexDirection={{sm:'row-reverse', md:'row'}} display={{sm:'flex', md:'block'}} justifyContent={{sm:'flex-end',md:'flex-start'}}>
                                            Flexible working hours  <CheckIcon marginRight={{sm:'10px', md:'0'}} marginLeft={{md:'10px'}} color='#D3D3D3'/>    
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{sm:'row-reverse', md:'row'}} display={{sm:'flex', md:'block'}} justifyContent={{sm:'flex-end',md:'flex-start'}}>    
                                            Company events & social hours <CheckIcon marginRight={{sm:'10px', md:'0'}} marginLeft='2px' color='#D3D3D3'/>    
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{sm:'row-reverse', md:'row'}} display={{sm:'flex', md:'block'}} justifyContent={{sm:'flex-end',md:'flex-start'}}>
                                            Gym and Studio access   <CheckIcon marginRight={{sm:'10px', md:'0'}} marginLeft='2px' color='#D3D3D3'/>    
                                        </ListItem>
                                       
                                    </UnorderedList> 
                                        
                                </Box>
                            </GridItem> 
                        </SimpleGrid> 
                    </Flex>    
                    </Container>
                </Box>


           
            {/*  application  */}
           <Commoncard
            backgroundColor='#f7f7f7'
            width={{sm:'90%', md:'60%'}}
            fontSizeHeading={{sm:'30px',md:'50px'}}
            fontSize='20px'
            color='#585858'
            padding='20px 0px'
            textAlign='center'
            title='JOIN TEAM Perpetua'
            subHeading="Full and part-time positions are available."
            subtitle={<Box>To apply, download and print the application below. Please fill out, scan, and email your completed application to <Link href='mailto:hello@perptua.ie' textDecoration='underline' > hello@perpetua.ie</Link> with the job title as subject line</Box>}
            buttontitle='Application'
            isButton={true} 
            buttonLink = 'careers'
            /> 
        </>
    )
}
export default Facilitics;