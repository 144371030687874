import React from 'react';
import { Box, Container, Flex, GridItem, Link, SimpleGrid, Image } from '@chakra-ui/react';
import * as Style from './LandingPage1.style';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';
import { NavLink } from 'react-router-dom';
import Button from '../Button/CustomeButton';
import VideoBanner from '../VideoBanner/VideoBanner';
import Accordian from '../Accordion/Accordian';

const accoData = [
    {
        "id": 1,
        "title": "When should I arrive for class?",
        "description": "We recommend arriving at the gym 15 minutes before class for check-in. This will allow time for our Front Desk staff to get you checked into class and show you around the facility.",
    },
    {
        "id": 2,
        "title": "What do I do when I get there?",
        "description": "Say hello to whoever is at the front desk and mention that it is your first time. They’ll get you taken care of.",
    },
    {
        "id": 3,
        "title": "What if I'm running late?",
        "description": "To ensure that your spot is not released to the waitlist, please arrive at least 5 minutes prior to the start of your class.",
    },
    {
        "id": 3,
        "title": "Good Vibes Zone",
        "description": "We want you to bring your good vibes! We truly believe in making our gyms a space where members can check their stress at the door. In our gyms you’ll find a community built on sweat and support, all are welcome here. All we ask is that you show up, bring a smile, and get in a great workout. It’s that simple.",
    }

]
const accoData2 = [
    {
        "id": 1,
        "title": "What is a RIDE 45 class like?",
        "description": "A RIDE45 class is the perfect blend of music, technology and indoor cycling. At its core, the music drives the workout plan created by our coaches. The use of the technology from the Keiser Group app immerses the participant in the class with visual feedback on their performance. The lighting in our studio enhances the experience, elevating these classes to the best in Dublin. You will leave dripping in sweat, with a sense of achievement and with a goal to beat in your next class.",
    },
    {
        "id": 2,
        "title": "I've never done this style of training before, can I keep up?",
        "description": "Absolutely! Our workouts are beginner friendly and designed to allow all athletes to workout at their own pace. Our Coaches are trained to modify and make adjustments to the workout on the fly to meet you where you are.",
    },
    {
        "id": 3,
        "title": "What should I wear?",
        "description": "Wear athletic clothes that are comfy for you to sweat and move in.",
    },
    {
        "id": 4,
        "title": "What shoes should I wear?",
        "description": "Most athletes choose to wear normal trainers or you can wear clip on cycling shoes",
    },
    {
        "id": 5,
        "title": "Do you guys provide water?",
        "description": "Each of our gyms are equipped with filtered water stations. Bring a reusable water bottle with you and fill up before class!",
    }

]
const accoData3 = [
    {
        "id": 1,
        "title": "Do you have showers?",
        "description": "Yes! Each of the gyms have showers that you are free to use. Towels and bath & body products are also provided.",
    },
    {
        "id": 2,
        "title": "That was fun! What's next?",
        "description": "Awesome! Schedule your next workout through the Perpetua Fitness app. If you have any questions, please feel free to reach out to our team at any facility or through hello@perpetua.ie",
    }

]

const Ride45FirstTimersLanding = () => {
    return (
        <Style.PageWrapper>
            <VideoBanner
                videobanner='../video/Ride-45-banner.mp4'
                subtitle='A GROUP FITNESS EXPERIENCE THAT IS UNMATCHED IN IRELAND'
                title={<Box>Ride45 <br /> WHAT TO EXPECT </Box>}
                isButton={true}
                issubtextpara={false}
                margin="0"
                videoModal='https://www.youtube.com/embed/MBO0SKgydyU'
                targetBlank={false}
                modalButtontitle="Watch First Timer Video"
                issubtitleRight={false}
            />

            <Style.ContentCard1 className='wow animate fadeIn'>
                <Container maxW="container.xl">
                    <Heading title="Welcome to Ride45 " variant="extralarge" margin="0px 0px 25px"></Heading>
                    <Text title="RIDE45 is an indoor cycling class programmed to music, utilising the technology in our bespoke studio. This is an immersive and inclusive experience, where each participant will have their cycling data on the screen. There is an option to opt out of being on the screen, but we would encourage all first timers to opt in, to be involved in the team challenges and generally enhance their experience." as='p' margin="0px auto 15px" fontSize="18px" width="90%" ></Text>
                    <Text title="RIDE45 offers a range of training styles from endurance work to intervals. There will be a mixture of sprinting, climbing,  power work and rhythm activities on occasion. Each RIDE45 coach will have their particular style and music choice, this is what makes this program so unique." as='p' margin="0px auto 15px" fontSize="18px" width="90%"></Text>
                    <Text title="The beauty of RIDE45 for first timers, is that you are in complete control of gears on the bike. The coach will advise, and you can adjust as you see fit. Because of this, you are guaranteed to get an effective workout, no matter your current level of fitness. " as='p' margin="0px auto 15px" fontSize="18px" width="90%"></Text>
                </Container>
            </Style.ContentCard1>

            <Box padding="0 10px 50px">
                <Heading
                    title='15 MINS BEFORE CLASS'
                    textAlign='center'
                    variant='large'
                    padding='0 0 15px'
                />
                <Text
                    title='Make your way to the bench outside the RIDE studio and your coach will invite you in and help setting up your bike.'
                    textAlign='center'
                />
            </Box>

            <Container maxW="container.xl">
                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0", md: "50px 0" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='./images/ThredAndshredFirsTimers1.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Image src='./images/timer-icon-2.svg' margin={{ sm: "0 auto", md: "0" }} className='firstTimersSvg' />
                        <Heading title='15 Mins Before Class' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title="Make sure you have an account with us, and you’re booked into class (that's important). You can create an account here if you don’t have one." />
                        <Text title='Arrive 15 minutes prior to class time, so you can check in at Front of House, grab your sweat towel and get ready in our changing room. You can also avail of a shower towel for after your session.' padding='5px 0' />
                        <Text title='Please make your way down to the back of the gym to Ride45 area and wait for your coach to call you into the section. ' />
                    </GridItem>
                </SimpleGrid>
                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0", md: "50px 0" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='./images/ThredAndshredFirsTimers2.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Image src='./images/heart-icon.svg' margin={{ sm: "0 auto", md: "0" }} className='firstTimersSvg' />
                        <Heading title='Gears' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title="Keiser M3i Bikes range from gear 1-24. Coaches will instruct participants in ranges of 4. For example: “8-12”, “12-16” etc. Therefore every individual is in control of the level throughout the class. The gear number appears in the bottom left corner of your personal display." />
                    </GridItem>
                </SimpleGrid>

                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0", md: "50px 0" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='./images/ThredAndshredFirsTimers4.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Image src='./images/bbbb-removebg-preview.svg' margin={{ sm: "0 auto", md: "0" }} className='firstTimersSvg' />
                        <Heading title='RPM' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title="(Revolutions per minute) is speed at which you are pedalling. Coaches will refer to this number throughout the class. You can find your RPM at the top of your personal display." />
                    </GridItem>
                </SimpleGrid>

                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0", md: "50px 0" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='./images/ThredAndshredFirsTimers7.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Image src='./images/strength-icon-1.svg' margin={{ sm: "0 auto", md: "0" }} className='firstTimersSvg' />
                        <Heading title='Positions' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title="In RIDE45, staying the seat and standing are the main position used. Should the coach include some Rhythm Activities, this could be slightly more complex. " />
                    </GridItem>
                </SimpleGrid>

                <SimpleGrid
                    columns={{ sm: '1', md: '2' }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 0 50px", md: "50px 0 100px" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='./images/ThredAndshredFirsTimers6.webp' />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Image src='./images/aaaaa-removebg-preview.svg' margin={{ sm: "0 auto", md: "0" }} className='firstTimersSvg' />
                        <Heading title='After Class' variant='small' padding='15px 0' textAlign={{ sm: "center", md: "start" }} />
                        <Text title="Clean down the bike. Put all cleaning, scented and sweat towels in towel bins provided. " />
                    </GridItem>
                </SimpleGrid>
            </Container>


            <Box padding={{ sm: '35px 0px 30px', md: '80px 0px' }} background="#f7f7f7">
                <Container maxW='container.xl' >
                    <Flex margin="auto" textAlign={'center'} width={{ sm: '100%', md: '50%' }} flexDirection="column" paddingBottom="30px">
                        <Heading
                            lineHeight={"1"}
                            variant="large"
                            title="Frequently Asked Questions"
                            marginBottom='30px'
                        />
                        <Box mb="40px">
                            <Text
                                title="Questions about the classes or next steps? We got you. If you need more information, please feel free to contact us hello@perpetua.ie"
                            />
                        </Box>
                    </Flex>

                    <Flex flexDirection={{ sm: "column", md: "row" }}>
                        <Box width={{ sm: '100%', md: '33.33%' }} paddingBottom={{ sm: "30px", md: 0 }}>
                            <Heading
                                lineHeight={"1"}
                                variant="smallmid"
                                title="BEFORE CLASS"
                                marginBottom='20px'
                                padding="0 15px"
                            />
                            <Accordian data={accoData} />
                        </Box>
                        <Box width={{ sm: '100%', md: '33.33%' }} paddingBottom={{ sm: "30px", md: 0 }}>
                            <Heading
                                lineHeight={"1"}
                                variant="smallmid"
                                title="DURING CLASS"
                                marginBottom='20px'
                                padding="0 15px"
                            />
                            <Accordian data={accoData2} />
                        </Box>
                        <Box width={{ sm: '100%', md: '33.33%' }} paddingBottom={{ sm: "30px", md: 0 }}>
                            <Heading
                                lineHeight={"1"}
                                variant="smallmid"
                                title="AFTER CLASS"
                                marginBottom='20px'
                                padding="0 15px"
                            />
                            <Accordian data={accoData3} />
                        </Box>
                    </Flex>
                </Container>
            </Box>

            <Style.tremsandcondition className='wow animate fadeIn'>
                <Container maxW="container.xl">
                    <Style.tremsandconditionWrapper>
                        <Heading title="TERMS & CONDITIONS" variant="large" margin="0px 0px 5px" color='#000' />
                        <Text title="Offer valid only for first-time referral clients on an Intro Offer 3 for €33. Promotion can only be used for one #PlusOne at a time. First-timer must purchase an Intro Offer online and take a class with the existing client. Both parties must be present and check in at the front of house together to be eligible for the promotion. Not applicable for private events or corporate buy-outs, including full and/or partial buy-outs. All complimentary class credits expire 30 days from the date credited. Promotion ends 30/09/2023." as='p' fontSize="18px" color='#000' />
                    </Style.tremsandconditionWrapper>
                </Container>
            </Style.tremsandcondition>

            <Flex
                flexDirection='column'
                padding={{ sm: '0 0 50px', md: '50px 0 100px' }}
                textAlign='center'
                justifyContent='center'
                justifyItems='center'
            >
                <Container maxW='container.xl'>
                    <Box>
                        <Heading
                            as="h2"
                            title="ARE YOU READY?"
                            variant='extralarge'
                            textAlign="center"
                            lineHeight="1"
                            padding={{ base: '0px 0px 15px', sm: '0px 0px 30px' }}
                        />
                        <Text
                            title="Try 3 classes for €33. All fitness levels welcome "
                            as='p'
                        />
                        <NavLink to='/contact-us' _hover={{ textDecoration: 'none' }}><Button title='Get Started' className="wow fadeInUp" color='black' margin='40px 0px 0px' /> </NavLink>
                        <Box>
                            <Heading
                                as="h5"
                                title="Download our app "
                                variant='extrasmall'
                                textAlign="center"
                                lineHeight="1"
                                padding={{ sm: '15px 0px 0px', md: '30px 0px 0px' }}
                                display='inline-block'
                            />
                            <Link href="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness" target='_blank' >
                                <Heading
                                    as="h5"
                                    title="Here"
                                    variant='extrasmall'
                                    textAlign="center"
                                    lineHeight="1"
                                    padding={{ sm: '15px 0px 0px', md: '30px 0px 0px' }}
                                    display='inline-block'
                                    margin='0px 0px 0px 5px'
                                    color='Red'
                                    textDecoration='underline'
                                />
                            </Link>
                        </Box>
                    </Box>
                </Container>
            </Flex>
        </Style.PageWrapper>
    )
}

export default Ride45FirstTimersLanding;