import React from 'react';
import { Box, Flex, UnorderedList, ListItem, Text, Heading,Link} from '@chakra-ui/react';
import Button from '../Button/CustomeButton';
function ZigzagCard(){
    return(
        <>
            <Flex 
            flexDirection={{sm: 'column-reverse', md: "row"}}
            padding={{sm:'0px', md:'30px 0px 0', lg:'100px 0px 0px' }} 
            alignItems='center'
            alignContent='center'
            >
                <Flex width={{sm: "100%", md:"50%"}} backgroundColor='#f7f7f7' padding={{sm: "45px 0 35px", md: "200px 0"}} position="relative" justifyContent="flex-end"
                _after={{position:'absolute',content:'""', width:'180px', height:'100%', top:'0',left:'100%', backgroundColor:'#f7f7f7', zIndex:'-1', display:{sm:"none", md:"block"}}}   
                >
                    <Box width={{md: "100%", xl:"630px"}}  padding={{lg:"0 15px"}}>
                       
                        <Heading  fontFamily='Poppins' fontSize={{sm:'30px',md:'40px'}} padding={{sm:'0px 30px', md:'20px 30px'}} lineHeight='1' margin={{sm:'0px 0px 25px', md:'0px 0px 30px', lg:'0px 0px 30px'}}>PROTEIN SHAKES</Heading>
                        <Text fontFamily='Poppins' fontSize='16px'  padding='0px 30px' >Our Mojo Protein Shakes are the perfect choice for post-workout nutrition. Designed to help you recover, our shakes blend SF Nutrition 100% natural vegan protein, superfood boosters and dairy free mylks to deliver maximum nutrition. Our shakes are designed to enhance performance & recovery, build lean muscle, help with hormonal balance and boost your metabolism.</Text>
                    </Box>
                </Flex>
                <Box 
                width={{sm: "100%", md:"50%"}}
                backgroundImage='url(./images/zigzag1.jpg)' 
                height={{sm: "300", md: '558px'}}
                backgroundRepeat='no-repeat'
                backgroundPosition='center'
                backgroundSize='cover'
                ></Box>

            </Flex>
           
         
            <Flex 
                flexDirection={{sm: "column", md: "row"}}
                padding={{sm:'0px', md:'70px 0px 0px', lg:'150px 0px 0px' }}
                alignItems='center'
                alignContent='center'
            >
                <Box width={{sm: '100%', md: "50%"}} textAlign='right'>
                    <Box 
                        backgroundImage='url(./images/zigzag2.jpg)' 
                        width={{md:"100%" , lg: '551px'}}
                        height={{sm: "350px", md: '671px'}} 
                        display={{sm:"block", md: 'inline-block' }}
                        backgroundRepeat='no-repeat'
                        backgroundPosition='top'
                        backgroundSize='cover'
                        position='relative'
                        zIndex='1'
                        top={{sm: "0", md: "-50px"}}
                    >
                    </Box>
                </Box>
                <Box width={{sm: '100%', md: "58%"}}  height={{sm: "250px", md: '671px'}}  backgroundColor='#f7f7f7' marginLeft={{sm: "0", md: '-100px'}}
                 padding={{sm: "45px 0px 0px 0px", md: "70px 100px 60px 187px", xl:  '180px 100px 0px 190px'}}   >
                    
                    <Heading fontSize={{sm:'30px',md:'40px'}} fontFamily='Poppins' padding='0px 30px' lineHeight='1' margin={{sm:'0px 0px 15px', md:'0px 0px 40px', lg:'0px 0px 40px'}}>ACAI BOWLS</Heading>
                    <Text fontSize='16px'fontFamily='Poppins' padding='0px 30px'>Our Brasilian Acai Bowls are packed with nutritional benefits that will help balance your energy throughout the day – and they’re delicious!</Text>
                </Box>
            </Flex>
          
            <Flex 
            flexDirection={{sm: 'column-reverse', md: "row"}}
            padding={{sm:'0px ', md:'30px 0px 0', lg:'100px 0px 0px' }} 
            alignItems='center'
            alignContent='center'
            >
                <Flex width={{sm: "100%", md:"50%"}} backgroundColor='#f7f7f7' padding={{sm: "45px 0 35px", md: "220px 0"}} position="relative" justifyContent="flex-end"
                _after={{position:'absolute',content:'""', width:'180px', height:'100%', top:'0',left:'100%', backgroundColor:'#f7f7f7', zIndex:'-1', display:{sm:"none", md:"block"}}}   
                >
                    <Box width={{md: "100%", xl:"630px"}}  padding="0 15px">
                        <Heading fontSize={{sm:'30px',md:'40px'}} fontFamily='Poppins' padding={{sm:'0px 30px', md:'20px 30px'}} lineHeight='1' margin={{sm:'0px 0px 25px', md:'0px 0px 30px', lg:'0px 0px 30px'}}>PROTEIN MEALS</Heading>
                       <Text fontSize='16px' fontFamily='Poppins' padding='0px 30px'>We’ve got you sorted with our Sandwich and Porridge offerings! All our sandwiches are toasted to perfection on Kohberg Organic Whole Grain bread. Our Porridge is made to order using Irish rolled oats and plant milks.</Text>
                    </Box>
                </Flex>
                <Box 
                width={{sm: "100%", md:"50%"}}
                backgroundImage='url(./images/zigzag3.jpg)' 
                height={{sm: "300", md: '558px'}}
                backgroundRepeat='no-repeat'
                backgroundPosition='center'
                backgroundSize='cover'
                ></Box>
            </Flex>
            
            <Flex 
                flexDirection={{sm: 'column', md: "row"}}
                padding={{sm:'0px', md:'70px 0px 0px', lg:'150px 0px 100px' }}
                alignItems='center'
                alignContent='center'
            >
                <Box width={{sm: '100%', md: "50%"}} textAlign='right'>
                    <Box 
                        backgroundImage='url(./images/zigzag4.jpg)' 
                        width={{md:"100%" , lg: '551px'}}
                        height={{sm: "350px", md: '671px'}} 
                        display={{sm:"block", md: 'inline-block' }}
                        backgroundRepeat='no-repeat'
                        backgroundPosition='top'
                        backgroundSize='cover'
                        position='relative'
                        zIndex='1'
                        top={{sm: "0", md: "-50px"}}
                    >
                    </Box>
                </Box>
                <Box width={{sm: '100%', md: "58%"}}  height={{sm: "360px", md: '671px'}}  backgroundColor='#f7f7f7' marginLeft={{sm: "0", md: '-100px'}}
                 padding={{sm: "15px 0px 20px 20px", md: "70px 100px 60px 187px", xl:  '180px 100px 0px 190px'}}   >
                   
                   <Heading fontSize={{sm:'30px',md:'40px'}} fontFamily='Poppins' padding='20px 0px' lineHeight='1'>COFFEE & SPECIALITY DRINKS</Heading>
                    <Text fontSize='16px' fontFamily='Poppins' >We’ve partnered with Cloud Picker to give you premium coffee roasted here in Dublin. Whether you like a simple Caffe Americano or a rich foamy Cappuccino, we’re here to give you your morning or afternoon pick-me-up. We also have a variety of energy and recovery drinks, including Nocco products.</Text>
                </Box>
            </Flex>
        
            <Flex 
            flexDirection={{sm: 'column-reverse', md: "row"}}
            padding={{sm:'0px', md:'0 0px 90px'}} 
            alignItems='center'
            alignContent='center'
            >
                <Flex width={{sm: "100%", md:"50%"}} backgroundColor='#f7f7f7' padding={{sm: "35px 0 35px", md: "170px 0"}} position="relative" justifyContent="flex-end"
                _after={{position:'absolute',content:'""', width:'180px', height:'100%', top:'0',left:'100%', backgroundColor:'#f7f7f7', zIndex:'-1', display:{sm:"none", md:"block"}}}   
                >
                    <Box width={{md: "100%", xl:"630px"}}  padding="0 15px">
                       <Heading fontFamily='Poppins' fontSize={{sm:'30px',md:'40px'}} padding={{sm:'5px 0px 0px 30px' ,md:'5px 30px'}} lineHeight='1' margin={{sm:'0px 0px 25px', md:'0px 0px 30px', lg:'0px 0px 30px'}}>CORPORATE CATERING</Heading>
                        <Text fontFamily='Poppins' fontSize='16px' padding='0px 30px'>In need of an office lunch but want it to be delicious and nutritious? Let MOJO take the lead.</Text>
                        <UnorderedList padding='0px 50px' fontFamily='Poppins' marginTop='5px' fontSize='16px'>
                            <ListItem>Board meeting catering</ListItem>
                            <ListItem>Breakfast meetings</ListItem>
                            <ListItem>Corporate events</ListItem>
                        </UnorderedList>
                        <Text fontFamily='Poppins' fontSize='16px' padding='10px 30px'>Our high levels of personal service is what makes us stand out.</Text>
                        <Link href='/contact-us' padding='10px 30px' _hover={{textDecoration:'none'}}>
                            <Button title='ENQUIRE' fontSize='16px' color='black'/>
                        </Link>
                    </Box>
                </Flex>
                <Box 
                width={{sm: "100%", md:"50%"}}
                backgroundImage='url(./images/zigzag5.jpg)' 
                height={{sm: "300", md: '558px'}}
                backgroundRepeat='no-repeat'
                backgroundPosition='center'
                backgroundSize='cover'
                ></Box>
            </Flex>
        </>
    )
}

export default ZigzagCard;